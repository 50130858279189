import React, {useState, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {clearState, forgetPassword} from "../../redux/auth/auth.slice";
import {useDispatch, useSelector} from "react-redux";
import ForgetPasswordSuccess from "../../components/Modal/ForgetPasswordSuccess";
import Loading from "../../components/Loading";
import ResetPasswordFail from "../../components/Modal/ResetPasswordFail";
import {typeUser} from "../../constant";

function ForgetPassword() {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [textValidate, setTextValidate] = useState('');
  const [classValidate, setClassValidate] = useState('input-system w-100');
  const {isFetching, isSuccess, isError, responseForgetPassword , errorMessage} = useSelector((state) => state.auth);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [url, setUrl] = useState('');
  const [modalFail, setModalFail] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    setTextValidate('');
    setClassValidate('input-system w-100');
    dispatch(forgetPassword({email}));
  }

  useEffect(() => {
    if (isSuccess) {
      if (responseForgetPassword.data.role === typeUser.coach) {
        setUrl('/coach/login');
      } else if (responseForgetPassword.data.role === typeUser.student) {
        setUrl('/student/login');
      } else if (responseForgetPassword.data.role === typeUser.company) {
        setUrl('/company/login');
      } else {
        setUrl('/teacher/login');
      }
      dispatch(clearState());
      setTextValidate('');
      setClassValidate('input-system w-100');
      setModalSuccess(true);
    }
    if (isError) {
      dispatch(clearState());
      if (errorMessage.status === 500) {
        setModalFail(true);
        return;
      }
      if (errorMessage.status === 422) {
        if (errorMessage?.data?.errors) {
          setTextValidate(errorMessage?.data?.errors?.email[0] || '');
        } else {
          setTextValidate(errorMessage?.data?.message || '');
        }
        setClassValidate('input-system w-100 border-error');
      }
    }
  }, [isSuccess, isError]);

  function closeModalSuccess(value) {
    setModalSuccess(value);
  }

  function closeModalFail(value) {
    setModalFail(value);
  }

  return (
    <>
      <form className="form-register form-forget-password" onSubmit={handleSubmit}>
        <div>{<ForgetPasswordSuccess todos={modalSuccess} closeModal={closeModalSuccess} url={url}/>}</div>
        <div>{<ResetPasswordFail todos={modalFail} closeModal={closeModalFail}/>}</div>
        <div className="title-form">{t('forget_password')}</div>
        <div className="group-register group-forget-password">
          <div className="d-flex flex-column align-items-start mb-4 w-100">
            <label className="main-text mb-4">{t('description_forget_password')}</label>
            <label className="main-text mb-2">{t('email_input')}</label>
            <input type="text" name="email" placeholder={t('placeholder_email')} className={classValidate} value={email} onChange={(e) => {setEmail(e.target.value.trim())}} />
            <span className="text-error">{textValidate}</span>
          </div>
        </div>
        <button className="button-primary">{t('send')}</button>
      </form>
      {isFetching && <Loading />}
    </>
  );
}

export default ForgetPassword;
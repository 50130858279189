import React from 'react';
import PulseLoader from 'react-spinners/PulseLoader';

function Index() {
  return (
    <div style={{
      position: 'fixed',
      background: 'rgb(29 29 29 / 50%)',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999,
    }}>
      <PulseLoader color="#0078BF" />
    </div>
  );
}

export default Index;
import React from 'react';
import {Button, Modal} from "react-bootstrap";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

ForgetPasswordSuccess.propTypes = {
  todos: PropTypes.bool,
  closeModal: PropTypes.func,
  url: PropTypes.string,
};

ForgetPasswordSuccess.defaultProps = {
  todos: false,
  closeModal: null,
  url: null
};

function ForgetPasswordSuccess(props) {
  const {t} = useTranslation();
  const { todos, url } = props;

  return (
    <div>
      <Modal show={todos} centered>
        <Modal.Header>
          <Modal.Title>
            <div className="title-form">{t('forget_password')}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="main-text">{t('success_forget_password')}</div>
        </Modal.Body>
        <Modal.Footer>
          <Link to={url} className="text-header">
          <Button variant="primary">
            <div>{t('register_company.modal_button')}</div>
          </Button>
          </Link>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ForgetPasswordSuccess;
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {typeUser} from "../../constant";
import {clearState, currentUser} from "../../redux/user/user.slide";
import './index.css';
import FormUpdate from "../Coach/FormInfo";
import Loading from "../../components/Loading";

function Update() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {isFetching, isErrorUser, isSuccessUser, response} = useSelector(state => state.user);
  const [user, setUser] = useState(null);
  const [textBack, setTextBack] = useState('');

  useEffect(() => {
    dispatch(currentUser());
    return () => dispatch(clearState());
  }, []);

  useEffect(() => {
    if (isErrorUser) {
      return alert(t('error_system'));
    }
    if (isSuccessUser) {
      if (response?.data.role !== typeUser.coach) {
        navigate('/');
      }
      setUser(response?.data);
      setTextBack(response?.data?.update_flag ? t('update_coach.back_my_page') : t('update_coach.back_top_page'));
    }

    return () => dispatch(clearState());
  }, [isSuccessUser, isErrorUser]);

  return (
    <>
      <FormUpdate
        textBack={textBack}
        textSubmit={t('update')}
        userInfo={user}
        type='update'
        typeSubmit='update'
        showHideForm={null}
        isShow={true}
        formData={null}
      />
      {isFetching && <Loading />}
    </>
  );
}

export default Update;
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import SignUp from "../Auth/SignUp";
import {typeUser} from "../../constant";
import PropTypes from "prop-types";
import FormCompany from '../Company/FormInfo';
import FormTeacher from '../Teacher/FormInfo';
import FormStudent from '../Student/FormInfo';
import FormCoach from '../Coach/FormInfo';

Register.propTypes = {
  type: PropTypes.string,
}
Register.defaultProps = {
  type: '',
}
function Register(props) {
  const {t} = useTranslation();
  const {type} = props;
  const [showInfoForm, setShowInfoForm] = useState(true);
  const [formData, setFormData] = useState({});
  const [title, setTitle] = useState('');

  useEffect(() => {
    switch (type) {
      case typeUser.company:
        setTitle(t('register_company.modal_title'));
        break;
      case typeUser.student:
        setTitle(t('register_student'));
        break;
      case typeUser.coach:
        setTitle(t(''));
        break;
      case typeUser.teacher:
        setTitle(t('teacher.register'));
        break;
    }
  }, [type]);

  const getTemplate = () => {
    switch (type) {
      case typeUser.student:
        return <FormStudent
          textBack={t('update_coach.back_top_page')}
          textSubmit={t('save_issue')}
          showHideForm={handleShowHideForm}
          formData={getData}
          typeSubmit='create'
          userInfo={null}
          type={typeUser.teacher}
          isShow={showInfoForm}
        />;
      case typeUser.coach:
        return <FormCoach
          textBack={t('update_coach.back_top_page')}
          textSubmit={t('save_issue')}
          showHideForm={handleShowHideForm}
          formData={getData}
          typeSubmit='create'
          userInfo={null}
          type={typeUser.teacher}
          isShow={showInfoForm}
        />;
      case typeUser.teacher:
        return <FormTeacher
          textBack={t('update_coach.back_top_page')}
          textSubmit={t('save_issue')}
          showHideForm={handleShowHideForm}
          formData={getData}
          typeSubmit='create'
          userInfo={null}
          type={typeUser.teacher}
          isShow={showInfoForm}
        />;
      case typeUser.company:
        return <FormCompany
          textBack={t('update_coach.back_top_page')}
          textSubmit={t('save_issue')}
          showHideForm={handleShowHideForm}
          formData={getData}
          typeSubmit='create'
          userInfo={null}
          type={typeUser.company}
          isShow={showInfoForm}
        />;
      default:
        return null;
    }
  }
  const handleShowHideForm = (value) => {
    setShowInfoForm(value);
  }

  const getData = (data) => {
    setFormData(data);
  }

  const backForm = () => {
    setShowInfoForm(true);
  }

  return (
    <>
      {getTemplate()}
      {!showInfoForm && <SignUp title={title} type={type} formData={formData} back={backForm} />}
    </>
  );
}

export default Register;
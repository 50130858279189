import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {manager, paginationAdmin} from "../../constant";
import ModalCreate from './Modal/CreateData';
import ModalEdit from './Modal/EditData';
import Notification from "../../components/Modal/Notification";
import DeleteModal from './Modal/DeleteData';
import {get, post, deleteMethod} from '../../services';
import Pagination from 'react-paginate';

Data.propTypes = {
  type: PropTypes.number,
  setLoading: PropTypes.func,
}
Data.defaultProps = {
  type: null,
  setLoading: null,
}
function Data(props) {
  const { t } = useTranslation();
  const {type, setLoading} = props;
  const [titleManager, setTitleManager] = useState('');
  const [titleDelete, setTitleDelete] = useState('');
  const [titleEdit, setTitleEdit] = useState('');
  const [titleCreate, setTitleCreate] = useState('');
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalNotification, setOpenModalNotification] = useState(false);
  const [titleNotification, setTitleNotification] = useState('');
  const [contentNotification, setContentNotification] = useState('');
  const [createSuccess, setCreateSuccess] = useState('');
  const [updateSuccess, setUpdateSuccess] = useState('');
  const [deleteSuccess, setDeleteSuccess] = useState('');
  const [createError, setCreateError] = useState('');
  const [updateError, setUpdateError] = useState('');
  const [deleteError, setDeleteError] = useState('');
  const [contentDelete, setContentDelete] = useState('');
  const [dataId, setDataId] = useState(null);
  const [urlUpdate, setUrlUpdate] = useState('');
  const [urlCreate, setUrlCreate] = useState('');
  const [urlDelete, setUrlDelete] = useState('');
  const [urlList, setUrlList] = useState('');
  const [validateName, setValidateName] = useState('');
  const [name, setName] = useState('');
  const [response, setResponse] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [nameColumn, setNameColumn] = useState('');

  useEffect(() => {
    setKeyword('');
    switch (type) {
      case manager.gender:
        setTitleManager(t('admin.manager_gender'));
        setTitleCreate(t('admin.data.create.gender'));
        setTitleEdit(t('admin.data.edit.gender'));
        setCreateSuccess(t('admin.data.create_success.gender'));
        setUpdateSuccess(t('admin.data.edit_success.gender'));
        setDeleteSuccess(t('admin.data.delete_success.gender'));
        setTitleDelete(t('admin.data.delete.gender'));
        setContentDelete(t('admin.data.content_delete.gender'));
        setUpdateError(t('admin.data.edit_error.gender'));
        setCreateError(t('admin.data.create_error.gender'));
        setDeleteError(t('admin.data.delete_error.gender'));
        setUrlList('/genders');
        setUrlUpdate('/genders/');
        setUrlCreate('/genders');
        setUrlDelete('/genders/');
        setNameColumn(t('admin.gender.name'));
        break;
      case manager.object_student:
        setTitleManager(t('admin.manager_object_student'));
        setTitleCreate(t('admin.data.create.student_object'));
        setTitleEdit(t('admin.data.edit.student_object'));
        setCreateSuccess(t('admin.data.create_success.student_object'));
        setUpdateSuccess(t('admin.data.edit_success.student_object'));
        setDeleteSuccess(t('admin.data.delete_success.student_object'));
        setTitleDelete(t('admin.data.delete.student_object'));
        setContentDelete(t('admin.data.content_delete.student_object'));
        setUrlList('/levels');
        setUrlUpdate('/levels/');
        setUrlCreate('/levels');
        setUrlDelete('/levels/');
        setNameColumn(t('admin.object_student.name'));
        break;
      case manager.quantity_student:
        setTitleManager(t('admin.manager_quantity_student'));
        setTitleCreate(t('admin.data.create.student_join'));
        setTitleEdit(t('admin.data.edit.student_join'));
        setCreateSuccess(t('admin.data.create_success.student_join'));
        setUpdateSuccess(t('admin.data.edit_success.student_join'));
        setDeleteSuccess(t('admin.data.delete_success.student_join'));
        setTitleDelete(t('admin.data.delete.student_join'));
        setContentDelete(t('admin.data.content_delete.student_join'));
        break;
      case manager.quantity_organizer:
        setTitleManager(t('admin.manager_quantity_organizer'));
        setTitleCreate(t('admin.data.create.organizer_join'));
        setTitleEdit(t('admin.data.edit.organizer_join'));
        setCreateSuccess(t('admin.data.create_success.organizer_join'));
        setUpdateSuccess(t('admin.data.edit_success.organizer_join'));
        setDeleteSuccess(t('admin.data.delete_success.organizer_join'));
        setTitleDelete(t('admin.data.delete.organizer_join'));
        setContentDelete(t('admin.data.content_delete.organizer_join'));
        break;
      case manager.school_type:
        setTitleManager(t('admin.manager_school_type'));
        setTitleCreate(t('admin.data.create.school_type'));
        setTitleEdit(t('admin.data.edit.school_type'));
        setCreateSuccess(t('admin.data.create_success.school_type'));
        setUpdateSuccess(t('admin.data.edit_success.school_type'));
        setDeleteSuccess(t('admin.data.delete_success.school_type'));
        setTitleDelete(t('admin.data.delete.school_type'));
        setContentDelete(t('admin.data.content_delete.school_type'));
        setUrlList('/type-of-schools');
        setUrlUpdate('/type-of-schools/');
        setUrlCreate('/type-of-schools');
        setUrlDelete('/type-of-schools/');
        setNameColumn(t('admin.type_school.name'));
        break;
      case manager.school_name:
        setTitleManager(t('admin.manager_school_name'));
        setTitleCreate(t('admin.data.create.school_name'));
        setTitleEdit(t('admin.data.edit.school_name'));
        setCreateSuccess(t('admin.data.create_success.school_name'));
        setUpdateSuccess(t('admin.data.edit_success.school_name'));
        setDeleteSuccess(t('admin.data.delete_success.school_name'));
        setTitleDelete(t('admin.data.delete.school_name'));
        setContentDelete(t('admin.data.content_delete.school_name'));
        setUrlList(null);
        break;
      case manager.major:
        setTitleManager(t('admin.manager_major'));
        setTitleCreate(t('admin.data.create.major'));
        setTitleEdit(t('admin.data.edit.major'));
        setCreateSuccess(t('admin.data.create_success.major'));
        setUpdateSuccess(t('admin.data.edit_success.major'));
        setDeleteSuccess(t('admin.data.delete_success.major'));
        setTitleDelete(t('admin.data.delete.major'));
        setContentDelete(t('admin.data.content_delete.major'));
        setUrlList('/majors');
        setUrlUpdate('/majors/');
        setUrlCreate('/majors');
        setUrlDelete('/majors/');
        setNameColumn(t('admin.major.name'));
        break;
      case manager.year_school:
        setTitleManager(t('admin.manager_year'));
        setTitleCreate(t('admin.data.create.school_year'));
        setTitleEdit(t('admin.data.edit.school_year'));
        setCreateSuccess(t('admin.data.create_success.school_year'));
        setUpdateSuccess(t('admin.data.edit_success.school_year'));
        setDeleteSuccess(t('admin.data.delete_success.school_year'));
        setTitleDelete(t('admin.data.delete.school_year'));
        setContentDelete(t('admin.data.content_delete.school_year'));
        setUrlList(null);
        break;
      case manager.category:
        setTitleManager(t('admin.manager_category'));
        setTitleCreate(t('admin.data.create.category'));
        setTitleEdit(t('admin.data.edit.category'));
        setCreateSuccess(t('admin.data.create_success.category'));
        setUpdateSuccess(t('admin.data.edit_success.category'));
        setDeleteSuccess(t('admin.data.delete_success.category'));
        setTitleDelete(t('admin.data.delete.category'));
        setContentDelete(t('admin.data.content_delete.category'));
        setUrlList('/categories');
        setUrlUpdate('/categories/');
        setUrlCreate('/categories');
        setUrlDelete('/categories/');
        setNameColumn(t('admin.category.name'));
        break;
      default:
        return null;
    }
  }, [type]);

  const closeModalCreate = (value) => {
    setOpenModalCreate(value);
  }

  const closeModalEdit = (value) => {
    setOpenModalEdit(value);
  }

  const closeModalDelete = (value) => {
    setOpenModalDelete(value);
  }

  const closeModalNotification = (value) => {
    setOpenModalNotification(value);
    location.reload();
  }

  const getDataDefault = async () => {
    getData({paginate: paginationAdmin});
  }

  const handlePageChange = async (pageNumber) => {
    getData({
      paginate: paginationAdmin,
      name: keyword,
      page: pageNumber?.selected + 1,
    });
  }

  const getData = async (params) => {
    setLoading(true);
    const data =  await get(urlList, null, params);
    if (data?.status === 500) {
      alert(t('error_system'));
    }
    if (data?.status === 200) {
      setResponse(data?.data?.data?.data ?? []);
      setCurrentPage(data?.data?.data?.meta.current_page);
      setTotalPages(data?.data?.data?.meta?.last_page);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (urlList) {
      getDataDefault();
    } else {
      setResponse([]);
    }
  }, [urlList]);

  const handleUpdate = async (name) => {
    setLoading(true);
    setValidateName('');
    const url = urlUpdate + dataId;
    const data = await post(url, {
      id: dataId,
      name,
      _method: 'PUT',
    });
    if (data?.status === 500) {
      setOpenModalEdit(false);
      setOpenModalNotification(true);
      setTitleNotification(titleEdit);
      setContentNotification(updateError);
    }
    if (data?.status === 422) {
      setValidateName(data?.data?.errors?.name?.[0]);
    }
    if (data?.status === 200) {
      setOpenModalEdit(false);
      setOpenModalNotification(true);
      setTitleNotification(titleEdit);
      setContentNotification(updateSuccess);
    }
    setLoading(false);
  }

  const handleCreate = async (name) => {
    setValidateName('');
    setLoading(true);
    const data = await post(urlCreate, {name});
    if (data?.status === 500) {
      setOpenModalCreate(false);
      setOpenModalNotification(true);
      setTitleNotification(titleCreate);
      setContentNotification(createError);
    }
    if (data?.status === 422) {
      setValidateName(data?.data?.errors?.name?.[0]);
    }
    if (data?.status === 200) {
      setOpenModalCreate(false);
      setOpenModalNotification(true);
      setTitleNotification(titleCreate);
      setContentNotification(createSuccess);
    }
    setLoading(false);
  }

  const handleDelete = async () => {
    setLoading(true);
    const url = urlDelete + dataId;
    const data = await deleteMethod(url);
    if (data?.status === 500) {
      setOpenModalNotification(true);
      setTitleNotification(titleDelete);
      setContentNotification(deleteError);
    }
    if (data?.status === 200) {
      setOpenModalNotification(true);
      setTitleNotification(titleDelete);
      setContentNotification(deleteSuccess);
    }
    setLoading(false);
  }

  const handleSearch = (e) => {
    e.preventDefault();
    getData({
      paginate: paginationAdmin,
      name: keyword,
    });
  }

  return (
    <>
      <div className='manager-block'>
        <div>
          <div className='title-manager'>{titleManager}</div>
          <div className='w-100 d-flex justify-content-between align-items-center mb-3 '>
            <button className='btn-register-coach' onClick={() => {setValidateName(''); setOpenModalCreate(true);}}>{t('admin.register_btn')}</button>
            <form onSubmit={handleSearch} className='d-flex justify-content-end align-items-center w-100'>
              <div className='mr-3 main-text'>{t('admin.company.title_search')}</div>
              <input type="text" className='input-system mr-3 w-40' placeholder={t('admin.placeholder_keyword')} value={keyword || ''} maxLength={500} onChange={(e) => setKeyword(e.target.value)} />
              <button className='btn-save-company'>{t('admin.company.btn_search')}</button>
            </form>
          </div>
          <table className='table table-manager'>
            <thead>
            <tr>
              <th className='w-50'>{nameColumn}</th>
              <th className='th__action'></th>
            </tr>
            </thead>
            <tbody>
            {response.length === 0 && <tr><td colSpan={2} className="no-data">{t('no_data')}</td></tr>}
            {response.map((val) => (
              <tr key={val?.id}>
                <td>{val?.name}</td>
                <td>
                  <div className='d-flex justify-content-center align-items-center'>
                    <button className='btn-action-manager btn-edit-manager' onClick={() => {setOpenModalEdit(true); setName(val?.name); setDataId(val?.id); setValidateName('');}}>{t('admin.edit')}</button>
                    <button className='btn-action-manager btn-delete-manager' onClick={() => {setOpenModalDelete(true); setDataId(val?.id); setName(val?.name)}}>{t('admin.delete')}</button>
                  </div>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
        {totalPages > 1 &&
          <Pagination
            containerClassName='pagination justify-content-center'
            pageLinkClassName='page-link'
            breakClassName="page-item"
            breakLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            activeClassName="active"
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageChange}
            pageCount={totalPages}
            previousLabel="<"
            renderOnZeroPageCount={null}
            forcePage={currentPage - 1}
          />
        }
      </div>
      <DeleteModal
        todos={openModalDelete}
        title={titleDelete}
        content={contentDelete}
        closeModal={closeModalDelete}
        handleDelete={handleDelete}
        name={name}
      />
      <ModalCreate
        todos={openModalCreate}
        title={titleCreate}
        closeModal={closeModalCreate}
        handleCreate={handleCreate}
        message={validateName}
      />
      <ModalEdit
        todos={openModalEdit}
        title={titleEdit}
        closeModal={closeModalEdit}
        value={name}
        type={type}
        handleUpdate={handleUpdate}
        message={validateName}
      />
      <Notification
        todos={openModalNotification}
        title={titleNotification}
        content={contentNotification}
        closeModal={closeModalNotification} />
    </>
  );
}

export default Data;
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import DetailStudent from '../../components/Detail/index';
import {clearState, detailStudent} from "../../redux/student/student.slide";
import {typeUser} from "../../constant";
import NotFound from '../../components/404/index';
import {useTranslation} from "react-i18next";

function Detail() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {isSuccess, isError, response, isFetching, errorMessage} = useSelector((state) => state.student);
  const [isStudent, setIsStudent] = useState(true);

  useEffect(() => {
    dispatch(detailStudent({id: window.location.pathname.split('/').pop()}));
    return () => dispatch(clearState());
  }, []);

  useEffect(() => {
    if (isSuccess) {
      window.scrollTo({top: 0});
      if (response?.data?.role !== typeUser.student) {
        setIsStudent(false);
      }
    }
    if (isError) {
      if (errorMessage.status === 500) {
        alert(t('error_system'));
      }
      if (errorMessage.status === 404) {
        setIsStudent(false);
      }
    }
    return () => dispatch(clearState());
  }, [isSuccess, isError]);

  return (
    <>
      {isStudent ? <DetailStudent response={response} type='students' isLoading={isFetching}/> :
        <NotFound />
      }
    </>
  );
}

export default Detail;
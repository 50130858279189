import React from 'react';
import { Route, Routes } from 'react-router-dom';
import TopPage from "../containers/TopPage";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<TopPage />} />
    </Routes>
  );
}

export default AppRoutes;

import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
import {clearState, ListCategories} from "../../redux/category/category.slide";

Index.propTypes = {
  getCategory: PropTypes.func,
  response: PropTypes.any,
  type: PropTypes.string,
}
Index.defaultProps = {
  getCategory: null,
  response: null,
  type: '',
}
function Index(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {getCategory, response, type} = props;
  const {isSuccessCategory, isErrorCategory, listCategories} = useSelector((state) => state.category);
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState(null);

  useEffect(() => {
    dispatch(ListCategories());

    return () => dispatch(clearState());
  }, []);

  useEffect(() => {
    if (isErrorCategory) alert(t('error_system'));
    if (isSuccessCategory) {
      setCategories(listCategories?.data?.data ?? []);
    }

    return () => dispatch(clearState());
  }, [isSuccessCategory, isErrorCategory]);

  useEffect(() => {
    getCategory(categoryId)
  }, [categoryId]);

  useEffect(() => {
    setCategoryId(response);
  }, [response]);

  const handleKeyPress = (e) => {
    if (e.charCode === 13 && type === 'create') {
      e.preventDefault()
    }
  }

  return (
    <select
      onKeyPress={(e) => handleKeyPress(e)}
      value={categoryId ?? ''}
      style={{marginRight: 'unset'}}
      className={`select-company col-md-3 ${categoryId ? 'color-placeholder' : ''}`}
      onChange={(e) => {setCategoryId(parseInt(e.target.value))}}>
      <option value="" disabled hidden>{t('please_choose')}</option>
      {categories.map((val) => (
        <option key={val?.id} value={val.id}>{val.name}</option>
      ))}
    </select>
  );
}

export default Index;
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {clearState, getListCities} from "../../redux/company/cities.slide";
import PropTypes from "prop-types";

Index.propTypes = {
  getCity: PropTypes.func,
  response: PropTypes.number,
}
Index.defaultProps = {
  getCity: () => {},
  response: null,
}
function Index(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {getCity, response} = props;
  const {isSuccessCity, listCities} = useSelector((state) => state.city);
  const [cities, setCities] = useState([]);
  const [cityId, setCityId] = useState(null);

  useEffect(() => {
    dispatch(getListCities());

    return () => dispatch(clearState());
  }, []);

  useEffect(() => {
    setCities(listCities?.data ?? []);
  }, [isSuccessCity]);

  useEffect(() => {
    getCity(cityId)
  }, [cityId]);

  useEffect(() => {
    setCityId(response);
  }, [response]);

  return (
    <select value={cityId ?? ''} name="city_id" id="" className={`select-company col-md-3 ${cityId ? 'color-placeholder' : ''}`} onChange={(e) => {setCityId(parseInt(e.target.value))}}>
      <option value="" disabled hidden>{t('update_coach.placeholder.address')}</option>
      {cities.map((val, index) => (
        <option key={index} value={val.id}>{val.name}</option>
      ))}
    </select>
  );
}

export default Index;
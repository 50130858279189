import React, {useEffect, useState} from 'react';
import BannerCoach from "../Company/BannerCoach";
import {useTranslation} from "react-i18next";
import imageDefault from '../../assets/image/issue_default.svg';
import soundImg from '../../assets/image/sound.svg';
import heartImg from '../../assets/image/favorite.svg';
import heartImgActive from '../../assets/image/favorite_active.svg';
import {useDispatch, useSelector} from "react-redux";
import {clearState, listIssue} from "../../redux/issue/issue.slide";
import {getTypeIssue} from "../../helper";
import PropTypes from "prop-types";
import {post} from '../../services'
import {Link, useNavigate} from "react-router-dom";
import {issueStatus, typeUser} from "../../constant";

Content.propTypes = {
  loading: PropTypes.func,
  type: PropTypes.string,
}
Content.defaultProps = {
  loading: () => {},
  type: '',
}
function Content(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {loading, type} = props;
  const {isFetching, isSuccessIssue, isErrorIssue, listIssues} = useSelector(state => state.issue);
  const [issues, setIssues] = useState([]);
  const [themeName, setThemeName] = useState('');
  const [mode, setMode] = useState('');
  const [isFavorite, setIsFavorite] = useState(0);
  const [isGetFavorite, setIsGetFavorite] = useState(0);
  const [selectFavorite, setSelectFavorite] = useState('');
  const [selectMode, setSelectMode] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [disabledFavorite, setDisabledFavorite] = useState(false);
  const statusArr = [issueStatus.start, issueStatus.end, issueStatus.open, issueStatus.complete];
  const [isCall, setIsCall] = useState(true);

  useEffect(() => {
    dispatch(listIssue({status: statusArr}));
    return () => dispatch(clearState());
  }, []);

  useEffect(() => {
    if (isSuccessIssue) {
      if (isLoadMore) {
        setIssues([...issues, ...listIssues?.data?.data ?? []]);
      } else {
        setIssues(listIssues?.data?.data ?? []);
      }
      setCurrentPage(listIssues?.data.meta.current_page);
      setLastPage(listIssues?.data.meta.last_page);
      setIsCall(true);
      window.scrollTo(0, window.scrollY - 10);
    }
  }, [isSuccessIssue, isErrorIssue]);

  useEffect(() => {
    loading(isFetching);
  }, [isFetching]);
  const handleSearchIssue = (e) => {
    e.preventDefault();
    setIsCall(true);
    setIsLoadMore(false);
    dispatch(listIssue(paramSearch()));
  }

  const paramSearch = () => {
    const params = {
      mode: mode,
      theme_name: themeName,
      status: statusArr,
    };
    if (isGetFavorite) {
      params.is_get_favorite = isGetFavorite;
      params.is_favorite = isFavorite;
    }

    return params;
  }

  const handleScroll = () => {
    const bottom = Math.ceil(window.innerHeight + window.scrollY) === document.documentElement.scrollHeight;
    if (bottom && currentPage < lastPage && isCall) {
      setIsCall(false);
      setIsLoadMore(true);
      const params = paramSearch();
      params.page = currentPage + 1;
      dispatch(listIssue(params));
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {
      passive: true
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [currentPage, lastPage]);


  const toggleFavorite = async (id) => {
    setDisabledFavorite(true);
    const url = `issues/${id}/favorite`;
    const response = await post(url, {});
    const favoriteElement = document.querySelector(`.favorite-icon[data-id='${id}']`);
    if (favoriteElement) {
      favoriteElement.src = response.data.data ? heartImgActive : heartImg;
    }
    setDisabledFavorite(false);
  }

  const updateInfo = () => {
    let urlNavigate = '';
    switch (type) {
      case typeUser.student:
        urlNavigate = '/student/update';
        break;
      case typeUser.coach:
        urlNavigate = '/coach/update';
        break;
      case typeUser.teacher:
        urlNavigate = '/teacher/update';
    }
    navigate(urlNavigate);
  }

  return (
    <div>
      <BannerCoach title={t('banner_student.title')} des={t('banner_student.student_coach')} type={type}/>
      <div className='content-my-page'>
        <div className='group-btn-feature'>
          <button className='btn-feature btn-feature-custom btn-feature-my-page' onClick={updateInfo}>{type === typeUser.teacher ? t('teacher_edit_profile') : t('my_page_student.update')}</button>
          {type === typeUser.student ?
            <Link to='/student/issue-apply'>
              <button className='btn-feature btn-feature-custom btn-feature-my-page'>{t('list_issue_apply')}</button>
            </Link> : type === typeUser.coach ?
              <Link to='/coach/issue-apply'>
                <button className='btn-feature btn-feature-custom btn-feature-my-page'>{t('my_page_student.new_mission')}</button>
              </Link>
              : ''
          }
        </div>

        <form className="condition-search" onSubmit={handleSearchIssue}>
          <div className="title-search">{t('my_page_student.search_title')}</div>
          <select defaultValue='' className={"select-company select-search select-type " + selectMode} onChange={(e) => {setMode(e.target.value); setSelectMode('select-focus')}}>
            <option value='' disabled hidden>{t('my_page_student.placeholder_online')}</option>
            <option value=''>{t('my_page_student.please_choose')}</option>
            <option value='is_remote'>Online</option>
            <option value='is_face_to_face'>Offline</option>
            <option value='all'>Hybrid</option>
          </select>
          <select defaultValue='' className={"select-company select-search select-location " + selectFavorite} onChange={(e) => {setIsGetFavorite(e.target.value === '1' ? 1 : 0); setIsFavorite(parseInt(e.target.value)); setSelectFavorite('select-focus')}} >
            <option value='' disabled hidden>{t('my_page_student.placeholder_favorite')}</option>
            <option value=''>{t('my_page_student.please_choose')}</option>
            <option value='1'>お気入り</option>
          </select>
          <input type="text" className="input-search-keyword" placeholder={t('my_page_student.placeholder_key_search')} onChange={(e) => {setThemeName(e.target.value.trim())}} />
          <button className="btn-search">{t('company.search')}</button>
        </form>

        <div className='result-search-block'>
          <div className='d-flex justify-content-start align-items-center flex-wrap w-100'>
            <div className='course-list'>{t('my_page_student.course_list')}</div>
            <div className='course-list-sub'>{t('my_page_student.course_list_sub')}</div>
          </div>
          <div className={issues.length > 0 ? 'list-course' : 'w-100 mt-5'}>
            {issues.length === 0 && <div className="no-data">{t('no_data_issue')}</div>}
            {issues.map(value => (
              <div key={value.id} className='course-items'>
                <div className={value?.avatar ? 'avatar-issue' : 'avatar-issue avatar-issue-default-list'} style={{backgroundImage: `url(${value?.avatar ?? imageDefault})`}}>
                  <div className='header-course'>
                    <div className='d-flex justify-content-start align-items-center'>
                      <div className='type-issue-tag mr-3'>
                        <img src={soundImg} alt="icon" className='mr-2'/>
                        <div>{getTypeIssue(value?.is_remote, value?.is_face_to_face)}</div>
                      </div>
                      {!value?.is_free && <div className='type-issue-tag btn-fee-issue'>{t('my_page_student.fee')}</div>}
                    </div>
                    <div className='quantity-student-apply'>{value?.remain_date > 0 ? 'あと' + value?.remain_date + '日' : value?.remain_date === 0 ? t('last_day') : ''}</div>
                  </div>
                  <div className='w-100 d-flex justify-content-end pr-3'>
                    <img src={value?.is_favorite ? heartImgActive : heartImg} alt="icon" className={disabledFavorite ? 'favorite-icon pointer-event-none' : 'favorite-icon'} data-id={value?.id} onClick={() => toggleFavorite(value?.id)} />
                  </div>

                </div>
                <div className={value?.is_free ? 'info-course' : 'info-course issue-pay-fee'}>
                  <div className='title-course-custom' title={value?.theme_name}>{value?.theme_name}</div>
                  <div className='w-100 d-flex justify-content-center'>
                    <Link to={'/issues/' + value?.id} >
                      <button className='btn-detail-issue'>{t('see_more')}</button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Content;
import React, {useEffect, useState} from 'react';
import UrlExpired from "../../components/Modal/UrlExpired";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {clearState, signUpComplete} from "../../redux/auth/auth.slice";
import {typeUser} from '../../constant/index';

function CompleteSignUp() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [title, setTitle] = useState('');
  const [titleExpired, setTitleExpired] = useState('');
  const [content, setContent] = useState('');
  const {isSuccess, isError, errorMessage, response} = useSelector((state) => state.auth);
  const [isExpired, setIsExpired] = useState(false);
  const [loginUrl, setLoginUrl] = useState('');

  useEffect(() => {
    dispatch(signUpComplete({
      confirm_token: window.location.href.split('=').pop(),
    }));
    return () => {
      dispatch(clearState());
    };
  }, []);

  useEffect(() => {
    if (isSuccess) {
      if (response.data.data.role === typeUser.company) {
        setTitle(t('register_company.complete.title'));
        setContent(t('register_company.complete.content'));
        setLoginUrl('/company/login');
      }
      if (response.data.data.role === typeUser.student) {
        setTitle(t('student.title_complete'));
        setContent(t('student.complete'));
        setLoginUrl('/student/login');
      }
      if (response.data.data.role === typeUser.coach) {
        setTitle(t('coach.title_complete'));
        setContent(t('coach.complete'));
        setLoginUrl('/coach/login');
      }
      if (response.data.data.role === typeUser.teacher) {
        setTitle(t('teacher.title_complete_register'));
        setContent(t('teacher.content_complete_register'));
        setLoginUrl('/teacher/login');
      }
    }
    if (isError) {
      if (errorMessage.status === 500) {
        alert(t('error_system'));
      }
      if (errorMessage.status === 410) {
        setTitleExpired(t('register_account'));
        setIsExpired(true);
      }
    }
    return () => {
      dispatch(clearState());
    };
  }, [isSuccess, isError]);
  function handleClick() {
    navigate(loginUrl);
  }

  return (
    <>
      {isExpired ? <UrlExpired title={titleExpired} /> :
        <div className="form-register form-complete d-flex flex-column justify-content-between">
          <div className="title-form">{title}</div>
          <div className="main-text">{content}</div>
          <button className="button-primary" onClick={handleClick}>{t('login')}</button>
        </div>}
    </>
  );
}

export default CompleteSignUp;
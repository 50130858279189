import React  from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import {useSelector} from "react-redux";

const PrivateRouter = () => {
  const { isSignedIn } = useSelector((state) => state.user);
  return isSignedIn ? <Outlet /> : <Navigate to='/' />;
};

export default PrivateRouter;

import React from 'react';
import {Button, Modal} from "react-bootstrap";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

EvaluateConfirm.propTypes = {
  todos: PropTypes.bool,
  closeModal: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.string,
  handleSubmit: PropTypes.func,
};

EvaluateConfirm.defaultProps = {
  todos: false,
  closeModal: null,
  title: '',
  content: '',
  handleSubmit: null,
};

function EvaluateConfirm(props) {
  const {t} = useTranslation();
  const { todos, closeModal, title, content, handleSubmit } = props;

  return (
    <Modal show={todos} centered>
      <Modal.Header>
        <Modal.Title>
          <div className="title-form">{title}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="main-text">{content}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => closeModal(!todos)}>
          <div>{t('register_company.modal_button')}</div>
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          <div>{t('btn_evaluate')}</div>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EvaluateConfirm;
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {get, deleteMethod} from "../../services";

const initialState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: '',
  responseUser: null,
  isFetchingAdmin: false,
  isSuccessAdmin: false,
  isErrorAdmin: false,
  errorMessageAdmin: '',
  errorMessageUser: '',
  errorMessageDeleteUser: '',
};
export const userInfo = createAsyncThunk(
  'user_info',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'users/' + params?.id;
      const response = await get(url, {}, params);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

export const deleteUser = createAsyncThunk(
  'users/delete',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'users/' + params?.id;
      const response = await deleteMethod(url, params, true);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

const managerUserSlide = createSlice({
  name: 'managerUsers',
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isFetching = false;
      state.isSuccess = false;
      state.errorMessage = '';
      state.responseUser = null;
      state.isFetchingAdmin = null;
      state.isSuccessAdmin = false;
      state.isErrorAdmin = false;
      state.errorMessageAdmin = '';
      state.errorMessageUser = '';
      state.errorMessageDeleteUser = '';
      return state;
    },
  },
  extraReducers: {
    [userInfo.pending.type]: (state) => {
      state.errorMessageUser = '';
      state.isError = false;
      state.isFetching = true;
      state.isSuccess = false;
      state.responseUser = null;
    },
    [userInfo.fulfilled.type]: (state, { payload }) => {
      state.isError = false;
      state.isSuccess = true;
      state.isFetching = false;
      state.errorMessageUser = '';
      state.responseUser = payload.data;
    },
    [userInfo.rejected.type]: (state, { payload }) => {
      state.isError = true;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessageUser = payload;
      state.responseUser = null;
    },

    [deleteUser.pending.type]: (state) => {
      state.errorMessageDeleteUser = '';
      state.isErrorDelete = false;
      state.isFetchingDelete = true;
      state.isSuccessDelete = false;
    },
    [deleteUser.fulfilled.type]: (state) => {
      state.errorMessageDeleteUser = '';
      state.isErrorDelete = false;
      state.isSuccessDelete = true;
      state.isFetchingDelete = false;
    },
    [deleteUser.rejected.type]: (state, { payload }) => {
      state.errorMessageDeleteUser = payload;
      state.isErrorDelete = true;
      state.isSuccessDelete = false;
      state.isFetchingDelete = false;
    },
  },
});

export default managerUserSlide;

export const { clearState } = managerUserSlide.actions;

import React from 'react';
import {Button, Modal} from "react-bootstrap";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

DeleteData.propTypes = {
  todos: PropTypes.bool,
  closeModal: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.string,
  handleDelete: PropTypes.func,
  name: PropTypes.string,
}
DeleteData.defaultProps = {
  todos: false,
  closeModal: null,
  title: '',
  content: '',
  handleDelete: null,
  name: '',
}
function DeleteData(props) {
  const {t} = useTranslation();
  const {todos, closeModal, title, content, handleDelete, name} = props;

  return (
    <Modal show={todos} centered>
      <Modal.Header>
        <Modal.Title>
          <div className="title-form">{title}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="main-text">{content}</div>
        <div className='main-text'>{t('admin.name')}：{name}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => closeModal(!todos)}>
          <div>{t('register_company.modal_button')}</div>
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          <div>{t('admin.delete')}</div>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteData;
const ja = {
  translation: {
    logo: 'LOGO',
    project_information: '案件情報',
    teacher_registration: '教員登録',
    student_registration: '学生登録',
    company_registration: '企業登録',
    opera_company: '運営会社',
    inquiry: '問い合わせ',
    login: 'ログイン',
    logout: 'ログアウト',
    feature: 'FEATURE',
    special_seminar: '特集ゼミ',
    see_more: '詳しく見る',
    service: 'Service',
    service_feature: 'サービスの特徴',
    description_service_1: 'アクションラーニングは企業の課題を',
    description_service_2: '学生とともに解決するプラットフォーム です。',
    pick_up: 'pick-up',
    project_pick_up: '案件ピックアップ',
    form_register_company: '企業登録',
    email: 'ID（メールアドレス）',
    email_input: 'メールアドレス入力',
    placeholder_email: 'ID（メールアドレス）入力',
    send: '送信',
    password: 'パスワード',
    password_confirm: 'パスワード確認',
    password_placeholder: 'パスワード入力',
    password_confirm_placeholder: 'パスワード入力確認',
    teacher_edit_profile: '教員のあなたへ',
    register_company: {
      modal_title: '企業登録',
      modal_content: 'お手続きありがとうございます。認証メールを送らせていただきましたのでご確認ください。受信されたメールに記載のURLよりお進みください。',
      modal_button: '閉じる',
      expired: '認証URLの有効期限が過ぎています。',
      create_pass_success: 'パスワード登録が完了致しました。',
      complete: {
        title: '企業登録完了',
        content: '企業登録ありがとうございました。',
        button: 'ログイン"',
      },

    },
    register: '登録',
    id: 'ID',
    password_login: 'パスワード',
    forgot_password: 'パスワードお忘れの方',
    error_system: '予期せぬエラーが発生しました',
    company_name: '企業名',
    date_of_establishment: '設立年月日',
    email_address: 'メールアドレス',
    phone_number: '電話番号',
    location: '所在地',
    web_url: 'サイトURL',
    bio: '企業紹介',
    expectations: '参加学生に期待することなど',
    application_form: 'application FORM',
    register_info_company: '情報登録（企業）',
    avatar: 'プロフィール写真を',
    upload_large_size_avatar: '写真ファイルのサイズが1ファイル1MBを超えています。1MB以下の写真ファイルを選択して下さい。',
    can_setting: '設定できます',
    allow_student_view: '学生の閲覧を許可する',
    privacy_policy: 'プライバシーポリシー',
    update: '更新する',
    process_info: '（個人情報の取扱いについて）',
    placeholder: {
      company_name: 'アクション太郎',
      date_establishment: {
        year: '1993年',
        month: '8月',
        day: '9日',
      },
      email: 'info@action-learning.com',
      phone_number: '00-0000-0000',
      location: '福岡県',
      web_url: 'https://action-learning.com',
      bio: 'ここにテキストが入ります。',
      expectations: 'ここにテキストが入ります。',
      detail_location: '福岡県',
      register_object: '大学生',
      specialized: '特になし',
      zone: '福岡県',
      person_join: '4人',
      organize_join: '4人',
      zoom_url: 'https://zoom.us/12345',
      name_issue: 'ここに課題テーマを入力してください。',
      detail_issue: 'ここにテキストが入ります。',
    },
    see_more_text: 'もっと見る',
    forget_password: 'パスワードをお忘れの方',
    description_forget_password: 'パスワードをお忘れの方は、以下にご登録のメールアドレスを入力してください。',
    success_forget_password: 'ご登録のメールアドレスに、パスワード再設定のメールをお送りしました。\nご確認ください。',
    fail_forget_password: '何らかのエラーが発生しました。もう一度ご入力してください。',
    complete_forget_password: 'パスワード再設定完了',
    content_complete_password: 'パスワードの再設定が完了しました。\n新しいパスワードにて再度ログインをしてください。',
    reset_password_title: 'パスワード再設定',
    update_company_title: '企業情報更新',
    update_company_success: '企業情報更新を完了致しました。',
    update_company_error: '企業情報更新に失敗致しました。',
    coach: {
      list: 'コーチ一覧',
      for_company: '企業の方へ',
      coaches: 'coaches',
      complete: 'コーチ登録ありがとうございました。',
      title_complete: 'コーチ登録完了',
    },
    student: {
      list: '学生一覧',
      students: 'students',
      new: '新着学生',
      complete: '学生登録ありがとうございました。',
      title_complete: '学生登録完了',
    },
    company: {
      mission: '課題',
      find_coach: 'コーチを探す',
      find_student: '学生を探す',
      add_project: '新規案件情報を入力する',
      edit_company: '企業情報を編集する',
      find_person: '人を探す',
      type_student: '大学生',
      location: '地域で探す',
      keyword: 'フリーワード',
      search: '検索',
      please_choose: '選択してください',
      favorite_student: 'お気に入りの学生',
      type_school: '大学',
    },
    register_student: '学生登録',
    register_coach: 'コーチ登録',
    register_account: '会員登録',
    no_data: 'データがありません。',
    issue_registration: 'ISSUE REgisteration',
    condition_register: '課題条件の登録',
    can_set_image: '写真を設定できます',
    remote: 'Online',
    face_to_face: 'Offline',
    paid: '有料',
    free: '無料',
    date: '開催日時',
    register_object: '対象条件',
    specialized: '学部条件',
    zone: '希望地域',
    person_join: '学生応募数',
    organize_join: '主催者側参加人数',
    zoom_url: 'zoomURL',
    name_issue: '課題テーマ',
    detail: '詳細',
    save_draft: '一時保存',
    save_issue: '登録する',
    placeholder_year_issue: '2023年',
    back: 'に戻る',
    my_page: 'マイページ',
    top_page: 'トップページ',
    date_register: '掲載日：',
    edit_issue: '編集する',
    default_select: '------',
    update_coach: {
      register_info_coach: '情報登録（コーチ）',
      name: '名前',
      gender: '性別',
      placeholder: {
        gender: '男性',
        email: 'mail@action-learning.com',
        phone_number: '090-1234-5678',
        address: '福岡県',
        bio: 'ここにテキストが入ります。',
        skills: 'ここにテキストが入ります。',
      },
      birthday: '生年月日',
      email: 'メールアドレス',
      phone_number: '電話番号',
      address: '地域',
      bio: '自己紹介',
      skills: '言語・保有スキルなど',
      accept_company_view: '企業の観覧を許可する',
      policy: 'プライバシーポリシー',
      policy2: '（個人情報の取扱いについて）',
      register: '登録する',
      edit: '更新',
      back_top_page: 'トップページに戻る',
      back_my_page: 'マイページに戻る',
      modal_title: 'コーチ情報更新',
      success_msg: 'コーチ情報更新を完了致しました。',
      error_msg: 'コーチ情報更新に失敗致しました。',
      update: '更新',
    },
    update_student: {
      register_info_student: '情報登録（学生）',
      type_school: '学校種類',
      name_school: '学校名・学部名',
      accept_company_view: '企業の閲覧を許可する',
      major: '学部分類',
      school_year: '卒業年',
      placeholder: {
        type_school: '専門學学校',
        name_school: 'アクションデザイン専門学校',
        major: 'UI/UXデザイン学部',
      },
      modal_title: '学生情報更新',
      success_msg: '学生情報更新を完了致しました。',
      error_msg: '学生情報更新に失敗致しました。',
    },
    type_login: {
      company: '案件依頼企業用ログイン',
      coach: 'コーチ用ログイン',
      student: '学生用ログイン',
      teacher: '教員用ログイン',
    },
    modal_login_error: {
      title: 'ログインに失敗しました。',
      content: '学生用のログイン情報をもう一度入力してください。',
      button: '閉じる',
    },
    add_issue: {
      modal: {
        title_draft: '一時保存の課題登録',
        content_draft_success: '一時保存の課題登録を完了しました。',
        content_draft_error: '一時保存の課題登録に失敗しました。',
        title_save: '課題登録',
        content_save_success: '課題登録を完了しました。',
        content_save_error: '課題登録に失敗しました。',
      }
    },
    skill_detail: '興味のあるジャンル・資格・保有スキルなど',
    send_message: 'メッセージする',
    list_comment: '企業コメント一覧',
    message: {
      title_page: 'MESSAGE HISTORY',
      title_page_sub: 'メッセージ履歴',
      back_my_page: 'マイページへ',
      send_a_message: 'メッセージを送信する',
      btn_send: '送信する',
      read: '既読',
      unread: '未読',
      btn_list_apply: '応募データ',
      data_application: '応募データ',
      name_student: '氏名',
      no_data: 'メッセージ履歴がありません。',
      user_deleted:  'ブロック済みのユーザーにはメッセージを送信できません。',
    },
    label_company_info: {
      title: '企業情報',
      name: '企業名：',
      birthday: '設立年月日：',
      email: 'メールアドレス：',
      phone_number: '電話番号：',
      address: '所在地：',
      web_url: 'サイトURL：',
      bio: '企業紹介：',
    },
    back_btn: '戻る',
    banner_student: {
      title: '講座一覧',
      student_coach: '学生・コーチのあなたへ',
    },
    my_page_student: {
      update: '個人情報入力する',
      new_mission: '課題閲覧',
      search_title: '講座を探す',
      placeholder_online: 'オンライン',
      please_choose: '選択してください',
      placeholder_favorite: 'お気に入り',
      placeholder_key_search: 'ワード絞り込み',
      course_list: 'Course List',
      course_list_sub: '講座一覧',
      fee: '有料',
    },
    apply: '応募する',
    teacher: {
      register: '教員登録',
      title_complete_register: '教員登録完了',
      content_complete_register: '教員登録ありがとうございました。',
      register_info: '情報登録（教員）',
      name: '名前',
      school_name: '学校名',
      placeholder_school: 'アクションデザイン専門学校',
      specialize: '専門分野',
      placeholder_specialize: 'フォーム内',
      title_modal: '教員情報更新',
      content_modal_success: '教員情報更新を完了致しました。',
      content_modal_error: '教員情報更新に失敗致しました。',
    },
    form_login: {
      login: 'LOGIN',
      title: 'ログインページ',
      email: 'メールアドレス',
      placeholder_email: 'mail@action-learning.com',
      register: '新規登録',
      forget_password: 'アカウントを持っていない方',
    },
    admin: {
      admin: 'ADMIN',
      manager_company: '企業管理',
      manager_student: '学生管理',
      manager_coach: 'コーチ管理',
      manager_issue: '案件管理',
      manager_teacher: '教員管理',
      manager_data_general: '共通マスタデータ管理',
      manager_gender: '性別',
      manager_object_student: '対象条件',
      manager_quantity_student: '定員',
      manager_quantity_organizer: '主催側参加人数',
      manager_school_type: '学校種類',
      manager_school_name: '学校名',
      manager_major: '学部分類',
      manager_school_year: '卒業年',
      manager_year: '学年',
      manager_member_status: '会員ステータス',
      manager_issue_status: '案件ステータス',
      manager_quantity: '人数設定',
      setting_school_year_label: '学年設定',
      manager_category: 'カテゴリー管理',
      company: {
        title: '企業管理',
        name: '企業名',
        email: 'メールアドレス',
        confirm_delete: 'このユーザーを削除致します。よろしいですか？',
        title_delete: '企業削除',
        title_search: '検索条件',
        btn_search: '検索する',
        placeholder_search: '名前又はメールアドレスを入力してください',
        delete_active_company_error: 'この企業はアクティブの課題がありますので、削除できません。',
      },
      edit: '編集',
      delete: '削除',
      status: '会員ステータス',
      active: 'アクティブ',
      de_active: '一停止',
      back_top_manger_company: '企業管理へ戻る',
      back_top_manager_student: '学生管理へ戻る',
      back_top_manager_coach: 'コーチ管理へ戻る',
      back_top_manager_teacher: '教員管理へ戻る',
      modal_delete: {
        title: '企業削除',
        content_success: '企業削除を完了致しました。',
        content_error: '企業削除に失敗致しました。',
      },
      student: {
        name: '学生名',
        title_delete: '学生削除',
        confirm_delete: 'このユーザーを削除します。よろしいですか？',
        delete_success: '学生削除を完了致しました。',
        delete_error: '学生削除に失敗致しました。',
        delete_active_student_error: 'この学生は課題に参加していますので、削除できません。',
      },
      coach: {
        name: 'コーチ名',
        title_delete: 'コーチ削除',
        confirm_delete: 'このユーザーを削除します。よろしいですか？',
        delete_success: 'コーチ削除を完了致しました。',
        delete_error: 'コーチ削除に失敗致しました。',
        delete_active_coach_error: 'このコーチは課題に参加していますので、削除できません。',
      },
      teacher: {
        name: '教員名',
        title_delete: '教員削除',
        confirm_delete: 'このユーザーを削除します。よろしいですか？',
        delete_success: '教員削除を完了致しました。',
        delete_error: '教員削除に失敗致しました。',
      },
      issue: {
        name: '案件名',
        status: '案件ステータス',
        placeholder_search: '課題名と課題ステータスを入力してください',
        choose_coach: '選択',
        title_delete: '案件削除',
        content_delete: 'この案件を削除致します。よろしいですか？',
        coach: 'コーチ選択',
        coach_selected: 'コーチ名',
        delete_success: '案件削除をを完了致しました。',
        delete_error: '案件削除に失敗致しました。',
        change_coach: '変更',
        join_success: '課題対応にコーチの追加を完了致しました。',
        join_error: '課題対応にコーチの追加に失敗致しました。',
        title_join: 'コーチ追加',
        title_update: '案件更新',
        update_success: '案件更新を完了致しました。',
        update_error: '案件更新に失敗致しました。',
      },
      category: {
        name: 'カテゴリー名',
      },
      quantity: {
        student: '学生応募数',
        organizer: '主催者側参加人数',
      },
      list_coach: 'コーチ一覧',
      name_coach: 'コーチ名',
      view_profile: 'プロフィール',
      choose: '選',
      register_coach: 'コーチ登録',
      register_btn: '新規登録',
      name_title: '名前',
      gender: {
        name: '性別',
        register: '性別登録',
        placeholder: 'キーワードを入力してください。',
      },
      major: {
        name: '学部名',
      },
      object_student: {
        name: '対象条件',
      },
      type_school: {
        name: '種類',
      },
      name: '名前',
      placeholder_search_name: '名前をご検索してください。',
      placeholder_keyword: 'キーワードをご入力してください。',
      data: {
        create: {
          gender: '性別登録',
          student_object: '対象条件登録',
          student_join: '学生の参加数登録',
          organizer_join: '主催側参加人数登録',
          school_type: '学校種類登録',
          school_name: '学校名登録',
          major: '学部登録',
          school_year: '学年登録',
          member_status: '会員ステータス登録',
          issue_status: '案件ステータス登録',
          category: 'カテゴリー登録',
        },
        placeholder_name: '名前をご入力してください。',
        edit: {
          gender: '性別編集',
          student_object: '対象条件編集',
          student_join: '学生の参加数を編集する',
          organizer_join: '主催側参加人数編集',
          school_type: '学校種類編集',
          school_name: '学校名編集',
          major: '学部編集',
          school_year: '学年編集',
          member_status: '会員ステータス編集',
          issue_status: '案件ステータス編集',
          category: 'カテゴリー編集',
        },
        delete: {
          gender: '性別削除',
          student_object: '対象条件削除',
          student_join: '学生の参加数を削除する',
          organizer_join: '主催側参加人数削除',
          school_type: '学校種類削除',
          school_name: '学校名削除',
          major: '学部削除',
          school_year: '学年削除',
          member_status: '会員ステータス削除',
          issue_status: '案件ステータス削除',
          category: 'カテゴリー削除',
        },
        create_success: {
          gender: '性別登録を完了致しました。',
          student_object: '対象条件登録を完了致しました。',
          student_join: '登録定員を完了致しました。',
          organizer_join: '主催側参加人数登録を完了致しました。',
          school_type: '学校種類登録を完了致しました。',
          school_name: '学校名登録を完了致しました。',
          major: '学部登録を完了致しました。',
          school_year: '学年登録を完了致しました。',
          member_status: '会員ステータス登録を完了致しました。',
          issue_status: '案件ステータス登録を完了致しました。',
          category: 'カテゴリー登録を完了致しました。',
        },
        create_error: {
          gender: '性別登録に失敗致しました。',
          student_object: '対象条件登録に失敗致しました。',
          student_join: '登録定員に失敗致しました。',
          organizer_join: '主催側参加人数登録に失敗致しました。',
          school_type: '学校種類登録に失敗致しました。',
          school_name: '学校名登録に失敗致しました。',
          major: '学部登録に失敗致しました。',
          school_year: '学年登録に失敗致しました。',
          member_status: '会員ステータス登録に失敗致しました。',
          issue_status: '案件ステータス登録に失敗致しました。',
          category: 'カテゴリー登録に失敗致しました。',
        },
        edit_success: {
          gender: '性別編集を完了致しました。',
          student_object: '対象条件編集を完了致しました。',
          student_join: '定員編集を完了致しました。',
          organizer_join: '主催側参加人数編集を完了致しました。',
          school_type: '学校種類編集を完了致しました。',
          school_name: '学校名編集を完了致しました。',
          major: '学部編集を完了致しました。',
          school_year: '学年編集を完了致しました 。',
          member_status: '会員ステータス編集を完了致しました。',
          issue_status: '案件ステータス編集を完了致しました。',
          quantity: '参加人数編集を完了致しました。',
          setting_school_year: '学年設定更新を完了致しました。',
          category: 'カテゴリー編集を完了致しました。',
        },
        edit_error: {
          gender: '性別編集に失敗致しました。',
          student_object: '対象条件編集に失敗致しました。',
          student_join: '定員編集に失敗致しました。',
          organizer_join: '主催側参加人数編集に失敗致しました。',
          school_type: '学校種類編集に失敗致しました。',
          school_name: '学校名編集に失敗致しました。',
          major: '学部編集に失敗致しました。',
          school_year: '学年編集に失敗致しました。',
          member_status: '会員ステータス編集に失敗致しました。',
          issue_status: '案件ステータス編集に失敗致しました。',
          quantity: '参加人数編集に失敗致しました。',
          setting_school_year: '学年設定更新に失敗しました。',
          category: 'カテゴリー編集に失敗致しました。',
        },
        content_delete: {
          gender: 'この性別を削除致します。よろしいですか。',
          student_object: 'この対象条件を削除致します。よろしいですか。',
          student_join: 'この定員を削除致します。よろしいですか。',
          organizer_join: 'この主催側参加人数を削除致します。よろしいですか。',
          school_type: 'この学校種類を削除致します。よろしいですか。',
          school_name: 'この学校名を削除致します。よろしいですか。',
          major: 'この学部を削除致します。よろしいですか。',
          school_year: 'この学年を削除致します。よろしいですか。',
          member_status: 'この会員ステータスを削除致します。よろしいですか。',
          issue_status: 'この案件ステータスを削除致します。よろしいですか。',
          category: 'このカテゴリーを削除致します。よろしいですか。',
        },
        delete_success: {
          gender: '性別削除を完了致しました。',
          student_object: '対象条件削除を完了致しました。',
          student_join: '定員削除を完了致しました。',
          organizer_join: '主催側参加人数削除を完了致しました。',
          school_type: '学校種類削除を完了致しました。',
          school_name: '学校名削除を完了致しました。',
          major: '学部削除を完了致しました。',
          school_year: '学年削除を完了致しました。',
          member_status: '会員ステータス削除を完了致しました。',
          issue_status: '案件ステータス削除を完了致しました。',
          category: 'カテゴリー削除を完了致しました。',
        },
        delete_error: {
          gender: '性別削除に失敗致しました。',
          student_object: '対象条件削除に失敗致しました。',
          student_join: '定員削除に失敗致しました。',
          organizer_join: '主催側参加人数削除に失敗致しました。',
          school_type: '学校種類削除に失敗致しました。',
          school_name: '学校名削除に失敗致しました。',
          major: '学部削除に失敗致しました。',
          school_year: '学年削除に失敗致しました。',
          member_status: '会員ステータス削除に失敗致しました。',
          issue_status: '案件ステータス削除に失敗致しました。',
          category: 'カテゴリー削除に失敗致しました。',
        },
        title_update_quantity: ' 参加人数編集',
        title_update_setting_school_year: '学年を変更します'
      }
    },
    please_choose: '選択してください。',
    close: '閉じる',
    user_info: {
      name: '名前：',
      gender: '性別：',
      birthday: '生年月日：',
      email: 'メールアドレス：',
      phone_number: '電話番号：',
      city: '地域：',
      bio: '自己紹介：',
      skills: '言語・保有スキルなど：',
      back: '戻る',
    },
    issue_update: 'ISSUE UPDATE',
    issue_update_sub: '課題条件の編集',
    company_issue: {
      title: '課題一覧',
      list_issue: '登録中の課題一覧を見る',
      update_profile: '企業情報を編集する',
      list: '絞り込み検索',
      category: 'カテゴリー',
      status: 'ステータス',
      participant: '参加人数',
    },
    student_issue: {
      title: '課題一覧',
      list_issue: '応募中の課題一覧を見る',
      update_profile: '学生情報を編集する',
      list: '絞り込み検索',
      keyword: 'ワード絞り込み',
      please_type: '入力してください',
    },
    coach_issue: {
      title: '課題一覧',
      list_issue: '参加中の課題一覧を見る',
      update_profile: 'コーチ情報を編集する'
    },
    back_previous: '前のページに戻る',
    list_issue_register: '登録した課題一覧',
    list_issue_apply: '応募中の課題一覧',
    same_email: '入力したメールが登録されたフォームと一致しません。',
    update_email: {
      update: 'メールを更新する',
      title: 'メール更新',
      tutorial: '新しいメールアドレスをご入力のうえ、「送信する」を押してください。',
      tutorial2: '入力いただいたメールアドレスに認証メールが届きます。',
      email: 'メールアドレス',
      btn: '送信する',
      confirm: 'メール認証',
      send_success: 'お手続きありがとうございます。認証メールを送らせていただきましたのでご確認ください。受信されたメールに記載のURLよりお進みください。',
      title_complete: 'メールアドレス更新完了',
      complete_success: 'メールアドレスの更新が完了いたしました。',
      back_to_my_page: 'マイページへ',
    },
    cancel: 'キャンセル',
    yes: 'はい',
    student_apply_issue: {
      modal_title: '応募課題',
      confirm_apply: 'この課題に確かに応募しますか。',
      apply_ed: 'この課題に応募完了しました。',
      max: 'この課題の学生応募数は満員となっています。',
      only_student: '学生のみ課題に申し込む権限があります。',
      apply_success: '応募を完了しました。',
      apply_error: '応募に失敗しました。',
    },
    modal_login: {
      title: 'ロールをご選択ください。',
      student: '学生用ログイン',
      coach: 'コーチ用ログイン',
      company: '企業用ログイン',
      teacher: '教員用ログイン',
    },
    comment_student: '評価',
    complete_issue: '課題完了',
    title_complete_issue: '課題完了',
    content_complete_issue: '課題完了をしますか？',
    content_evaluate: '課題完了をしました。',
    btn_evaluate: '課題を評価する',
    list_student: '参加者の一覧',
    name_email: '名前（メールアドレス）',
    evaluate: {
      title: '参加者にお礼の「いいね！」を送ろう！',
      rate_1: 'ありがとうございました',
      rate_2: '気づきがありました',
      rate_3: '質問が上手です',
      rate_4: 'また参加ください',
      comment: 'コメント',
      sent: '送信する',
      no_data: 'データーがありません。',
      show: '評価とレビュー',
      edit: '評価を編集する',
    },
    please_type: '入力してください',
    notification: 'お知らせ',
    category: 'カテゴリー',
    online: 'オンライン',
    fee_issue: '有料課題',
    free_issue: '無料課題',
    university: '大学生',
    status_apply: '募集状況',
    public_date: '掲載日：',
    expired_date: '締切日：',
    no_data_issue: '該当する課題がありません。',
    last_day: '末日',
  },
};

export default ja;

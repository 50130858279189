import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {post, get} from "../../services";

const initialState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: '',
  responseStudent: null,
  currentRequestId: undefined,
  response: null,
};


export const updateStudent = createAsyncThunk(
  'auth/student/update-profile',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'auth/student/update-profile';
      const response = await post(url, params, true);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

export const detailStudent = createAsyncThunk(
  'users_student',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'users/' + params.id;
      const response = await get(url, {});
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

export const validateStudent = createAsyncThunk(
  'auth/students/validate-create-profile',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'auth/students/validate-create-profile';
      const response = await post(url, params, true);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

const studentsSlide = createSlice({
  name: 'students',
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isFetching = false;
      state.isSuccess = false;
      state.errorMessage = '';
      state.responseStudent = null;
      state.response = null;
      return state;
    },
  },
  extraReducers: {
    [updateStudent.pending.type]: (state, { meta }) => {
      const { requestId } = meta;
      if (!state.isFetching) {
        state.errorMessage = '';
        state.isError = false;
        state.isFetching = true;
        state.isSuccess = false;
        state.responseStudent = null;
        state.currentRequestId = requestId;
      }
    },
    [updateStudent.fulfilled.type]: (state, { payload, meta }) => {
      const { requestId } = meta;
      if (state.isFetching && state.currentRequestId === requestId) {
        state.isError = false;
        state.isSuccess = true;
        state.isFetching = false;
        state.errorMessage = '';
        state.responseStudent = payload.data;
        state.currentRequestId = undefined;
      }
    },
    [updateStudent.rejected.type]: (state, { payload, meta }) => {
      const { requestId } = meta;
      if (state.isFetching && state.currentRequestId === requestId) {
        state.isError = true;
        state.isSuccess = false;
        state.isFetching = false;
        state.errorMessage = payload;
        state.responseStudent = null;
        state.currentRequestId = undefined;
      }
    },

    [detailStudent.pending.type]: (state, { meta }) => {
      const { requestId } = meta;
      if (!state.isFetching) {
        state.errorMessage = '';
        state.isError = false;
        state.isFetching = true;
        state.isSuccess = false;
        state.response = null;
        state.currentRequestId = requestId;
      }
    },
    [detailStudent.fulfilled.type]: (state, { payload, meta }) => {
      const { requestId } = meta;
      if (state.isFetching && state.currentRequestId === requestId) {
        state.isError = false;
        state.isSuccess = true;
        state.isFetching = false;
        state.errorMessage = '';
        state.response = payload.data;
        state.currentRequestId = undefined;
      }
    },
    [detailStudent.rejected.type]: (state, { payload, meta }) => {
      const { requestId } = meta;
      if (state.isFetching && state.currentRequestId === requestId) {
        state.isError = true;
        state.isSuccess = false;
        state.isFetching = false;
        state.errorMessage = payload;
        state.response = null;
        state.currentRequestId = undefined;
      }
    },

    [validateStudent.pending.type]: (state) => {
      state.errorMessage = '';
      state.isError = false;
      state.isFetching = true;
      state.isSuccess = false;
    },
    [validateStudent.fulfilled.type]: (state) => {
      state.isError = false;
      state.isSuccess = true;
      state.isFetching = false;
      state.errorMessage = '';
    },
    [validateStudent.rejected.type]: (state, { payload }) => {
      state.isError = true;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = payload;
    },
  },
});

export default studentsSlide;

export const { clearState } = studentsSlide.actions;

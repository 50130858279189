import React, {useEffect, useState} from 'react';
import FormInfo from '../Issue/FormInfo';
import {useDispatch, useSelector} from "react-redux";
import {clearState, detailIssue} from "../../redux/issue/issue.slide";
import {useTranslation} from "react-i18next";
import Loading from "../../components/Loading";
function UpdateIssue() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {isFetching, isErrorIssue, isSuccessIssue, errorMessage, issueInfo} = useSelector(state => state.issue);
  const {isFetchingIssue} = useSelector(state => state.managerIssue);
  const [issue, setIssue] = useState(null);

  useEffect(() => {
    dispatch(detailIssue({id: window.location.href.split('/').pop()}));
    return () => dispatch(clearState());
  }, []);

  useEffect(() => {
    if (isErrorIssue) {
      if (errorMessage?.status === 500) {
        alert(t('error_system'));
      }
    }
    if (isSuccessIssue) {
      setIssue(issueInfo?.data);
    }
  }, [isSuccessIssue, isErrorIssue]);

  return (
    <>
      <FormInfo response={issue} type='update' />
      {isFetching || isFetchingIssue ? <Loading /> : null}
    </>
  );
}

export default UpdateIssue;
import React, {useEffect, useState} from 'react';
import {ErrorMessage, Field, FormikProvider, useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {typeAvatar} from "../../constant";
import AvatarUser from "../../components/SelectFile/AvatarUser";
import DatePicker from "../../components/DatePicker";
import LevelStudentSelect from '../../components/LevelStudent';
import MajorSelect from "../../components/Major";
import CitySelect from "../../components/City";
import QuantitySelect from '../../components/QuantityParticipant';
import IssueStatusSelect from '../../components/IssueStatus';
import CategorySelect from '../../components/Category';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import Notification from "../../components/Modal/Notification";
import {clearState, issueUpdate} from "../../redux/admin/manage-issue.slide";

FormInfo.propTypes = {
  response: PropTypes.any,
  type: PropTypes.string,
}
FormInfo.defaultProps = {
  response: null,
  type: '',
}
function FormInfo(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {response, type} = props;
  const [isFree, setIsFree] = useState(false);
  const [isRemote, setIsRemote] = useState(false);
  const [isFaceToFace, setIsFaceToFace] = useState(false);
  const [validateOnOff, setValidateOnOff] = useState('');
  const [validateDate, setValidateDate] = useState('');
  const [validateLevel, setValidateLevel] = useState('');
  const [validateMajor, setValidateMajor] = useState('');
  const [validateNumberStudent, setValidateNumberStudent] = useState('');
  const [validateNumberOrganizer, setValidateNumberOrganizer] = useState('');
  const [validateAvatar, setValidateAvatar] = useState('');
  const [validateStatus, setValidateStatus] = useState('');
  const [validateCategory, setValidateCategory] = useState('');
  const [date, setDate] = useState(null);
  const [file, setFile] = useState(null);
  const [levelStudentId, setLevelStudentId] = useState(null);
  const [majorId, setMajorId] = useState(null);
  const [cityId, setCityId] = useState(null);
  const [statusIssue, setStatusIssue] = useState(null);
  const [birthDay, setBirthDay] = useState('');
  const [quantityStudent, setQuantityStudent] = useState('');
  const [quantityOrganizer, setQuantityOrganizer] = useState('');
  const [urlAvatar, setUrlAvatar] = useState('');
  const [issueId, setIssueId] = useState(null);
  const {isSuccessIssueUpdate, isErrorIssueUpdate, errorMessageIssueUpdate} = useSelector(state => state.managerIssue);
  const [openModal, setOpenModal] = useState(false);
  const [contentModal, setContentModal] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);
  const [categoryId, setCategoryId] = useState(null);

  const formik = useFormik({
    initialValues: {
      zoom_url: '',
      theme_name: '',
      detail: '',
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      setIsSubmit(true);
      clearValidate();
      const dateMonth = date?.month < 10 ? '0' + date?.month : date?.month;
      const dateDay = date?.day < 10 ? '0' + date?.day : date?.day;
      let data = {...values,
        avatar: file,
        start_date: (!date?.day && !date?.month && !date?.year) ? null : date?.year + '-' + dateMonth + '-' + dateDay,
        user_level_id: levelStudentId,
        major_id: majorId,
        city_id: cityId,
        number_of_students_participating: quantityStudent,
        number_of_participants_from_the_organization: quantityOrganizer,
        is_remote: isRemote ? 1 : 0,
        is_face_to_face: isFaceToFace ? 1 : 0,
        is_free: isFree ? 1 : 0,
        category_id: categoryId,
        _method: 'PUT',
        status: statusIssue,
        id: issueId,
      };
      formik.setErrors({});
      dispatch(issueUpdate(data));
    }
  });

  useEffect(() => {
    setOpenModal(false);
    clearValidate();
    if (response) {
      formik.setFieldValue('zoom_url', response?.zoom_url || '');
      formik.setFieldValue('theme_name', response?.theme_name || '');
      formik.setFieldValue('detail', response?.detail || '');
      setIssueId(response?.id);
      setCityId(response?.city_id?.id);
      setBirthDay(response?.start_date ?? '');
      setMajorId(response?.major_id?.id);
      setLevelStudentId(response?.user_level_id?.id);
      setQuantityStudent(response?.number_of_students_participating);
      setQuantityOrganizer(response?.number_of_participants_from_the_organization);
      setIsRemote(response?.is_remote);
      setIsFaceToFace(response?.is_face_to_face);
      setIsFree(response?.is_free);
      setUrlAvatar(response?.avatar ?? '');
      setStatusIssue(response?.status);
      setCategoryId(response?.category?.id);
    }
  }, [response]);

  useEffect(() => {
    if (isErrorIssueUpdate) {
      if (errorMessageIssueUpdate?.status === 500) {
        setContentModal(t('admin.issue.update_error'));
        setOpenModal(true);
      }
      if (errorMessageIssueUpdate?.status === 422) {
        formik.setErrors({
          theme_name: errorMessageIssueUpdate?.data?.errors?.theme_name?.[0] || '',
          zoom_url: errorMessageIssueUpdate?.data?.errors?.zoom_url?.[0] || '',
          detail: errorMessageIssueUpdate?.data?.errors?.detail?.[0] || '',
        });
        setValidateNumberStudent(errorMessageIssueUpdate?.data?.errors?.number_of_students_participating?.[0] || '');
        setValidateNumberOrganizer(errorMessageIssueUpdate?.data?.errors?.number_of_participants_from_the_organization?.[0] || '');
        setValidateDate(errorMessageIssueUpdate?.data?.errors?.start_date?.[0] || '');
        setValidateAvatar(errorMessageIssueUpdate?.data?.errors?.avatar?.[0] || '');
        setValidateMajor(errorMessageIssueUpdate?.data?.errors?.major_id?.[0] || '');
        setValidateLevel(errorMessageIssueUpdate?.data?.errors?.user_level_id?.[0] || '');
        setValidateOnOff(errorMessageIssueUpdate?.data?.errors?.is_face_to_face?.[0] || '');
        setValidateStatus(errorMessageIssueUpdate?.data?.errors?.status?.[0] || '');
        setValidateCategory(errorMessageIssueUpdate?.data?.errors?.category_id?.[0] || '');
        for (let i in errorMessageIssueUpdate?.data?.errors) {
          let el = document.querySelector('.' + i);
          if (el) {
            el?.scrollIntoView();
            break;
          }
        }
      }
    }
    if (isSuccessIssueUpdate) {
      setContentModal(t('admin.issue.update_success'));
      setOpenModal(true);
    }

    return () => dispatch(clearState());
  }, [isSuccessIssueUpdate, isErrorIssueUpdate]);

  const clearValidate = () => {
    setValidateAvatar('');
    setValidateMajor('');
    setValidateNumberStudent('');
    setValidateNumberOrganizer('');
    setValidateDate('');
    setValidateOnOff('');
    setValidateLevel('');
    setValidateStatus('');
    setValidateCategory('');
  }

  const handleDatePicker = (date) => {
    setDate(date);
  }

  const getFile = (file) => {
    setFile(file);
  }

  const getLevelStudent = (id) => {
    setLevelStudentId(id);
  }

  const getMajor = (id) => {
    setMajorId(id);
  }

  const getCity = (id) => {
    setCityId(id);
  }

  const getQuantityStudent = (quantity) => {
    setQuantityStudent(quantity);
  }

  const getQuantityOrganizer = (quantity) => {
    setQuantityOrganizer(quantity);
  }

  const getIssueStatus = (id) => {
    setStatusIssue(id);
  }

  const getCategory = (id) => {
    setCategoryId(id);
  }

  const closeModal = () => {
    setOpenModal(false);
    navigate('/admin/issues');
  }

  return (
    <>
      <Notification todos={openModal} title={t('admin.issue.title_update')} content={contentModal} closeModal={closeModal} />
      <div className="container container-add-issue">
        <div className="header-add-issue">
          <div className="title-add-issue">{type === 'update' ? t('issue_update') : t('issue_registration')}</div>
          <div className="des-add-issue">{type === 'update' ? t('issue_update_sub') : t('condition_register')}</div>
        </div>
        <AvatarUser
          getFile={getFile}
          textBack=''
          textValidate={validateAvatar}
          backUrl=''
          avatar={urlAvatar}
          clearValidate={isSubmit}
          type={typeAvatar.issue}
        />

        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit} className="w-100">
            <div className="type-issue-block">
              <div className="remote-face is_face_to_face">
                <div className="d-flex justify-content-start align-items-center flex-wrap mr-5">
                  <div className="custom-checkbox custom-checkbox-issue">
                    <Field id="checkbox1" type="checkbox" className="checkbox-system is_remote" value="1" checked={isRemote} onChange={() => setIsRemote(!isRemote)} />
                    <label htmlFor="checkbox1"className=""></label>
                  </div>
                  <label htmlFor="checkbox1" className="main-text">{t('remote')}</label>
                </div>
                <div className="d-flex justify-content-start align-items-center">
                  <div className="custom-checkbox custom-checkbox-issue">
                    <Field id="checkbox2" type="checkbox" className="checkbox-system" value="1" checked={isFaceToFace} onChange={() => setIsFaceToFace(!isFaceToFace)} />
                    <label htmlFor="checkbox2"className=""></label>
                  </div>
                  <label htmlFor="checkbox2" className="main-text">{t('face_to_face')}</label>
                </div>
              </div>
              <div className="free-paid-block is_free">
                <button type="button" className={`btn-free-paid ${isFree ? '' : 'active'}`} onClick={() => {setIsFree(false)}} >{t('paid')}</button>
                <button type="button" className={`btn-free-paid ${isFree ? 'active' : ''}`} onClick={() => {setIsFree(true)}} >{t('free')}</button>
              </div>
            </div>
            <div className="text-error pl-unset mb-5">{validateOnOff}</div>
            <div className="form-group row col-md-12 start_date">
              <label htmlFor="">{t('date')}</label>
              <DatePicker date={handleDatePicker} response={birthDay} />
              <div className="text-error pl-unset">{validateDate}</div>
            </div>

            <div className="form-group col-md-12">
              <div className="form-group group-date-establishment">
                <div className="form-group-issue">
                  <label htmlFor="" className='user_level_id'>{t('register_object')}</label>
                  <LevelStudentSelect getLevelStudent={getLevelStudent} response={levelStudentId} />
                  <div className="text-error text-error-custom">{validateLevel}</div>
                </div>
                <div className="form-group-issue">
                  <label htmlFor="" className='major_id'>{t('specialized')}</label>
                  <MajorSelect getMajor={getMajor} response={majorId} />
                  <div className="text-error text-error-custom">{validateMajor}</div>
                </div>
                <div className="form-group-issue">
                  <label htmlFor="" className='city_id'>{t('zone')}</label>
                  <CitySelect getCity={getCity} response={cityId} />
                </div>
              </div>
            </div>

            <div className="form-group col-md-12">
              <div className="form-group group-date-establishment form-group-join">
                <div className="form-group-issue">
                  <label htmlFor="" className='number_of_students_participating'>{t('person_join')}</label>
                  <QuantitySelect getQuantity={getQuantityStudent} response={parseInt(quantityStudent)} type='student' />
                  <div className="text-error text-error-custom">{validateNumberStudent}</div>
                </div>
                <div className="form-group-issue">
                  <label htmlFor="" className='number_of_participants_from_the_organization'>{t('organize_join')}</label>
                  <QuantitySelect getQuantity={getQuantityOrganizer} response={parseInt(quantityOrganizer)} type='organizer' />
                  <div className="text-error text-error-custom">{validateNumberOrganizer}</div>
                </div>
                <div className="form-group-issue">
                  <label htmlFor="" className='category_id'>{t('category')}</label>
                  <CategorySelect getCategory={getCategory} response={categoryId} type='organizer' />
                  <div className="text-error text-error-custom">{validateCategory}</div>
                </div>
              </div>
            </div>

            <div className="form-group mb-4 row col-md-12">
              <label htmlFor="web_url" className='zoom_url'>{t('zoom_url')}</label>
              <Field
                type="text"
                name="web_url"
                value=""
                placeholder={t('placeholder.zoom_url')}
                className={`form-control input-system ${
                  formik.touched.zoom_url && formik.errors.zoom_url ? "is-invalid" : ""
                }`}
                {...formik.getFieldProps("zoom_url")}
              />
              <ErrorMessage
                component="div"
                name="zoom_url"
                className="invalid-feedback pl-unset"
              />
            </div>
            <div className="form-group mb-4 row col-md-12">
              <label htmlFor="theme_name" className='theme_name'>{t('name_issue')}</label>
              <Field
                type="text"
                name="theme_name"
                placeholder={t('placeholder.name_issue')}
                className={`form-control input-system ${
                  formik.touched.theme_name && formik.errors.theme_name ? "is-invalid" : ""
                }`}
                {...formik.getFieldProps("theme_name")}
              />
              <ErrorMessage
                component="div"
                name="theme_name"
                className="invalid-feedback"
              />
            </div>
            <div className="form-group mb-4 row col-md-12">
              <label htmlFor="detail" className='detail'>{t('detail')}</label>
              <textarea
                type="text"
                name="detail"
                placeholder={t('placeholder.detail_issue')}
                className={`form-control text-area-company ${
                  formik.touched.detail && formik.errors.detail ? "is-invalid" : ""
                }`}
                {...formik.getFieldProps("detail")}
              />
              <ErrorMessage
                component="div"
                name="detail"
                className="invalid-feedback pl-unset"
              />
            </div>

            <div className="form-group row col-md-12">
              <label htmlFor="" className='status'>{t('admin.issue.status')}</label>
              <IssueStatusSelect getIssueStatus={getIssueStatus} response={statusIssue} type='' />
              <div className="text-error pl-unset">{validateStatus}</div>
            </div>

            <div className="group-btn-issue">
              <button type='button' className="btn-save-issue mr-3" onClick={() => navigate('/admin/issues')}>{t('user_info.back')}</button>
              <button className="btn-save-issue">{t('update')}</button>
            </div>
          </form>
        </FormikProvider>
      </div>
    </>
  );
}

export default FormInfo;
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {get, post} from "../../services";

const initialState = {
  isSignedIn: !!localStorage.getItem('token'),
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: '',
  currentRequestId: undefined,
  userInfo: null,
  response: null,
  isSuccessUser: false,
  isErrorUser: false,
  errorMessageUser: '',
  listUsers: null,
  isErrorConfirm: false,
  isSuccessConfirm: false,
  isFetchingUpdate: false,
  responseUpdate: null,
  isSuccessRegister: false,
  isErrorRegister: false,
  isFetchingRegister: false,
  errorMessageRegister: '',
};

export const currentUser = createAsyncThunk(
  'auth/get-current-user',
  async (params, {rejectWithValue }) => {
    try {
      const url = `auth/get-current-user`;
      const response = await get(url, params);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

export const updateUserCompany = createAsyncThunk(
  'auth/company/update-profile',
  async (params, { rejectWithValue }) => {
    try {
      const url = `auth/company/update-profile`;
      const response = await post(url, params, true);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      return rejectWithValue(e.response);
    }
  },
);

export const signOut = createAsyncThunk(
  'auth/sign-out',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      localStorage.removeItem('token');
      dispatch(setUserSignIn(false));
    } catch (e) {
      return rejectWithValue(e.response);
    }
  },
);

export const listUser = createAsyncThunk(
  'users/list',
  async (params, {rejectWithValue }) => {
    try {
      const url = `users`;
      const response = await get(url, null, params);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

export const confirmUpdateEmail = createAsyncThunk(
  'auth/confirm-update-email',
  async (params, { rejectWithValue }) => {
    try {
      const url = `auth/confirm-update-email`;
      const response = await post(url, params, false);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      return rejectWithValue(e.response);
    }
  },
);

export const updateEmail = createAsyncThunk(
  'auth/update-email',
  async (params, { rejectWithValue }) => {
    try {
      const url = `auth/update-email`;
      const response = await post(url, params, false);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      return rejectWithValue(e.response);
    }
  },
);

export const validateCompany = createAsyncThunk(
  'auth/companies/validate-create-profile',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'auth/companies/validate-create-profile';
      const response = await post(url, params, true);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

export const createTeacher = createAsyncThunk(
  'auth/teachers',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'auth/teachers';
      const response = await post(url, params, true);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

export const createCompany = createAsyncThunk(
  'auth/companies/create',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'auth/companies';
      const response = await post(url, params, true);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

export const createStudent = createAsyncThunk(
  'auth/students/create',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'auth/students';
      const response = await post(url, params, true);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

export const createCoach = createAsyncThunk(
  'auth/coaches/create',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'auth/coaches';
      const response = await post(url, params, true);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isFetching = false;
      state.isSuccess = false;
      state.isSuccessUser = false;
      state.isErrorUser = false;
      state.errorMessageUser = '';
      state.userInfo = null;
      state.response = null;
      state.listUsers = null;
      state.isErrorConfirm = false;
      state.isSuccessConfirm = false;
      state.isFetchingUpdate = false;
      state.responseUpdate = null;
      state.isErrorRegister = false;
      state.isSuccessRegister = false;
      state.isFetchingRegister = false;
      state.errorMessageRegister = '';
      return state;
    },
    setUser: (state, {payload}) => {
      state.userInfo = payload;
      return state
    },
    setUserSignIn: (state, {payload}) => {
      state.isSignedIn = payload;
      return state
    },
  },
  extraReducers: {
    [currentUser.pending.type]: (state) => {
      state.errorMessageUser = '';
      state.isError = false;
      state.isErrorUser = false;
      state.isFetching = true;
      state.isSuccess = false;
      state.isSuccessUser = false;
    },
    [currentUser.fulfilled.type]: (state, { payload }) => {
      state.isError = false;
      state.isErrorUser = false;
      state.isSuccess = false;
      state.isSuccessUser = true;
      state.isFetching = false;
      state.errorMessage = '';
      state.errorMessageUser = '';
      state.response = payload.data;
      state.currentRequestId = undefined;
    },
    [currentUser.rejected.type]: (state, { payload }) => {
      state.isError = true;
      state.isErrorUser = true;
      state.isSuccess = false;
      state.isSuccessUser = false;
      state.isFetching = false;
      state.errorMessage = payload;
      state.errorMessageUser = payload;
      state.currentRequestId = undefined;
    },

    [updateUserCompany.pending.type]: (state) => {
      state.errorMessage = '';
      state.isError = false;
      state.isFetching = true;
      state.isSuccess = false;
    },
    [updateUserCompany.fulfilled.type]: (state) => {
      state.isError = false;
      state.isSuccess = true;
      state.isFetching = false;
      state.errorMessage = '';
    },
    [updateUserCompany.rejected.type]: (state, { payload }) => {
      state.isError = true;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = payload;
    },

    [validateCompany.pending.type]: (state) => {
      state.errorMessage = '';
      state.isError = false;
      state.isFetching = true;
      state.isSuccess = false;
    },
    [validateCompany.fulfilled.type]: (state) => {
      state.isError = false;
      state.isSuccess = true;
      state.isFetching = false;
      state.errorMessage = '';
      state.currentRequestId = undefined;
    },
    [validateCompany.rejected.type]: (state, { payload }) => {
      state.isError = true;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = payload;
      state.currentRequestId = undefined;
    },

    [listUser.pending.type]: (state) => {
      state.errorMessage = '';
      state.isError = false;
      state.isFetching = true;
      state.isSuccess = false;
      state.listUsers = null;
    },
    [listUser.fulfilled.type]: (state, { payload }) => {
      state.isError = false;
      state.isSuccess = true;
      state.isFetching = false;
      state.errorMessage = '';
      state.listUsers = payload.data;
    },
    [listUser.rejected.type]: (state, { payload }) => {
      state.isError = true;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = payload;
      state.currentRequestId = undefined;
      state.listUsers = null;
    },

    [confirmUpdateEmail.pending.type]: (state) => {
      state.errorMessage = '';
      state.isErrorConfirm = false;
      state.isFetching = true;
      state.isSuccessConfirm = false;
      state.isFetchingUpdate = true;
    },
    [confirmUpdateEmail.fulfilled.type]: (state) => {
      state.isErrorConfirm = false;
      state.isSuccessConfirm = true;
      state.isFetching = false;
      state.errorMessage = '';
      state.isFetchingUpdate = false;
    },
    [confirmUpdateEmail.rejected.type]: (state, { payload }) => {
      state.isErrorConfirm = true;
      state.isSuccessConfirm = false;
      state.isFetching = false;
      state.errorMessage = payload;
      state.isFetchingUpdate = false;
    },

    [updateEmail.pending.type]: (state) => {
      state.errorMessage = '';
      state.isError = false;
      state.isFetching = true;
      state.isSuccess = false;
      state.responseUpdate = null;
    },
    [updateEmail.fulfilled.type]: (state, { payload }) => {
      state.isError = false;
      state.isSuccess = true;
      state.isFetching = false;
      state.errorMessage = '';
      state.responseUpdate = payload;
    },
    [updateEmail.rejected.type]: (state, { payload }) => {
      state.isError = true;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = payload;
      state.responseUpdate = null;
    },

    [createTeacher.pending.type]: (state) => {
      state.errorMessageRegister = '';
      state.isErrorRegister = false;
      state.isFetchingRegister = true;
      state.isSuccessRegister = false;
    },
    [createTeacher.fulfilled.type]: (state) => {
      state.isErrorRegister = false;
      state.isSuccessRegister = true;
      state.isFetchingRegister = false;
      state.errorMessageRegister = '';
    },
    [createTeacher.rejected.type]: (state, { payload }) => {
      state.isErrorRegister = true;
      state.isSuccessRegister = false;
      state.isFetchingRegister = false;
      state.errorMessageRegister = payload;
    },

    [createCompany.pending.type]: (state) => {
      state.errorMessageRegister = '';
      state.isErrorRegister = false;
      state.isFetchingRegister = true;
      state.isSuccessRegister = false;
    },
    [createCompany.fulfilled.type]: (state) => {
      state.isErrorRegister = false;
      state.isSuccessRegister = true;
      state.isFetchingRegister = false;
      state.errorMessageRegister = '';
    },
    [createCompany.rejected.type]: (state, { payload }) => {
      state.isErrorRegister = true;
      state.isSuccessRegister = false;
      state.isFetchingRegister = false;
      state.errorMessageRegister = payload;
    },

    [createStudent.pending.type]: (state) => {
      state.errorMessageRegister = '';
      state.isErrorRegister = false;
      state.isFetchingRegister = true;
      state.isSuccessRegister = false;
    },
    [createStudent.fulfilled.type]: (state) => {
      state.isErrorRegister = false;
      state.isSuccessRegister = true;
      state.isFetchingRegister = false;
      state.errorMessageRegister = '';
    },
    [createStudent.rejected.type]: (state, { payload }) => {
      state.isErrorRegister = true;
      state.isSuccessRegister = false;
      state.isFetchingRegister = false;
      state.errorMessageRegister = payload;
    },

    [createCoach.pending.type]: (state) => {
      state.errorMessageRegister = '';
      state.isErrorRegister = false;
      state.isFetchingRegister = true;
      state.isSuccessRegister = false;
    },
    [createCoach.fulfilled.type]: (state) => {
      state.isErrorRegister = false;
      state.isSuccessRegister = true;
      state.isFetchingRegister = false;
      state.errorMessageRegister = '';
    },
    [createCoach.rejected.type]: (state, { payload }) => {
      state.isErrorRegister = true;
      state.isSuccessRegister = false;
      state.isFetchingRegister = false;
      state.errorMessageRegister = payload;
    },
  },
});

export default usersSlice;

export const { clearState, setUser, setUserSignIn } = usersSlice.actions;

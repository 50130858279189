import React from 'react';
import PropTypes from "prop-types";
import {Button, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";

Confirm.propTypes = {
  title: PropTypes.string,
  todos: PropTypes.bool,
  closeModal: PropTypes.func,
  content: PropTypes.string,
  handleSubmit: PropTypes.func,
}
Confirm.defaultProps = {
  title: '',
  todos: false,
  closeModal: null,
  content: '',
  handleSubmit: null,
}
function Confirm(props) {
  const {t} = useTranslation();
  const {title, todos, closeModal, content, handleSubmit} = props;

  return (
    <Modal show={todos} centered>
      <Modal.Header>
        <Modal.Title>
          <div className="title-form">{title}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="main-text">{content}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => closeModal(!todos)}>
          <div>{t('cancel')}</div>
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          <div>{t('yes')}</div>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Confirm;
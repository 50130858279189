import React from 'react';
import {useDispatch} from "react-redux";
import {signOut} from "../../redux/user/user.slide";
import {useTranslation} from "react-i18next";
import logoutIcon from "../../assets/image/logout_icon.svg";

function Header() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div className='admin-header'><div className="group-action-header">
      <button className="group-logout" onClick={() => dispatch(signOut())}>
        <div className="text-logout mr-2">{t('logout')}</div>
        <img src={logoutIcon} className="" alt="logo" />
      </button>
    </div>
    </div>
  );
}

export default Header;
import React, {useEffect, useState, useRef} from 'react';
import {Formik, Field, ErrorMessage, useFormik, FormikProvider} from "formik";
import * as PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import addIcon from '../../../assets/image/add_avatar.svg';
import {datePickerIssue, issueStatus} from "../../../constant";
import {clearState as clearStateLevel, listLevelStudent} from "../../../redux/company/levels.slide";
import {clearState as clearStateCity, getListCities} from "../../../redux/company/cities.slide";
import {clearState as clearStateCategory, ListCategories} from "../../../redux/category/category.slide";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {clearState as clearStateMajor, listMajor} from "../../../redux/major/major.slide";
import {clearState, addIssue, validateAddIssue} from "../../../redux/company/company.slide";
import Loading from "../../../components/Loading";
import Header from "../../../components/Header";
import backIcon from "../../../assets/image/back_icon.svg";
import {clearState as clearStateUser, currentUser} from "../../../redux/user/user.slide";
import Notification from "../../../components/Modal/Notification";
import {clearState as clearStateParticipant, getSettingParticipant} from "../../../redux/participant/participant.slide";
import _ from "lodash";
import nl2br from 'react-nl2br';
import {listIssue} from "../../../redux/issue/issue.slide";
import imageDefault from '../../../assets/image/issue_default.svg';

Field.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string
};

Formik.propTypes = {
  validationSchema: PropTypes.any,
  onSubmit: PropTypes.func,
  initialValues: PropTypes.shape({password: PropTypes.string, email: PropTypes.string}),
  children: PropTypes.func
};
function AddIssue() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const current = new Date();
  const {listYear, listMonth, listDay28, listDay29, listDay30, listDay31} = datePickerIssue;
  const [isRemote, setIsRemote] = useState(false);
  const [isFaceToFace, setIsFaceToFace] = useState(false);
  const [isFree, setIsFree] = useState(false);
  const [selectType, setSelectType] = useState('');
  const [selectSpe, setSelectSpe] = useState('');
  const [selectLocation, setSelectLocation] = useState('');
  const [selectOrganizerJoin, setSelectOrganizerJoin] = useState('');
  const [listDay, setListDay] = useState(listDay31);
  const [leapYear, setLeapYear] = useState(true);
  const [currentMonth, setCurrentMonth] = useState(null);
  const [currentYear, setCurrentYear] = useState(null);
  const [day, setDay] = useState(current.getDate());
  const [month, setMonth] = useState(current.getMonth() + 1);
  const [year, setYear] = useState(current.getFullYear());
  const inputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState('');
  const [levelId, setLevelId] = useState(null);
  const [majorId, setMajorId] = useState(null);
  const [cityId, setCityId] = useState(null);
  const [numberStudent, setNumberStudent] = useState(null);
  const [numberOrganization, setNumberOrganization] = useState(null);
  const { isSuccessLevel, listLevel} = useSelector((state) => state.level);
  const { isSuccessCity, listCities} = useSelector((state) => state.city);
  const {isSuccessMajor, listMajors} = useSelector((state) => state.major);
  const {isSuccessParticipant, listParticipants} = useSelector((state) => state.participant);
  const {isSuccessCategory, listCategories} = useSelector((state) => state.category);
  const [levelStudent, setLevelStudent] = useState([]);
  const [cityArray, setCityArray] = useState([]);
  const [majorArray, setMajorArray] = useState([]);
  const [participantQuantity, setParticipantQuantity] = useState();
  const {isFetching, isSuccess, isError, errorMessage} = useSelector((state) => state.company);
  const [validateDate, setValidateDate] = useState('');
  const [validateAvatar, setValidateAvatar] = useState('');
  const [validateLevel, setValidateLevel] = useState('');
  const [validateMajor, setValidateMajor] = useState('');
  const [validateCity, setValidateCity] = useState('');
  const [validateNumberStudent, setValidateNumberStudent] = useState('');
  const [validateNumberOrganizer, setValidateNumberOrganizer] = useState('');
  const [validateOnOff, setValidateOnOff] = useState('');
  const [validateCategory, setValidateCategory] = useState('');
  const [isDraft, setIsDraft] = useState(true);
  const [formAdd, setFormAdd] = useState(true);
  const [formConfirm, setFormConfirm] = useState(false);
  const [formDone, setFormDone] = useState(false);
  const {response} = useSelector((state) => state.user);
  const [levelName, setLevelName] = useState('');
  const [cityName, setCityName] = useState('');
  const [personLeft, setPersonLeft] = useState('');
  const [isSave, setIsSave] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [contentModal, setContentModal] = useState('');
  const [url, setUrl] = useState('');
  const [redirect, setRedirect] = useState(false);
  const {listIssues} = useSelector((state) => state.issue);
  const statusArr = [issueStatus.start, issueStatus.end, issueStatus.open, issueStatus.complete];
  const [flag, setFlag] = useState(false);
  const [categoryId, setCategoryId] = useState(null);
  const [categories, setCategories] = useState([]);

  const formik = useFormik({
    initialValues: {
      zoom_url: '',
      theme_name: '',
      detail: '',
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      setFlag(true);
      setValidateAvatar('');
      setValidateMajor('');
      setValidateNumberStudent('');
      setValidateNumberOrganizer('');
      setValidateDate('');
      setValidateAvatar('');
      setValidateOnOff('');
      setValidateLevel('');
      setValidateCity('');
      setValidateCategory('');
      const dateMonth = month < 10 ? '0' + month : month;
      const dateDay = day < 10 ? '0' + day : day;
      let data = Object.assign({}, values, {
        avatar: selectedFile,
        start_date: (!day && !month && !year) ? null : year + '-' + dateMonth + '-' + dateDay,
        user_level_id: levelId,
        major_id: majorId,
        city_id: cityId,
        number_of_students_participating: numberStudent,
        number_of_participants_from_the_organization: numberOrganization,
        is_remote: isRemote ? 1 : 0,
        is_face_to_face: isFaceToFace ? 1 : 0,
        is_free: isFree ? 1 : 0,
        is_draft: isDraft ? 1 : 0,
        category_id: categoryId,
      });
      formik.setErrors({});
      if (isDraft) {
        dispatch(addIssue(data));
      } else {
        isSave ? dispatch(addIssue(data)) : dispatch(validateAddIssue(data));
      }
    }
  });

  useEffect(() => {
    dispatch(currentUser());
    dispatch(listLevelStudent());
    dispatch(getListCities());
    dispatch(listMajor());
    dispatch(getSettingParticipant());
    dispatch(ListCategories());
    return () => {
      dispatch(clearStateUser());
      dispatch(clearStateLevel());
      dispatch(clearStateCity());
      dispatch(clearStateMajor());
      dispatch(clearStateParticipant());
      dispatch(clearStateCategory());
    }
  }, []);

  useEffect(() => {
    if (isSuccessLevel) {
      setLevelStudent(listLevel?.data ?? []);
      dispatch(clearStateLevel());
    }
    if (isSuccessCity) {
      setCityArray(listCities?.data ?? []);
      dispatch(clearStateCity());
    }
    if (isSuccessMajor) {
      setMajorArray(listMajors?.data ?? []);
      dispatch(clearStateMajor());
    }
    if (isSuccessParticipant) {
      setParticipantQuantity(listParticipants?.data ?? null);
      dispatch(clearStateParticipant());
    }
    if (isSuccessCategory) {
      setCategories(listCategories?.data?.data ?? []);
      dispatch(clearStateCategory());
    }
  }, [isSuccessCity, isSuccessLevel, isSuccessMajor, isSuccessParticipant, isSuccessCategory]);

  useEffect(() => {
    if (isError) {
      if (errorMessage.status === 500) {
        if (isDraft) {
          setTitleModal(t('add_issue.modal.title_draft'));
          setContentModal(t('add_issue.modal.content_draft_error'));
        } else {
          setTitleModal(t('add_issue.modal.title_save'));
          setContentModal(t('add_issue.modal.content_save_error'));
        }
        setOpenModal(true);
      }
      if (errorMessage.status === 422) {
        formik.setErrors({
          theme_name: errorMessage?.data?.errors?.theme_name?.[0] || '',
          zoom_url: errorMessage?.data?.errors?.zoom_url?.[0] || '',
          detail: errorMessage?.data?.errors?.detail?.[0] || '',
        });
        setValidateNumberStudent(errorMessage?.data?.errors?.number_of_students_participating?.[0] || '');
        setValidateNumberOrganizer(errorMessage?.data?.errors?.number_of_participants_from_the_organization?.[0] || '');
        setValidateDate(errorMessage?.data?.errors?.start_date?.[0] || '');
        setValidateAvatar(errorMessage?.data?.errors?.avatar?.[0] || '');
        setValidateCity(errorMessage?.data?.errors?.city_id?.[0] || '');
        setValidateMajor(errorMessage?.data?.errors?.major_id?.[0] || '');
        setValidateLevel(errorMessage?.data?.errors?.user_level_id?.[0] || '');
        setValidateOnOff(errorMessage?.data?.errors?.is_face_to_face?.[0] || '');
        setValidateCategory(errorMessage?.data?.errors?.category_id?.[0] || '');
        for (let i in errorMessage?.data?.errors) {
          let el = document.querySelector('.' + i);
          if (el) {
            el?.scrollIntoView();
            break;
          }
        }
      }
    }
    if (isSuccess) {
      dispatch(listIssue({status: statusArr}));
      setRedirect(true);
      if (isDraft) {
        setTitleModal(t('add_issue.modal.title_draft'));
        setContentModal(t('add_issue.modal.content_draft_success'));
        setUrl('/company/my-issue');
        if (flag) setOpenModal(true);
      } else {
        window.scrollTo({top: 0});
        setFormAdd(false);
        setFormConfirm(true);
        setFormDone(false);
        if (isSave) {
          setTitleModal(t('add_issue.modal.title_save'));
          setContentModal(t('add_issue.modal.content_save_success'));
          setOpenModal(true);
          setUrl('/company/my-issue');
          setFormAdd(false);
          setFormDone(false);
        }
      }
    }

    return () => dispatch(clearState());
  }, [isSuccess, isError]);

  function checkLeapYear(year) {
    setLeapYear(isLeapYear(year));
    setCurrentYear(year);
  }

  function isLeapYear(year) {
    return (year % 100 === 0) ? ( year % 400 === 0) : (year % 4 === 0);
  }

  useEffect(() => {
    if ((!leapYear && month === 2 && day > 28) || (month === 2 && day > 29)) {
      setDay(1);
    }
  }, [year, month]);

  useEffect(() => {
    if (currentMonth === 2) {
      leapYear ? setListDay(listDay29) : setListDay(listDay28);
    } else if ([4, 6, 9, 11].includes(currentMonth)) {
      setListDay(listDay30);
    } else setListDay(listDay31);
  }, [currentYear, currentMonth]);

  useEffect(() => {
    setLeapYear(isLeapYear(year));
    if (month === 2) {
      if (leapYear) {
        setListDay(listDay29);
      }
      else setListDay(listDay28);
    } else if (month === 4 || month === 6 || month ===9 || month === 11) {
      setListDay(listDay30);
    } else setListDay(listDay31);
  }, [year, month, leapYear]);

  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      return
    }
    setValidateAvatar('');
    const MAX_FILE_SIZE = 1024;
    const IMAGE_UPLOADED_FILE =  e.target.files[0].size / 1024;
    if (IMAGE_UPLOADED_FILE > MAX_FILE_SIZE) {
      setValidateAvatar(t('upload_large_size_avatar'));
      return;
    }
    setSelectedFile(e.target.files[0]);
  }

  useEffect(() => {
    if (!selectedFile) {
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile]);

  const handleClick = () => {
    inputRef.current.click();
  };

  function closeModal(value) {
    setOpenModal(value);
    redirect && navigate(url);
  }

  function handleKeyPress(e) {
    if (e.charCode === 13) {
      e.preventDefault()
    }
  }

  return (
    <>
      <Notification todos={openModal} title={titleModal} content={contentModal} closeModal={closeModal} />
      {formAdd &&
        <div className="container container-add-issue">
          <div className="header-add-issue">
            <div className="title-add-issue">{t('issue_registration')}</div>
            <div className="des-add-issue">{t('condition_register')}</div>
          </div>
          <div>
            <input type='file' hidden ref={inputRef} onChange={onSelectFile} accept=".png, .jpg, .jpeg" />
          </div>
          {selectedFile ?
            <div className="d-flex flex-column w-100">
              <div className="image-add-issue-block avatar" onClick={handleClick}>
                <img className="preview-image" src={preview}/>
              </div>
              <div className="mb-4 text-error w-100">{validateAvatar}</div>
            </div> :
            <div className="d-flex flex-column w-100">
              <div className="image-add-issue-block avatar" onClick={handleClick}>
                <img src={addIcon} alt=""/>
                <div className="des-image-add">{t('can_set_image')}</div>
              </div>
              <div className="mb-4 text-error w-100">{validateAvatar}</div>
            </div>
          }

          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit} className="w-100">
              <div className="type-issue-block">
                <div className="remote-face is_face_to_face">
                  <div className="d-flex justify-content-start align-items-center flex-wrap mr-5">
                    <div className="custom-checkbox custom-checkbox-issue">
                      <Field id="checkbox1" type="checkbox" className="checkbox-system is_remote" value="1" checked={isRemote} onChange={() => setIsRemote(!isRemote)} />
                      <label htmlFor="checkbox1"className=""></label>
                    </div>
                    <label htmlFor="checkbox1" className="main-text">{t('remote')}</label>
                  </div>
                  <div className="d-flex justify-content-start align-items-center">
                    <div className="custom-checkbox custom-checkbox-issue">
                      <Field id="checkbox2" type="checkbox" className="checkbox-system is_face_to_face" value="1" checked={isFaceToFace} onChange={() => setIsFaceToFace(!isFaceToFace)} />
                      <label htmlFor="checkbox2"className=""></label>
                    </div>
                    <label htmlFor="checkbox2" className="main-text">{t('face_to_face')}</label>
                  </div>
                </div>
                <div className="free-paid-block is_free">
                  <button type="button" className={`btn-free-paid ${isFree ? '' : 'active'}`} onClick={() => {setIsFree(false)}} >{t('paid')}</button>
                  <button type="button" className={`btn-free-paid ${isFree ? 'active' : ''}`} onClick={() => {setIsFree(true)}} >{t('free')}</button>
                </div>
              </div>
              <div className="text-error pl-unset mb-5">{validateOnOff}</div>
              <div className="form-group row col-md-12 start_date">
                <label htmlFor="">{t('date')}</label>
                <div className="form-group row group-date-establishment">
                  <select value={year || ''} className={`select-company ${year ? 'color-placeholder' : ''}`} onKeyPress={(e) => handleKeyPress(e)} onChange={(e) => { checkLeapYear(parseInt(e.target.value)); setYear(parseInt(e.target.value)) }}>
                    <option disabled hidden value="">{t('placeholder.date_establishment.year')}</option>
                    {listYear.map((val, index) => (
                      <option key={index} value={val.value}>{val.name}</option>
                    ))}
                  </select>
                  <select value={month || ''} name="" id="" className={`select-company ${month ? 'color-placeholder' : ''}`} onKeyPress={(e) => handleKeyPress(e)} onChange={(e) => {setCurrentMonth(e.target.value); setMonth(parseInt(e.target.value))}}>
                    <option disabled hidden value="">{t('placeholder.date_establishment.month')}</option>
                    {listMonth.map((val, index) => (
                      <option key={index} value={val.value}>{val.name}</option>
                    ))}
                  </select>
                  <select value={day || ''} name="" id="" className={`select-company ${day ? 'color-placeholder' : ''}`}  onKeyPress={(e) => handleKeyPress(e)} onChange={(e) => {setDay(parseInt(e.target.value))}}>
                    <option disabled hidden value="">{t('placeholder.date_establishment.day')}</option>
                    {listDay.map((val, index) => (
                      <option key={index} value={val.value}>{val.name}</option>
                    ))}
                  </select>
                </div>
                <div className="text-error pl-unset">{validateDate}</div>
              </div>

              <div className="form-group col-md-12">
                <div className="form-group group-date-establishment">
                  <div className="form-group-issue">
                    <label htmlFor="" className='user_level_id'>{t('register_object')}</label>
                    <select value={levelId || ''} className={`select-company ` + selectType} onKeyPress={(e) => handleKeyPress(e)} onChange={(e) => {setSelectType('select-focus'); setLevelId(e.target.value); setLevelName(e.target.selectedOptions[0].text)}}>
                      <option disabled hidden value="">{t('placeholder.register_object')}</option>
                      {levelStudent.map((val) => (
                        <option key={val.id} value={val.id}>{val.name}</option>
                      ))}
                    </select>
                    <div className="text-error text-error-custom">{validateLevel}</div>
                  </div>
                  <div className="form-group-issue major_id">
                    <label htmlFor="" className='major_id'>{t('specialized')}</label>
                    <select value={majorId || ''} className={`select-company ` + selectSpe} onKeyPress={(e) => handleKeyPress(e)} onChange={(e) => {setSelectSpe('select-focus'); setMajorId(e.target.value);}}>
                      <option disabled hidden value="">{t('placeholder.specialized')}</option>
                      {majorArray.map((val) => (
                        <option key={val.id} value={val.id}>{val.name}</option>
                      ))}
                    </select>
                    <div className="text-error text-error-custom">{validateMajor}</div>
                  </div>
                  <div className="form-group-issue city_id">
                    <label htmlFor="" className='city_id'>{t('zone')}</label>
                    <select value={cityId || ''} style={{marginRight: 'unset'}} className={`select-company ` + selectLocation} onKeyPress={(e) => handleKeyPress(e)} onChange={(e) => {setSelectLocation('select-focus'); setCityId(e.target.value); setCityName(e.target.selectedOptions[0].text)}}>
                      <option disabled hidden value="">{t('placeholder.zone')}</option>
                      {cityArray.map((val) => (
                        <option key={val.id} value={val.id}>{val.name}</option>
                      ))}
                    </select>
                    <div className="text-error text-error-custom">{validateCity}</div>
                  </div>
                </div>
              </div>

              <div className="form-group col-md-12">
                <div className="form-group group-date-establishment form-group-join">
                  <div className="form-group-issue">
                    <label htmlFor="" className='number_of_students_participating'>{t('person_join')}</label>
                    <select value={numberStudent || ''} className={`select-company ${numberStudent ? 'color-placeholder' : ''}`} onKeyPress={(e) => handleKeyPress(e)} onChange={(e) => {setNumberStudent(e.target.value); setPersonLeft(e.target.selectedOptions[0].text)}}>
                      <option disabled hidden value="">{t('placeholder.person_join')}</option>
                      {_.times(participantQuantity?.number_of_students_participating, (i) => (
                        <option key={i} value={i + 1}>{i +1}人</option>
                      ))}
                    </select>
                    <div className="text-error text-error-custom">{validateNumberStudent}</div>
                  </div>
                  <div className="form-group-issue">
                    <label htmlFor="" className='number_of_participants_from_the_organization'>{t('organize_join')}</label>
                    <select value={numberOrganization || ''} className={`select-company ` + selectOrganizerJoin} onKeyPress={(e) => handleKeyPress(e)} onChange={(e) => {setSelectOrganizerJoin('select-focus'); setNumberOrganization(e.target.value)}}>
                      <option disabled hidden value="">{t('placeholder.organize_join')}</option>
                      {_.times(participantQuantity?.number_of_participants_from_the_organization, (i) => (
                        <option key={i} value={i + 1}>{i +1}人</option>
                      ))}
                    </select>
                    <div className="text-error text-error-custom">{validateNumberOrganizer}</div>
                  </div>
                  <div className="form-group-issue">
                    <label htmlFor="" className='category_id'>{t('category')}</label>
                    {/*<CategorySelect getCategory={getCategory} response={categoryId ?? null} type='create' />*/}
                    <select
                      onKeyPress={(e) => handleKeyPress(e)}
                      value={categoryId ?? ''}
                      style={{marginRight: 'unset'}}
                      className={`select-company col-md-3 ${categoryId ? 'color-placeholder' : ''}`}
                      onChange={(e) => {setCategoryId(parseInt(e.target.value))}}>
                      <option value="" disabled hidden>{t('please_choose')}</option>
                      {categories.map((val) => (
                        <option key={val?.id} value={val.id}>{val.name}</option>
                      ))}
                    </select>
                    <div className="text-error text-error-custom">{validateCategory}</div>
                  </div>
                </div>
              </div>

              <div className="form-group mb-4 row col-md-12 zoom_url">
                <label htmlFor="web_url">{t('zoom_url')}</label>
                <Field
                  onKeyPress={(e) => handleKeyPress(e)}
                  type="text"
                  name="web_url"
                  value=""
                  placeholder={t('placeholder.zoom_url')}
                  className={`form-control input-system ${
                    formik.touched.zoom_url && formik.errors.zoom_url ? "is-invalid" : ""
                  }`}
                  {...formik.getFieldProps("zoom_url")}
                />
                <ErrorMessage
                  component="div"
                  name="zoom_url"
                  className="invalid-feedback pl-unset"
                />
              </div>
              <div className="form-group mb-4 row col-md-12 theme_name">
                <label htmlFor="theme_name">{t('name_issue')}</label>
                <Field
                  onKeyPress={(e) => handleKeyPress(e)}
                  type="text"
                  name="theme_name"
                  placeholder={t('placeholder.name_issue')}
                  className={`form-control input-system ${
                    formik.touched.theme_name && formik.errors.theme_name ? "is-invalid" : ""
                  }`}
                  {...formik.getFieldProps("theme_name")}
                />
                <ErrorMessage
                  component="div"
                  name="theme_name"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group mb-4 row col-md-12 detail">
                <label htmlFor="detail">{t('detail')}</label>
                <textarea
                  type="text"
                  name="detail"
                  placeholder={t('placeholder.detail_issue')}
                  className={`form-control text-area-company ${
                    formik.touched.detail && formik.errors.detail ? "is-invalid" : ""
                  }`}
                  {...formik.getFieldProps("detail")}
                />
                <ErrorMessage
                  component="div"
                  name="detail"
                  className="invalid-feedback pl-unset"
                />
              </div>

              <div className="group-btn-issue">
                <button className="btn-save-issue mr-3" onClick={() => setIsDraft(true)}>{t('save_draft')}</button>
                <button className="btn-save-issue" onClick={() => setIsDraft(false)}>{t('save_issue')}</button>
              </div>
            </form>
          </FormikProvider>
        </div>
      }
      {formConfirm &&
        <div className="container container-issue">
          <Header type={null}/>
          <div className="body-issue">
            <div className="back-group">
              <Link to="/company/students">
                <img src={backIcon} alt="icon" className="cursor-pointer" />
              </Link>
              <div className="text-back">{t('my_page')}</div>
              <div className="text-back">{t('back')}</div>
            </div>
            <div className="form-content-block">
              <div className="header-content-issue">
                <div className="item-issue">
                  <img src={listIssues?.data?.data?.[0]?.avatar ?? imageDefault} alt="" className={listIssues?.data?.data?.[0]?.avatar ? '' : 'custom-default-size'} />
                </div>
                <div className="item-issue">
                  <img src={listIssues?.data?.data?.[1]?.avatar ?? imageDefault} alt="" className={listIssues?.data?.data?.[1]?.avatar ? '' : 'custom-default-size'}/>
                </div>
                <div className="item-issue">
                  <img src={listIssues?.data?.data?.[2]?.avatar ?? imageDefault} alt="" className={listIssues?.data?.data?.[2]?.avatar ? '' : 'custom-default-size'}/>
                </div>
              </div>

              <div className="content-issue">
                <div className="content-issue-left">
                  <div className="title-issue text-break">{formik.getFieldProps('theme_name').value}</div>
                  <div className="company-name text-break">{response?.data?.name}</div>
                  <div className="text-info-group">
                    {levelName && <div className="text-info-issue mr-3">{levelName}</div>}
                    {cityName && <div className="text-info-issue">{cityName}</div>}
                  </div>
                  <div className="title-detail-issue">{t('detail')}</div>
                  <div className="text-detail-issue text-break">{nl2br(formik.getFieldProps('detail').value)}</div>
                  <FormikProvider value={formik}>
                    <form onSubmit={formik.handleSubmit}>
                      <button className="btn-save-issue btn-issue-custom" onClick={() => {setIsSave(true)}} >{t('save_issue')}</button>
                    </form>
                  </FormikProvider>
                  <button className='btn-save-issue btn-issue-custom btn-issue-back' onClick={() => {setFormAdd(true); setFormConfirm(false)}}>{t('back_btn')}</button>
                </div>
                <div className="content-issue-right">
                  <div className="header-issue-right">
                    <div className="quantity-left">あと{personLeft}</div>
                    <div className="date-register">{t('date_register')}{new Date().getFullYear()}年{new Date().getMonth() + 1}月{new Date().getDate()}日</div>
                  </div>
                  <div className="info-company-block">
                    <div className="text-info-company">
                      <p>{t('label_company_info.title')}</p>
                      <p>{t('label_company_info.name')}{response?.data.name}</p>
                      <p>{t('label_company_info.birthday')}{response?.data?.profile?.birthday}</p>
                      <p>{t('label_company_info.email')}{response?.data.email}</p>
                      <p>{t('label_company_info.phone_number')}{response?.data?.profile?.phone_number}</p>
                      <p>{t('label_company_info.address')}{response?.data?.profile?.city}{response?.data?.profile?.city && response?.data?.profile?.address ? ', ' : ''}{response?.data?.profile?.address}</p>
                      <p>{t('label_company_info.web_url')}{response?.data?.profile?.web_url}</p>
                      <p>{t('label_company_info.bio')}{response?.data?.profile?.bio}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      {formDone &&
        <div className="container container-issue">
          <Header type={null}/>
          <div className="body-issue">
            <div className="back-group">
              <Link to="/company/students">
                <img src={backIcon} alt="icon" className="cursor-pointer" />
              </Link>
              <div className="text-back">{t('my_page')}</div>
              <div className="text-back">{t('back')}</div>
            </div>
            <div className="form-content-block">
              <div className="header-content-issue">
                <div className="item-issue">
                  <img src={listIssues?.data?.data?.[0]?.avatar ?? imageDefault} alt="" className={listIssues?.data?.data?.[0]?.avatar ? '' : 'custom-default-size'} />
                </div>
                <div className="item-issue">
                  <img src={listIssues?.data?.data?.[1]?.avatar ?? imageDefault} alt="" className={listIssues?.data?.data?.[1]?.avatar ? '' : 'custom-default-size'}/>
                </div>
                <div className="item-issue">
                  <img src={listIssues?.data?.data?.[2]?.avatar ?? imageDefault} alt="" className={listIssues?.data?.data?.[2]?.avatar ? '' : 'custom-default-size'}/>
                </div>
              </div>

              <div className="content-issue">
                <div className="content-issue-left">
                  <div className="title-issue text-break">{formik.getFieldProps('theme_name').value}</div>
                  <div className="company-name text-break">{response?.data?.name}</div>
                  <div className="text-info-group">
                    {levelName && <div className="text-info-issue mr-3">{levelName}</div>}
                    {cityName && <div className="text-info-issue">{cityName}</div>}
                  </div>
                  <div className="title-detail-issue">{t('detail')}</div>
                  <div className="text-detail-issue text-break">{nl2br(formik.getFieldProps('detail').value)}</div>
                  <Link to="/company/my-issue">
                    <button className="btn-save-issue btn-issue-custom">{t('edit_issue')}</button>
                  </Link>
                </div>
                <div className="content-issue-right">
                  <div className="header-issue-right">
                    <div className="quantity-left">あと{personLeft}</div>
                    <div className="date-register">{t('date_register')}{new Date().getFullYear()}年{new Date().getMonth() + 1}月{new Date().getDate()}日</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      {isFetching && <Loading />}
    </>
  );
}

export default AddIssue;
import React, {useEffect, useState} from 'react';
import FormUpdate from './FormInfo';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {clearState, currentUser} from "../../redux/user/user.slide";
import {typeUser} from "../../constant";
import {useNavigate} from "react-router-dom";

function Update() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {isErrorUser, isSuccessUser, response} = useSelector(state => state.user);
  const [user, setUser] = useState(null);

  useEffect(() => {
    dispatch(currentUser());
    return () => dispatch(clearState());
  }, []);

  useEffect(() => {
    if (isErrorUser) {
      return alert(t('error_system'));
    }
    if (isSuccessUser) {
      setUser(response?.data);
      if (response?.data.role !== typeUser.teacher) {
        navigate('/');
      }
    }

    return () => dispatch(clearState());
  }, [isSuccessUser, isErrorUser]);

  return (
    <>
      <FormUpdate
        textBack={t('update_coach.back_my_page')}
        textSubmit={t('update')}
        typeSubmit='update'
        userInfo={user}
        type={typeUser.teacher}
        formData={null}
        showHideForm={null}
        isShow={true}
      />
    </>
  );
}

export default Update;
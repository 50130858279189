import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {clearState, listGender} from "../../redux/gender/gender.slide";
import PropTypes from "prop-types";

Index.propTypes = {
  getGender: PropTypes.func,
  response: PropTypes.number,
}
Index.defaultProps = {
  getGender: () => {},
  response: null,
}
function Index(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {getGender, response} = props;
  const {listGenders, isSuccessGender} = useSelector(state => state.gender);
  const [genders, setGenders] = useState([]);
  const [genderId, setGenderId] = useState(null);

  useEffect(() => {
    dispatch(listGender());

    return () => dispatch(clearState());
  }, []);

  useEffect(() => {
    setGenders(listGenders?.data ?? []);
  }, [isSuccessGender]);

  useEffect(() => {
    getGender(genderId)
  }, [genderId]);

  useEffect(() => {
    setGenderId(response);
  }, [response]);

  return (
    <select value={genderId ?? ''} name="gender_id" id="" className={`select-company col-md-3 mb-4 ${genderId ? 'color-placeholder' : ''}`} onChange={(e) => {setGenderId(parseInt(e.target.value))}} >
      <option value="" disabled hidden>{t('update_coach.placeholder.gender')}</option>
      {genders.map((val) => (
        <option key={val.id} value={val.id}>{val.name}</option>
      ))}
    </select>
  );
}

export default Index;
import React, {useEffect, useState} from 'react';
import Header from "../../components/Header";
import Content from "./Content";
import {useTranslation} from "react-i18next";
import Loading from "../../components/Loading";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {clearState, currentUser} from "../../redux/user/user.slide";
import {typeUser} from "../../constant";

function Student() {
  const {t} = useTranslation();
  const {isFetching} = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {response} = useSelector((state) => state.user);
  const [isCompany, setIsCompany] = useState(false);

  useEffect(() => {
    dispatch(currentUser());
    return () => {
      dispatch(clearState());
    };
  }, []);

  useEffect(() => {
    if (response) {
      if (response.data.role === typeUser.company) {
        setIsCompany(true);
      } else {
        return navigate('/');
      }
    }
  }, [response]);

  return (
    <>
      {isCompany ?
        <>
          <div className="container container-app">
            <Header type={null}/>
            <Content
              title={t('student.list')}
              activeStudent="active"
              titleResult={t('student.students')}
              listResult={t('student.new')}
              isStudent={true}
              activeCoach=''
            />
          </div>
          {isFetching && <Loading />}
        </> : <div></div>
      }
    </>
  );
}

export default Student;
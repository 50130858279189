import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from "prop-types";
import {useTranslation} from 'react-i18next';
import {useNavigate} from "react-router-dom";

UrlExpired.propTypes = {
  title: PropTypes.string,
}

UrlExpired.defaultProps = {
  title: '',
}
function UrlExpired(props) {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {title} = props;
  function handleClick() {
    navigate('/');
  }
  return (
    <div>
      <Modal show={true} centered>
        <Modal.Header>
          <Modal.Title>
            <div className="title-form">{title}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="main-text">{t('register_company.expired')}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClick}>
            <div>{t('register_company.modal_button')}</div>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default UrlExpired;
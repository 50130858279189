import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import Header from "../../components/Header";
import './index.css';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {clearState, currentUser} from "../../redux/user/user.slide";
import {TYPE_CHAT_SINGLE, typeUser} from "../../constant";
import PropTypes from "prop-types";
import HeaderUser from "./HeaderUser";
import HeaderIssue from "./HeaderIssue";
import ModalUsers from "./ModalUsers";
import Message from "./Message";
import OverviewMessage from "./OverviewMessage";
import {
  chatMessage,
  clearState as clearStateMsg,
  detailConversation,
  listConversation,
  listMessage, markRead
} from "../../redux/mesage/message.slide";
import NotFound from "../../components/404";
import Loading from "../../components/Loading";
import Echo from "laravel-echo";
import io from "socket.io-client";
import Notification from "../../components/Modal/Notification";

Index.propTypes = {
  isOverview: PropTypes.bool,
}
Index.defaultProps = {
  isOverview: false,
}
function Index(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [classOpen, setClassOpen] = useState('');
  const {isFetching, response, isError} = useSelector((state) => state.user);
  const {
    listConversations,
    listMessages,
    successSendMsg,
    errorSendMsg,
    successListMsg,
    msgChat,
    detailConver,
    errorDetail,
    successDetail,
    msgErrorDetail,
  } = useSelector((state) => state.message);
  const [url, setUrl] = useState('');
  const {isOverview} = props;
  const [openModal, setOpenModal] = useState(false);
  const [messages, setMessages] = useState([]);
  const [overviewMessages, setOverviewMessages] = useState([]);
  const [userId, setUserId] = useState(null);
  const [contentMsg, setContentMsg] = useState('');
  const [conversationId, setConversationId] = useState(null);
  const [userReceiver, setUserReceiver] = useState(null);
  const [enableSend, setEnableSend] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [oldestMsgId, setOldestMsgId] = useState(null);
  const [currentPageUser, setCurrentPageUser] = useState(1);
  const [lastPageUser, setLastPageUser] = useState(1);
  const [isLoadMoreMsg, setIsLoadMoreMsg] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [autoScroll, setAutoScroll] = useState(true);
  const [listConvers, setListConvers] = useState([]);
  const [type, setType] = useState('');
  const [conversationInfo, setConversationInfo] = useState(null);
  const [students, setStudents] = useState([]);
  const [openModalNotification, setOpenModalNotification] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    dispatch(currentUser());
    if (isOverview) setClassOpen('open');
    setIsShow(!isOverview);

    return () => dispatch(clearState());
  }, [isOverview]);

  useEffect(() => {
    if (!isOverview) {
      dispatch(detailConversation({id: window.location.href.split('/').pop()}));
    }
    dispatch(listConversation());

    return () => dispatch(clearStateMsg());
  }, []);

  useEffect(() => {
    if (isError) {
      return alert(t('error_system'));
    }

    if (response) {
      setUserId(response?.data?.id);
      const role = response?.data?.role;
      switch (role) {
        case typeUser.company:
          setUrl('/company/students');
          break;
        case typeUser.student:
          setUrl('/student');
          break;
        case typeUser.coach:
          setUrl('/coach');
          break;
        default:
          setUrl('/');
      }
    }

    if (successDetail) {
      const user = detailConver?.data?.users.find(user => user?.id !== userId);
      setIsShow(true);
      if (user?.is_deleted && detailConver?.data?.type === TYPE_CHAT_SINGLE) {
        setOpenModalNotification(true);
        setIsDelete(true);
        setIsLoading(false);
      } else setIsDelete(false);
      if (detailConver?.data.length === 0) {
        return setIsNotFound(true);
      }
      setStudents(detailConver?.data?.issue?.students ?? []);
      setConversationInfo(detailConver?.data);
      dispatch(listMessage({id: detailConver.data?.id}));
      dispatch(markRead({id: detailConver.data?.id}));
      setConversationId(detailConver.data?.id);
      const receiverUser = detailConver?.data.users.find((user) => user.id !== userId);
      setUserReceiver(receiverUser);
      if (!detailConver.data.latest_message) {
        const newDt = overviewMessages.find((mes) => {
          return detailConver.data.id === mes.id;
        });
        if (!newDt) {
          setOverviewMessages(overviewMessages => [detailConver.data, ...overviewMessages]);
        }
      }
    }

    if (errorDetail) {
      if (msgErrorDetail?.status === 404) setIsNotFound(true);
      else alert(t('error_system'));
      return;
    }
    if (listConversations?.data?.data) {
      setListConvers(listConversations?.data?.data ?? []);
      setCurrentPageUser(listConversations.data.meta.current_page);
      setLastPageUser(listConversations.data.meta.last_page);
      const newDt = overviewMessages.filter((mes) => {
        return listConversations.data.data.find(conver => mes.id === conver.id) === undefined;
      });
      setOverviewMessages([...newDt, ...listConversations.data.data]);
    }
    if (successSendMsg) {
      setContentMsg('');
      setEnableSend(false);
    }
    if (msgChat.status === 500) {
      return alert(t('error_system'));
    }
    if (errorSendMsg) {
      setEnableSend(false);
    }

    return () => dispatch(clearStateMsg());
  }, [response, isError, listConversations, listMessages, successSendMsg, errorSendMsg, errorDetail, successDetail, detailConver, msgErrorDetail]);

  useEffect(() => {
    if (listMessages) {
      const messageArray = listMessages.data.data;
      setCurrentPage(listMessages.data.meta.current_page);
      setLastPage(listMessages.data.meta.last_page);
      setOldestMsgId(messageArray?.[0]?.id);
      if (isLoadMoreMsg) {
        setMessages(messages => [...messageArray, ...messages]);
      } else {
        setMessages(messageArray);
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    }
  }, [successListMsg]);

  useEffect(() => {
    setType(conversationInfo?.type);
  }, [conversationInfo]);

  useEffect(() => {
    document.addEventListener("mousedown", setExpandMenu);
    return () => document.removeEventListener("mousedown", setExpandMenu);
  });

  const setExpandMenu = (event) => {
    const concernedElement = document.querySelector(".expand-menu-chat");
    const sidebarElement = document.getElementById('sidebar-chat');
    if (concernedElement?.contains(event.target)) {
      setClassOpen('open');
      return;
    }
    if (!sidebarElement?.contains(event.target) && !isOverview) {
      setClassOpen('');
    }
  }

  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (autoScroll && !isOverview) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  }, [messages]);

  function closeModal(value) {
    setOpenModal(value);
  }

  useEffect(() => {
    if (userId) {
      const echo = new Echo({
        broadcaster: "socket.io",
        host: process.env.REACT_APP_HOST_SOCKET,
        client: io,
        auth: {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      });
      echo.private('notifications.' + userId)
        .listen('MessageSent', (data) => {
          dispatch(listConversation());
          if (data?.conversation?.id === parseInt(window.location.href.split('/').pop())) {
            const messageContainer = document.getElementById("messageList");
            if (messageContainer.scrollTop + messageContainer.clientHeight >= messageContainer.scrollHeight - 160) {
              setAutoScroll(true);
            } else {
              setAutoScroll(false);
            }
            setMessages(messages => [...messages, data?.message]);
          }
        });
    }
  }, [userId]);

  const submitMsg = () => {
    document.querySelector('textarea.input-msg-chat')?.focus();
    setEnableSend(true);
    setAutoScroll(true);
    if (contentMsg && !isDelete) {
      dispatch(chatMessage({
        conversation_id: conversationId,
        content: contentMsg,
      }));
    }
  };

  const enableButtonSend = (e) => {
    const value = e.target.value.trim();
    setEnableSend(!value);
    if (isDelete) {
      setEnableSend(true);
    }
  }

  const handleScrollMsg = () => {
    const messageContainer = document.getElementById("messageList");
    if (messageContainer.scrollTop === 1 && currentPage < lastPage) {
      setIsLoadMoreMsg(true);
      setAutoScroll(false);
      dispatch(listMessage({
        id: conversationId,
        less_than_id: oldestMsgId
      }));
      messagesEndRef.current.scrollTop = 50;
    }
  };

  useEffect(() => {
    const messageContainer = document.getElementById("messageList");
    messageContainer?.addEventListener("scroll", handleScrollMsg);

    return () => messageContainer?.removeEventListener("scroll", handleScrollMsg);
  }, [currentPage, lastPage, listConvers]);

  useEffect(() => {
    const sidebarContainer = document.getElementById("sidebar-chat");
    const handleScroll = () => {
      if (sidebarContainer.scrollHeight - sidebarContainer.scrollTop === sidebarContainer.clientHeight && currentPageUser < lastPageUser) {
        dispatch(listConversation({page: currentPageUser + 1}));
      }
    };

    sidebarContainer?.addEventListener("scroll", handleScroll);
    return () => sidebarContainer?.removeEventListener("scroll", handleScroll);
  }, [currentPageUser, lastPageUser]);

  const handleChildVariable = (variable) => {
    setAutoScroll(true);
    setIsLoading(true);
    setIsLoadMoreMsg(false);
    setMessages([]);
    setConversationId(variable.converId);
    dispatch(detailConversation({id: variable.converId}));
    dispatch(listMessage({id: variable.converId}));
    setClassOpen('');
    setContentMsg('');
  }

  const handleFocusInput = () => {
    dispatch(markRead({id: conversationId}));
    dispatch(listConversation());
  }

  const closeModalNotification = (value) => {
    setOpenModalNotification(value);
  }

  return (
    <>
      {isNotFound ? <NotFound /> :
        <>
          <Notification todos={openModalNotification} title={t('notification')} content={t('message.user_deleted')} closeModal={closeModalNotification} />
          <div className='container container-app container-msg'>
            <Header type=''/>
            <div className='message-block'>
              <div className='group-title-page-msg'>
                <div className='title-page-message'>{t('message.title_page')}</div>
                <div className='title-page-message-sub'>{t('message.title_page_sub')}</div>
              </div>
                <div className={listConvers.length || !isOverview ? 'chat-message-wrapper' : 'd-none'}>
                  <div className={'sidebar-chat ' + classOpen} id='sidebar-chat'>
                    {overviewMessages.map((overviewMessage, index) =>
                      <OverviewMessage
                        key={index}
                        response={overviewMessage}
                        conversationId={conversationId}
                        userId={userId}
                        switchMsg={handleChildVariable}
                        type={isOverview}
                      />
                    )}
                  </div>
                  {!isOverview &&
                    <div className={type === TYPE_CHAT_SINGLE ? 'body-chat' : 'body-chat body-chat-issue'}>
                      {type === TYPE_CHAT_SINGLE ?
                        <HeaderUser user={userReceiver} /> : <HeaderIssue setOpenModal={setOpenModal} response={conversationInfo} userId={userId} role={response?.data?.role} />
                      }
                      <div className='content-chat-block'>
                        <div id="messageList" className='content-body-chat' ref={messagesEndRef}>
                          {messages.map(message =>
                            <Message
                              key={message.id}
                              response={message}
                              userId={userId}
                            />
                          )}
                        </div>
                      </div>
                      <div className='footer-body-chat'>
                    <textarea className='input-msg-chat'
                              onFocus={handleFocusInput}
                              maxLength={5000}
                              placeholder={t('message.send_a_message')}
                              onChange={(e) => {setContentMsg(e.target.value); enableButtonSend(e)}}
                              value={contentMsg}>
                    </textarea>
                        <div className='send-msg-block'>
                          <div className='text-error'></div>
                          <button className='btn-send-message' onClick={submitMsg} disabled={enableSend} >{t('message.btn_send')}</button>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              {listConvers.length || isShow ? null : <div className='text-error text-center'>{t('message.no_data')}</div>}
              <Link to={url}>
                <button className={type === 'user' ? 'btn-back-my-page' : 'btn-back-my-page btn-back-issue'}>{t('message.back_my_page')}</button>
              </Link>
            </div>
          </div>
          <ModalUsers todos={openModal} closeModal={closeModal} users={students} />
        </>
      }
      {isFetching || isLoading ? <Loading /> : null}
    </>
  );
}

export default Index;
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {get, post} from "../../services";

const initialState = {
  isFetching: false,
  isSuccessGender: false,
  isErrorGender: false,
  errorMessage: '',
  listGenders: null,
};

export const listGender = createAsyncThunk(
  'genders',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'genders';
      const response = await get(url, params);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

export const updateGender = createAsyncThunk(
  'genders/update',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'genders/' + params?.id;
      const response = await post(url, params);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

const gendersSlide = createSlice({
  name: 'genders',
  initialState,
  reducers: {
    clearState: (state) => {
      state.isErrorGender = false;
      state.isFetching = false;
      state.isSuccessGender = false;
      state.errorMessage = '';
      state.listGenders = null;
      return state;
    },
  },
  extraReducers: {
    [listGender.pending.type]: (state) => {
      state.errorMessage = '';
      state.isErrorGender = false;
      state.isFetching = true;
      state.isSuccessGender = false;
      state.listGenders = null;
    },
    [listGender.fulfilled.type]: (state, { payload }) => {
      state.isErrorGender = false;
      state.isSuccessGender = true;
      state.isFetching = false;
      state.errorMessage = '';
      state.currentRequestId = undefined;
      state.listGenders = payload.data;
    },
    [listGender.rejected.type]: (state, { payload }) => {
      state.isErrorGender = true;
      state.isSuccessGender = false;
      state.isFetching = false;
      state.errorMessage = payload;
      state.currentRequestId = undefined;
      state.listGenders = null;
    },

    [updateGender.pending.type]: (state) => {
      state.errorMessageUpdate = '';
      state.isErrorUpdate = false;
      state.isFetching = true;
      state.isSuccessUpdate = false;
    },
    [updateGender.fulfilled.type]: (state) => {
      state.isErrorUpdate = false;
      state.isSuccessUpdate = true;
      state.isFetching = false;
      state.errorMessageUpdate = '';
    },
    [updateGender.rejected.type]: (state, { payload }) => {
      if (state.isFetching) {
        state.isErrorUpdate = true;
        state.isSuccessUpdate = false;
        state.isFetching = false;
        state.errorMessageUpdate = payload;
      }
    },
  },
});

export default gendersSlide;

export const { clearState } = gendersSlide.actions;

import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {clearState, listIssue} from "../../../redux/issue/issue.slide";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import IssueStatusSelect from "../../../components/IssueStatus";
import {issueStatus, MAX_QUANTITY_STUDENT_APPLY, typeUser} from "../../../constant";
import {clearState as clearStateCategory, ListCategories} from "../../../redux/category/category.slide";

Search.propTypes = {
  response: PropTypes.func,
  params: PropTypes.func,
  type: PropTypes.string,
  setLoading: PropTypes.func,
}
Search.defaultProps = {
  response: null,
  params: null,
  type: '',
  setLoading: null,
}
function Search(props) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {response, params, type, setLoading} = props;
  const {isSuccessIssue, isErrorIssue, listIssues} = useSelector(state => state.issue);
  const [name, setName] = useState('');
  const [status, setStatus] = useState([]);
  const [quantity, setQuantity] = useState(null);
  const [categoryId, setCategoryId] = useState('');
  const statusArr = [issueStatus.start, issueStatus.end, issueStatus.open, issueStatus.temp, issueStatus.complete];
  const [isSearch, setIsSearch] = useState(false);
  const {isSuccessCategory, isErrorCategory, listCategories} = useSelector(state => state.category);
  const [categories, setCategories] = useState([]);

  const search = (e) => {
    e.preventDefault();
    setLoading(true);
    setIsSearch(true);
    const paramSearch = {
      theme_name: name,
      number_of_students_participating: quantity,
      category_id: categoryId,
    };
    if (status.length) paramSearch.status = status;
    if (type === typeUser.company) {
      paramSearch.get_by_company_owner = 1;
    }
    if (type === typeUser.student) {
      paramSearch.get_by_student_joined = 1;
    }
    if (type === typeUser.coach) {
      paramSearch.get_by_coach_joined = 1;
    }
    params(paramSearch);
    dispatch(listIssue(paramSearch));
  }

  useEffect(() => {
    if (isSearch) {
      if (isErrorIssue) {
        alert(t('error_system'));
      }
      if (isSuccessIssue) {
        setLoading(false);
        response(listIssues);
      }
    }

    return () => dispatch(clearState());
  }, [isSuccessIssue, isErrorIssue, isSearch]);

  useEffect(() => {
    dispatch(ListCategories());
    return () => dispatch(clearStateCategory());
  }, []);

  useEffect(() => {
    if (isErrorCategory) {
      alert(t('error_system'));
    }
    if (isSuccessCategory) {
      setCategories(listCategories?.data?.data ?? []);
    }

    return () => dispatch(clearStateCategory());
  }, [isErrorCategory, isSuccessCategory]);

  const getIssueStatus = (value) => {
    if (value) setStatus([value])
    else setStatus(statusArr);
  }

  return (
    <form onSubmit={search} className='form-search-condition'>
      <div className='group-select'>
        <div className='main-text mb-2'>{t('company_issue.category')}</div>
        <select value={categoryId || ''} className={`select-company ${categoryId ? 'color-placeholder' : ''}`} onChange={(e) => setCategoryId(e.target.value)} >
          <option value="" disabled hidden>{t('please_choose')}</option>
          <option value=''>{t('please_choose')}</option>
          {categories.map(category => (
            <option key={category?.id} value={category?.id}>{category?.name}</option>
          ))}
        </select>
      </div>
      <div className='group-select'>
        <div className='main-text mb-2'>{t('company_issue.status')}</div>
        <IssueStatusSelect getIssueStatus={getIssueStatus} response='' type='search' />
      </div>
      <div className='group-select'>
        <div className='main-text mb-2'>{t('company_issue.participant')}</div>
        <select value={quantity || ''} className={`select-company ${quantity ? 'color-placeholder' : ''}`} onChange={(e) => setQuantity(e.target.value)}>
          <option value="" disabled hidden>{t('please_choose')}</option>
          <option value=''>{t('please_choose')}</option>
          {_.times(MAX_QUANTITY_STUDENT_APPLY, (i) => (
            <option key={i} value={i + 1}>{i +1}人</option>
          ))}
        </select>
      </div>
      {(type === typeUser.student || type === typeUser.coach) &&
        <div className='group-select'>
          <div className='main-text mb-2'>{t('student_issue.keyword')}</div>
          <input type="text" className='input-system' placeholder={t('student_issue.please_type')} style={{width: '250px'}}
            onChange={(e) => setName(e.target.value.trim())}
          />
        </div>}
      <button className='btn-search mt-3'>{t('company.search')}</button>
    </form>
  );
}

export default Search;
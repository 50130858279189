import React, {useEffect, useState} from 'react';
import Header from "../../components/Header";
import {Link, useNavigate} from "react-router-dom";
import backIcon from "../../assets/image/back_icon.svg";
import soundIcon from "../../assets/image/sound.svg";
import imageDefault from "../../assets/image/issue_default.svg";
import nl2br from "react-nl2br";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {clearState, completeIssue, detailIssue, studentJoin} from "../../redux/issue/issue.slide";
import {clearState as clearStateUser, currentUser} from "../../redux/user/user.slide";
import {issueStatus, TYPE_CHAT_GROUP, typeUser} from "../../constant";
import NotFound from "../../components/404";
import Loading from "../../components/Loading";
import Notification from "../../components/Modal/Notification";
import Confirm from '../../components/Modal/Confirm';
import HeaderNotLogin from '../../components/NavBar';
import LoginModal from '../../components/Modal/Login';
import {clearState as clearStateMsg, createConversation} from "../../redux/mesage/message.slide";
import ListStudent from './ListStudent';
import EvaluateConfirm from "../../components/Modal/EvaluateConfirm";
import Evaluate from '../../components/Modal/Evaluate';
import {getTypeIssue} from "../../helper";

function Detail() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    issueInfo,
    isSuccessIssue,
    isErrorIssue,
    errorMessage,
    isErrorJoin,
    isSuccessJoin,
    isFetching,
    isErrorComplete,
    isSuccessComplete,
  } = useSelector(state => state.issue);
  const {statusConversation, conversation, errorConversation} = useSelector(state => state.message);
  const {response, isSuccessUser, isSignedIn} = useSelector(state => state.user);
  const [issue, setIssue] = useState(null);
  const [isNotFound, setIsNotFound] = useState(false);
  const [backUrl, setBackUrl] = useState('/');
  const [openModal, setOpenModal] = useState(false);
  const [contentModal, setContentModal] = useState('');
  const [openModalApply, setOpenModalApply] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [openModalLogin, setOpenModalLogin] = useState(false);
  const [isApply, setIsApply] = useState(false);
  const [isMax, setIsMax] = useState(false);
  const [textBtn, setTextBtn] = useState(t('apply'));
  const [textBack, setTextBack] = useState(t('top_page'));
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [role, setRole] = useState('');
  const [status, setStatus] = useState('');
  const [isOwner, setIsOwner] = useState(false);
  const [issueId, setIssueId] = useState(null);
  const [openModalStudents, setOpenModalStudents] = useState(false);
  const [students, setStudents] = useState([]);
  const [titleConfirm, setTitleConfirm] = useState('');
  const [contentConfirm, setContentConfirm] = useState('');
  const [openModalEvaluate, setOpenModalEvaluate] = useState(false);
  const [openModalEvaluateConfirm, setOpenModalEvaluateConfirm] = useState(false);
  const [userId, setUserId] = useState(null);
  const [evaluateArr, setEvaluateArr] = useState(null);
  const [evaluate, setEvaluate] = useState(null);

  useEffect(() => {
    dispatch(detailIssue({id: window.location.href.split('/').pop()}));
    if (isSignedIn) dispatch(currentUser());
    window.scrollTo(0, 0);

    return () => {
      dispatch(clearState());
      dispatch(clearStateUser());
    }
  }, []);

  useEffect(() => {
    const param = new URLSearchParams(window.location.search).get('apply');
    if (isSuccessUser && isSignedIn && param && issue) {
      const role = response?.data?.role;
      if (role === typeUser.student) {
        setOpenModalApply(true);
        setTitleConfirm(t('student_apply_issue.modal_title'));
        setContentConfirm(t('student_apply_issue.confirm_apply'));
      } else {
        setContentModal(t('student_apply_issue.only_student'));
        setOpenModal(true);
      }
    }
    if (isSuccessUser && issue) {
      setIsOwner(response?.data?.id === issue?.user?.id);
    }
  }, [isSignedIn, isSuccessUser, issue]);

  useEffect(() => {
    if (isErrorIssue || isErrorJoin) {
      setOpenModalApply(false);
      if (errorMessage.status === 500 || errorMessage.status === 422) {
        setContentModal(t('student_apply_issue.apply_error'));
        return;
      }
      if (errorMessage.status === 404) {
        setIsNotFound(true);
        return;
      }
      if (errorMessage.status === 400) {
        setContentModal(errorMessage?.data?.message);
      }
      setOpenModal(true);
    }
    if (isSuccessIssue) {
      setIssue(issueInfo?.data);
      setIsOpen(issueInfo?.data?.status === issueStatus.open);
      setStatus(issueInfo?.data?.status);
      setIssueId(issueInfo?.data?.id);
      setStudents(issueInfo?.data?.students ?? []);
      setEvaluateArr(issueInfo?.data?.is_evaluated ?? []);
    }
    if (isSuccessJoin) {
      setOpenModalApply(false);
      setIsComplete(true);
      setContentModal(t('student_apply_issue.apply_success'));
      setOpenModal(true);
    }

    return () => dispatch(clearState());
  }, [isErrorIssue, isSuccessIssue, isSuccessJoin, isErrorJoin]);

  useEffect(() => {
    if (isSuccessUser) {
      setTextBack(t('my_page'));
      const role = response?.data?.role;
      setRole(role);
      switch (role) {
        case typeUser.student:
          setBackUrl('/student');
          break;
        case typeUser.coach:
          setBackUrl('/coach');
          break;
        case typeUser.company:
          setBackUrl('/company/students');
          break;
        case typeUser.teacher:
          setBackUrl('/teacher');
          break;
        default:
          setBackUrl('/');
      }
    }
  }, [isSuccessUser]);

  const closeModal = (value) => {
    setOpenModal(value);
    if (isComplete) {
      setIsLoading(true);
      dispatch(createConversation({
        issue_id: issueId,
        type: TYPE_CHAT_GROUP,
        user_id: response?.data?.id,
      }));
    }
  }

  useEffect(() => {
    if (errorConversation) {
      alert(t('error_system'));
      setIsLoading(false);
      return;
    }
    if (statusConversation) {
      navigate('/message/' + conversation?.data?.id);
      setIsLoading(false);
    }

    return () => dispatch(clearStateMsg());
  }, [statusConversation, errorConversation]);

  const closeModalApply = (value) => {
    setOpenModalApply(value);
  }

  const closeModalLogin = (value) => {
    setOpenModalLogin(value);
  }

  const apply = () => {
    dispatch(studentJoin({id: issueId}));
  }

  const handleApply = () => {
    if (isSignedIn) {
      setTitleConfirm(t('student_apply_issue.modal_title'));
      setContentConfirm(t('student_apply_issue.confirm_apply'));
      setOpenModalApply(true);
    }
    else setOpenModalLogin(true);
  }

  useEffect(() => {
    checkMaxApply();
    if (issue && isSuccessUser) {
      checkApply(issue?.students ?? []);
    }
  }, [issue, isSuccessUser]);

  const checkApply = (users) => {
    for (let i = 0; i < users.length; i++) {
      if (users[i]?.id === response?.data?.id) {
        setIsApply(true);
        setTextBtn(t('student_apply_issue.apply_ed'));
        break;
      }
    }
  }

  const checkMaxApply = () => {
    if (issue?.number_of_students_participating <= issue?.students?.length) {
      setIsMax(true);
      setTextBtn(t('student_apply_issue.max'));
    }
  }

  const getActionTemplate = () => {
    if (status === issueStatus.start) {
      return <button className='btn-system' onClick={confirmComplete}>{t('complete_issue')}</button>;
    }
    if (status === issueStatus.complete) {
      return <button className='btn-system' onClick={() => setOpenModalStudents(true)}>{t('comment_student')}</button>;
    }
    return null;
  }

  const confirmComplete = () => {
    setTitleConfirm(t('title_complete_issue'));
    setContentConfirm(t('content_complete_issue'))
    setOpenModalApply(true);
  }

  useEffect(() => {
    if (isErrorComplete) {
      alert(t('error_system'));
    }
    if (isSuccessComplete) {
      setOpenModalApply(false);
      setOpenModalEvaluateConfirm(true);
    }
  }, [isSuccessComplete, isErrorComplete]);

  const handleComplete = () => {
    dispatch(completeIssue({id: issueId}));
  }

  const showEvaluate = () => {
    setOpenModalStudents(false);
    setOpenModalEvaluate(true);
  }

  const closeModalEvaluate = (value) => {
    setOpenModalEvaluate(value);
  }

  const getUserId = (value) => {
    setUserId(value);
  }

  const setLoading = (value) => {
    setIsLoading(value);
  }

  const getEvaluate = (value) => {
    setEvaluate(value);
  }

  return (
    <>
      <Notification todos={openModal} title={t('student_apply_issue.modal_title')} content={contentModal} closeModal={closeModal} />
      <Confirm
        todos={openModalApply}
        title={titleConfirm}
        content={contentConfirm}
        closeModal={closeModalApply}
        handleSubmit={isOwner ? handleComplete : apply}
      />
      <LoginModal todos={openModalLogin} closeModal={closeModalLogin} issueId={issueId} />
      {isNotFound ? <NotFound /> :
        <div className="container container-issue">
          {isSignedIn ? <Header type={''}/> : <HeaderNotLogin />}
          <div className="body-issue">
            <div className="back-group">
              <Link to={backUrl} >
                <img src={backIcon} alt="icon" className="cursor-pointer" />
              </Link>
              <div className="text-back">{textBack}</div>
              <div className="text-back">{t('back')}</div>
            </div>
            <div className="form-content-block">
              <div className="header-content-issue">
                <div className="item-issue">
                  <img src={issue?.avatar ?? imageDefault} alt="image" className={issue?.avatar ? '' : 'custom-default-size'} />
                </div>
              </div>

              <div className='group-tag-issue'>
                <button className='btn-tag-issue'>
                  <img src={soundIcon} alt="icon" className='mr-2'/>
                  {getTypeIssue(issue?.is_remote, issue?.is_face_to_face)}
                </button>
                <button className='btn-tag-issue'>{issue?.is_free ? t('free_issue') : t('fee_issue')}</button>
                <button className='btn-tag-issue'>{issue?.user_level_id?.name}</button>
              </div>

              <div className="content-issue">
                <div className="content-issue-left">
                  <div className="title-issue text-break">{issue?.theme_name}</div>
                  <div className="company-name text-break">{issue?.user.name}</div>
                  <div className="text-info-group">
                    <div className="text-info-issue mr-3">{issue?.user_level_id?.name}</div>
                    <div className="text-info-issue">{issue?.city_id?.name}</div>
                  </div>
                  <div className="title-detail-issue">{t('detail')}</div>
                  <div className="text-detail-issue text-break">{nl2br(issue?.detail)}</div>
                  {role === typeUser.student || !isSignedIn ?
                    <button className="btn-system"
                            onClick={handleApply}
                            disabled={isApply || isMax || !isOpen}
                    >{textBtn}</button> : null
                  }
                  {role === typeUser.company && isOwner ? getActionTemplate() : null}
                </div>
                <div className="content-issue-right">
                  <div className="header-issue-right">
                    <div className="quantity-left">
                      <span>{t('status_apply')}</span>
                      <span>{issue?.students?.length}人/{issue?.number_of_students_participating}人</span>
                    </div>
                    <div className='group-date-issue'>
                      <div className="date-register">{t('date_register')}{issue?.created_at}</div>
                      <div className="expired-date">{t('expired_date')}{issue?.issue_start_date}</div>
                    </div>
                  </div>
                  <div className="info-company-block">
                    <div className="text-info-company">
                      <p>{t('label_company_info.title')}</p>
                      <p>{t('label_company_info.name')}{issue?.user?.name}</p>
                      <p>{t('label_company_info.birthday')}{issue?.user?.profile?.birthday}</p>
                      <p>{t('label_company_info.email')}{issue?.user?.email}</p>
                      <p>{t('label_company_info.phone_number')}{issue?.user?.profile?.phone_number}</p>
                      <p>{t('label_company_info.address')}{issue?.user?.profile?.city}{issue?.user?.profile?.city && issue?.user?.profile?.address ? ', ' : ''}{issue?.user?.profile?.address}</p>
                      <p>{t('label_company_info.web_url')}{issue?.user?.profile?.web_url}</p>
                      <p>{t('label_company_info.bio')}{nl2br(issue?.user?.profile?.bio)}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      {isFetching || isLoading ? <Loading /> : null}
      <ListStudent
        users={students}
        todos={openModalStudents}
        closeModal={() => setOpenModalStudents(false)}
        showEvaluate={showEvaluate}
        setUserId={getUserId}
        evaluateArr={evaluateArr}
        evaluate={getEvaluate}
      />
      <EvaluateConfirm
        todos={openModalEvaluateConfirm}
        title={t('title_complete_issue')}
        closeModal={() => {setOpenModalEvaluateConfirm(false); location.reload();}}
        content={t('content_evaluate')}
        handleSubmit={() => {setOpenModalEvaluateConfirm(false); setOpenModalStudents(true);}}
      />
      <Evaluate
        todos={openModalEvaluate}
        closeModal={closeModalEvaluate}
        userId={userId}
        setLoading={setLoading}
        response={evaluate}
      />
    </>
  );
}

export default Detail;
import React, {useEffect, useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

EditData.propTypes = {
  todos: PropTypes.bool,
  closeModal: PropTypes.func,
  title: PropTypes.string,
  value: PropTypes.string,
  handleUpdate: PropTypes.func,
  message: PropTypes.string,
}
EditData.defaultProps = {
  todos: false,
  closeModal: null,
  title: '',
  value: '',
  handleUpdate: null,
  message: '',
}
function EditData(props) {
  const {t} = useTranslation();
  const {todos, closeModal, title, value, handleUpdate, message} = props;
  const [name, setName] = useState('');

  useEffect(() => {
    const input = document.getElementById('edit-data');
    input?.focus();
    setName(value);
  }, [todos]);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleUpdate(name);
  }

  return (
    <Modal show={todos} centered>
      <form onSubmit={handleSubmit}>
      <Modal.Header>
        <Modal.Title>
          <div className="title-form">{title}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex justify-items-start align-items-center'>
          <label htmlFor="edit-data" className='name-create-data' >{t('admin.name_title')}</label>
          <input id='edit-data' defaultValue={value || ''} type="text" className='input-system w-100' placeholder={t('admin.data.placeholder_name')} onChange={(e) => setName(e.target.value.trim())} />
        </div>
        <span className='text-error'>{message}</span>
      </Modal.Body>
      <Modal.Footer>
        <Button type='button' variant="secondary" onClick={() => closeModal(!todos)}>{t('close')}</Button>
        <Button type='submit' variant="primary">{t('update')}</Button>
      </Modal.Footer>
      </form>
    </Modal>
  );
}

export default EditData;
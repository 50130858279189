import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {checkExpiredUrl, clearState, createPasswordCompany} from "../../redux/auth/auth.slice";
import {useDispatch, useSelector} from "react-redux";
import Loading from "../../components/Loading";
import Expired from '../../components/Modal/Expired';
import { useNavigate } from 'react-router-dom';
import UrlExpired from "../../components/Modal/UrlExpired";
import ResetPasswordSuccess from "../../components/Modal/ResetPasswordSuccess";
import ResetPasswordFail from "../../components/Modal/ResetPasswordFail";
import {typeUser} from "../../constant";

function ResetPassword() {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [textPassValidate, setTextPassValidate] = useState('');
  const [textPassConfirmValidate, setTextPassConfirmValidate] = useState('');
  const [classErrorPass, setClassErrorPass] = useState('input-system w-100');
  const [classErrorPassConfirm, setClassErrorPassConfirm] = useState('input-system w-100');
  const {isFetching, isSuccess, isError, errorMessage, isErrorExpired, typeUserRes} = useSelector((state) => state.auth);
  const [modalExpired, setModalExpired] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalFail, setModalFail] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [url, setUrl] = useState('');

  useEffect(() => {
    dispatch(checkExpiredUrl({
      token: window.location.href.split('=').pop(),
      type: 'reset_password',
    }));
    return () => {
      dispatch(clearState());
    };
  }, []);

  useEffect(() => {
    if (isSuccess) {
      dispatch(clearState());
      if (typeUserRes.data.data === typeUser.company) {
        setUrl('/company/login');
      }
      if (typeUserRes.data.data === typeUser.student) {
        setUrl('/student/login');
      }
      if (typeUserRes.data.data === typeUser.coach) {
        setUrl('/coach/login');
      }
      if (typeUserRes.data.data === typeUser.teacher) {
        setUrl('/teacher/login');
      }
      if (typeUserRes.data.data === typeUser.admin) {
        setUrl('/admin/login');
      }
      setModalSuccess(true);
    }
    if (isErrorExpired) {
      dispatch(clearState());
      setIsExpired(true);
    }
    if (isError) {
      dispatch(clearState());
      if (errorMessage.status === 422) {
        if (errorMessage?.data?.errors?.password_confirm) {
          setTextPassConfirmValidate(errorMessage?.data?.errors?.password_confirm ? errorMessage?.data?.errors?.password_confirm[0] : '');
          setClassErrorPassConfirm('input-system w-100 border-error');
        }
        if (errorMessage?.data?.errors?.password) {
          setTextPassValidate(errorMessage?.data?.errors?.password ? errorMessage?.data?.errors?.password[0] : '');
          setClassErrorPass('input-system w-100 border-error');
        }
        return;
      }
      if (errorMessage?.data?.status === 410) {
        setModalExpired(true);
        return;
      }
      if (errorMessage.status === 500) {
        setModalFail(true);
      }
    }
  }, [isSuccess, isError, isErrorExpired, typeUserRes]);

  function handleSubmit(e) {
    e.preventDefault();
    setClassErrorPass('input-system w-100');
    setClassErrorPassConfirm('input-system w-100');
    setTextPassValidate('');
    setTextPassConfirmValidate('');
    dispatch(createPasswordCompany({
      password,
      password_confirm: passwordConfirm,
      confirm_token: window.location.href.split('=').pop(),
      type: 'reset_password',
    }));
  }

  function closeModalExpired(value) {
    setModalExpired(value);
  }

  function closeModalSuccess(value) {
    setModalSuccess(value);
    navigate(url);
  }

  function closeModalFail(value) {
    setModalFail(value);
  }

  return (
    <>
      {isExpired ? <UrlExpired title={t('forget_password')} /> :
        <form className="form-register form-register-password form-reset-password" onSubmit={handleSubmit}>
          <div>{<Expired todos={modalExpired} closeModal={closeModalExpired}/>}</div>
          <div>{<ResetPasswordSuccess todos={modalSuccess} closeModal={closeModalSuccess}/>}</div>
          <div>{<ResetPasswordFail todos={modalFail} closeModal={closeModalFail}/>}</div>
          <div className="title-form">{t('reset_password_title')}</div>
          <div className="group-register group-register-password group-reset-password">
            <label className="main-text mb-4">新しいパスワードを入力してください。</label>
            <div className="d-flex flex-column align-items-start mb-4 w-100">
              <label className="main-text mb-2">{t('password')}</label>
              <input type="password" name="password" placeholder={t('password_placeholder')} className={classErrorPass} value={password} onChange={(e) => setPassword(e.target.value.trim())} />
              <span className="text-error">{textPassValidate}</span>
            </div>
            <div className="d-flex flex-column align-items-start w-100">
              <label className="main-text mb-2">{t('password_confirm')}</label>
              <input type="password" name="password_confirm" placeholder={t('password_confirm_placeholder')} className={classErrorPassConfirm} value={passwordConfirm} onChange={(e) => setPasswordConfirm(e.target.value.trim())} />
            </div>
            <span className="text-error">{textPassConfirmValidate}</span>
          </div>
          <button className="button-primary">{t('register')}</button>
        </form>
      }
      {isFetching && <Loading />}
    </>
  );
}

export default ResetPassword;

import React from 'react';
import favoriteIcon from "../../assets/image/favorite.svg";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import avatarDefault from '../../assets/image/avatar_default.png';
import nl2br from "react-nl2br";

ResultCoach.propTypes = {
  response: PropTypes.array,
}
ResultCoach.defaultProps = {
  response: [],
}
function ResultCoach(props) {
  const { t } = useTranslation();
  const {response} = props;

  return (
    <div className="list-result-search">
      {response.length === 0 ? <div className="no-data">{t('no_data')}</div> : ''}
      {response.map((val) => (
        <Link className="result-item" key={val?.id} to={'/company/coaches/' + val?.id}>
          <div className='group-avatar-bg' style={{ backgroundImage: `url(${val?.profile?.image ?? avatarDefault})` }}>
            <div className="d-flex justify-content-end pr-5 mb-3 w-100">
              <img src={favoriteIcon} alt="icon" className="img-favorite" />
            </div>
          </div>
          <div className="group-des-item">
            <div className="title-item text-three-dots" title={val?.name}>{val?.name}</div>
            <div className="detail-item text-three-dots" title={val?.profile?.gender?.name}>{val?.profile?.gender?.name}</div>
            <div className="detail-item skills-overview" title={val?.profile?.skills}>{nl2br(val?.profile?.skills)}</div>
          </div>
        </Link>
      ))}
    </div>
  );
}

export default ResultCoach;
import React from 'react';
import { useTranslation } from 'react-i18next';
import imgService1 from '../../../assets/image/service_img01.png';
import imgService2 from '../../../assets/image/service_img02.png';
import imgService3 from '../../../assets/image/service_img03.png';
import imgService4 from '../../../assets/image/service_img04.png';

export default function Feature() {
  const { t } = useTranslation();

  return (
    <div className="service-block">
      <div className="title-part-top-page mb-3">
        <div className="text-service mr-3">{t('service')}</div>
        <div className="text-service-feature">{t('service_feature')}</div>
      </div>
      <div className="text-description-service">{t('description_service_1')}<br/>{t('description_service_2')}</div>
      <div className="overview-service-block">
        <div className="overview-service">
          <img src={imgService1} className="" alt="" />
          <div className="des-service">
            1回90分：<br/>
            オンライン90分間のインターンシップです
          </div>
        </div>
        <div className="overview-service">
          <img src={imgService2} className="" alt="" />
          <div className="des-service">
            リアルな課題：<br/>
            企業の本当の困りごとに取り組みます
          </div>
        </div>
        <div className="overview-service">
          <img src={imgService3} className="" alt="" />
          <div className="des-service">
            いろいろな学生：<br/>
            他校の学生を含め、多様な学生と一緒におこないます
          </div>
        </div>
        <div className="overview-service">
          <img src={imgService4} className="" alt="" />
          <div className="des-service">
            学生ALコーチ：<br/>
            学び会う場をつくる、リーダーシップ
          </div>
        </div>
      </div>
    </div>
  );
}
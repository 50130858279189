import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {get, post} from "../../services";

const initialState = {
  isFetching: false,
  errorMessage: '',
  listEvaluates: null,
  isSuccessList: false,
  isErrorList: false,
  isErrorDetail: false,
  isSuccessDetail: false,
  evaluateDetail: null,
  isErrorEvaluate: false,
  isSuccessEvaluate: false,
};

export const listEvaluate = createAsyncThunk(
  'student/list-evaluate',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'evaluations';
      const response = await get(url, {}, params);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

export const detailEvaluate = createAsyncThunk(
  'student/detail-evaluate',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'evaluations/' + params?.id;
      const response = await get(url, {}, params);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

export const createEvaluate = createAsyncThunk(
  'evaluate/create',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'evaluations';
      const response = await post(url, params, true);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

export const updateEvaluate = createAsyncThunk(
  'evaluate/update',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'evaluations/' + params?.id;
      const response = await post(url, params, true);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

const evaluatesSlide = createSlice({
  name: 'evaluates',
  initialState,
  reducers: {
    clearState: (state) => {
      state.isFetching = false;
      state.errorMessage = '';
      state.listEvaluates = null;
      state.isSuccessList = false;
      state.isErrorList = false;
      state.evaluateDetail = null;
      state.isErrorDetail = false;
      state.isSuccessDetail = false;
      state.isErrorEvaluate = false;
      state.isSuccessEvaluate = false;
      return state;
    },
  },
  extraReducers: {
    [listEvaluate.pending.type]: (state) => {
      state.errorMessage = '';
      state.isErrorList = false;
      state.isFetching = true;
      state.isSuccessList = false;
      state.listEvaluates = null;
    },
    [listEvaluate.fulfilled.type]: (state, { payload }) => {
      state.isErrorList = false;
      state.isSuccessList = true;
      state.isFetching = false;
      state.errorMessage = '';
      state.listEvaluates = payload;
    },
    [listEvaluate.rejected.type]: (state, { payload }) => {
      state.isErrorList = true;
      state.isSuccessList = false;
      state.isFetching = false;
      state.errorMessage = payload;
      state.listEvaluates = null;
    },

    [detailEvaluate.pending.type]: (state) => {
      state.errorMessage = '';
      state.isErrorDetail = false;
      state.isFetching = true;
      state.isSuccessDetail = false;
      state.evaluateDetail = null;
    },
    [detailEvaluate.fulfilled.type]: (state, { payload }) => {
      state.isErrorDetail = false;
      state.isSuccessDetail = true;
      state.isFetching = false;
      state.errorMessage = '';
      state.evaluateDetail = payload;
    },
    [detailEvaluate.rejected.type]: (state, { payload }) => {
      state.isErrorDetail = true;
      state.isSuccessDetail = false;
      state.isFetching = false;
      state.errorMessage = payload;
      state.evaluateDetail = null;
    },

    [createEvaluate.pending.type]: (state) => {
      state.errorMessage = '';
      state.isSuccessEvaluate = false;
      state.isFetching = true;
      state.isErrorEvaluate = false;
    },
    [createEvaluate.fulfilled.type]: (state) => {
      state.errorMessage = '';
      state.isSuccessEvaluate = true;
      state.isFetching = false;
      state.isErrorEvaluate = false;
    },
    [createEvaluate.rejected.type]: (state, { payload }) => {
      state.errorMessage = payload;
      state.isSuccessEvaluate = false;
      state.isFetching = false;
      state.isErrorEvaluate = true;
    },

    [updateEvaluate.pending.type]: (state) => {
      state.errorMessage = '';
      state.isSuccessEvaluate = false;
      state.isFetching = true;
      state.isErrorEvaluate = false;
    },
    [updateEvaluate.fulfilled.type]: (state) => {
      state.errorMessage = '';
      state.isSuccessEvaluate = true;
      state.isFetching = false;
      state.isErrorEvaluate = false;
    },
    [updateEvaluate.rejected.type]: (state, { payload }) => {
      state.errorMessage = payload;
      state.isSuccessEvaluate = false;
      state.isFetching = false;
      state.isErrorEvaluate = true;
    },
  },
});

export default evaluatesSlide;

export const { clearState } = evaluatesSlide.actions;

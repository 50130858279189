import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from "prop-types";
import {useTranslation} from 'react-i18next';

RegisterSuccess.propTypes = {
  todos: PropTypes.bool,
  closeModal: PropTypes.func,
  title: PropTypes.string,
};

RegisterSuccess.defaultProps = {
  todos: false,
  closeModal: null,
  title: '',
};

function RegisterSuccess(props) {
  const {t} = useTranslation();
  const { todos, closeModal, title } = props;
  return (
    <div>
      <Modal show={todos} centered>
        <Modal.Header>
          <Modal.Title>
            <div className="title-form">{title}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="main-text">{t('register_company.modal_content')}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => closeModal(!todos)}>
            <div>{t('register_company.modal_button')}</div>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default RegisterSuccess;
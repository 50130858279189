import React, {useEffect, useState} from 'react';
import Header from "../../../components/Header";
import Loading from "../../../components/Loading";
import {useTranslation} from "react-i18next";
import TopContent from '../../../components/TopContent';
import Content from '../../Company/Issue/List';
import PropTypes from "prop-types";
import {typeUser} from "../../../constant";
import {useDispatch, useSelector} from "react-redux";
import {clearState, currentUser} from "../../../redux/user/user.slide";
import {useNavigate} from "react-router-dom";

MyIssue.propTypes = {
  type: PropTypes.string,
}
MyIssue.defaultProps = {
  type: '',
}
function MyIssue(props) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {type} = props;
  const [title, setTitle] = useState('');
  const [textList, setTextList] = useState('');
  const [textUpdate, setTextUpdate] = useState('');
  const {isSuccessUser, isErrorUser, response} = useSelector(state => state.user);
  const {isFetching} = useSelector(state => state.issue);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(currentUser());
    return () => dispatch(clearState());
  }, [type]);

  useEffect(() => {
    if (isErrorUser) {
      alert(t('error_system'));
    }
    if (isSuccessUser) {
      if (response?.data?.role !== type) {
        navigate('/');
      }
    }
  }, [isErrorUser, isSuccessUser]);

  useEffect(() => {
    if (type === typeUser.company) {
      setTitle(t('company_issue.title'));
      setTextList(t('company_issue.list_issue'));
      setTextUpdate(t('company_issue.update_profile'));
    }
    if (type === typeUser.student) {
      setTitle(t('student_issue.title'));
      setTextList(t('student_issue.list_issue'));
      setTextUpdate(t('student_issue.update_profile'));
    }
    if (type === typeUser.coach) {
      setTitle(t('coach_issue.title'));
      setTextList(t('coach_issue.list_issue'));
      setTextUpdate(t('coach_issue.update_profile'));
    }
  }, [type]);

  const handleSearch = (value) => {
    setIsLoading(value);
  }

  return (
    <>
      <div className="container container-app">
        <Header type=''/>
        <TopContent title={title} textList={textList} textUpdate={textUpdate} type={type} />
        <Content title={t('company_issue.list')} type={type} setLoading={handleSearch} />
      </div>
      {isFetching || isLoading ? <Loading /> : null}
    </>
  );
}

export default MyIssue;
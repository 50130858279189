import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {post, get} from "../../services";

const initialState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: '',
  responseCoach: null,
  currentRequestId: undefined,
  response: null,
};

export const updateCoach = createAsyncThunk(
  'auth/coach/update-profile',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'auth/coach/update-profile';
      const response = await post(url, params, true);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

export const detailCoach = createAsyncThunk(
  'users_coach',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'users/' + params.id;
      const response = await get(url, {});
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

export const validateRegister = createAsyncThunk(
  'auth/coaches/validate-create-profile',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'auth/coaches/validate-create-profile';
      const response = await post(url, params, true);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

const coachesSlide = createSlice({
  name: 'coaches',
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isFetching = false;
      state.isSuccess = false;
      state.errorMessage = '';
      state.responseCoach = null;
      state.response = null;
      return state;
    },
  },
  extraReducers: {
    [updateCoach.pending.type]: (state, { meta }) => {
      const { requestId } = meta;
      if (!state.isFetching) {
        state.errorMessage = '';
        state.isError = false;
        state.isFetching = true;
        state.isSuccess = false;
        state.responseCoach = null;
        state.currentRequestId = requestId;
      }
    },
    [updateCoach.fulfilled.type]: (state, { payload, meta }) => {
      const { requestId } = meta;
      if (state.isFetching && state.currentRequestId === requestId) {
        state.isError = false;
        state.isSuccess = true;
        state.isFetching = false;
        state.errorMessage = '';
        state.responseCoach = payload.data;
        state.currentRequestId = undefined;
      }
    },
    [updateCoach.rejected.type]: (state, { payload, meta }) => {
      const { requestId } = meta;
      if (state.isFetching && state.currentRequestId === requestId) {
        state.isError = true;
        state.isSuccess = false;
        state.isFetching = false;
        state.errorMessage = payload;
        state.responseCoach = null;
        state.currentRequestId = undefined;
      }
    },

    [detailCoach.pending.type]: (state, { meta }) => {
      const { requestId } = meta;
      if (!state.isFetching) {
        state.errorMessage = '';
        state.isError = false;
        state.isFetching = true;
        state.isSuccess = false;
        state.response = null;
        state.currentRequestId = requestId;
      }
    },
    [detailCoach.fulfilled.type]: (state, { payload, meta }) => {
      const { requestId } = meta;
      if (state.isFetching && state.currentRequestId === requestId) {
        state.isError = false;
        state.isSuccess = true;
        state.isFetching = false;
        state.errorMessage = '';
        state.response = payload.data;
        state.currentRequestId = undefined;
      }
    },
    [detailCoach.rejected.type]: (state, { payload, meta }) => {
      const { requestId } = meta;
      if (state.isFetching && state.currentRequestId === requestId) {
        state.isError = true;
        state.isSuccess = false;
        state.isFetching = false;
        state.errorMessage = payload;
        state.response = null;
        state.currentRequestId = undefined;
      }
    },

    [validateRegister.pending.type]: (state) => {
      state.errorMessage = '';
      state.isError = false;
      state.isFetching = true;
      state.isSuccess = false;
      state.response = null;
    },
    [validateRegister.fulfilled.type]: (state) => {
      state.isError = false;
      state.isSuccess = true;
      state.isFetching = false;
      state.errorMessage = '';
    },
    [validateRegister.rejected.type]: (state, { payload }) => {
      state.isError = true;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = payload;
    },
  },
});

export default coachesSlide;

export const { clearState } = coachesSlide.actions;

import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import './index.css';
import {Link} from "react-router-dom";
import {typeUser} from "../../constant";

Index.propTypes = {
  title: PropTypes.string,
  textList: PropTypes.string,
  textUpdate: PropTypes.string,
  type: PropTypes.string,
}
Index.defaultProps = {
  title: '',
  textList: '',
  textUpdate: '',
  type: '',
}
function Index(props) {
  const {title, textList, textUpdate, type} = props;
  const [urlUpdate, setUrlUpdate] = useState('');
  const [urlList, setUrlList] = useState('');

  useEffect(() => {
    if (type === typeUser.company) {
      setUrlUpdate('/company/update');
      setUrlList('/company/my-issue');
    }
    if (type === typeUser.student) {
      setUrlUpdate('/student/update');
      setUrlList('/student/issue-apply');
    }
    if (type === typeUser.coach) {
      setUrlUpdate('/coach/update');
      setUrlList('/coach/issue-apply');
    }
  }, [type]);

  return (
    <div className='top-content-block'>
      <div className='title-top-content'>{title}</div>
      <div className='switch-page-group'>
        <Link to={urlList}>
          <button className='btn-switch-page mr-3 active'>{textList}</button>
        </Link>
        <Link to={urlUpdate}>
          <button className='btn-switch-page' onClick={() => localStorage.setItem('is_issue', 'issue')}>{textUpdate}</button>
        </Link>
      </div>
    </div>
  );
}

export default Index;
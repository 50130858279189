import React, {useEffect} from 'react';
import {Button, Modal} from "react-bootstrap";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {listEvaluate} from "../../redux/evaluate/evaluate.slide";
import {useDispatch, useSelector} from "react-redux";


ListStudent.propTypes = {
  todos: PropTypes.bool,
  closeModal: PropTypes.func,
  users: PropTypes.array,
  showEvaluate: PropTypes.func,
  setUserId: PropTypes.func,
  evaluateArr: PropTypes.array,
  evaluate: PropTypes.func,
};

ListStudent.defaultProps = {
  todos: false,
  closeModal: null,
  users: [],
  showEvaluate: null,
  setUserId: null,
  evaluateArr: [],
  evaluate: null,
};
function ListStudent(props) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const { todos, closeModal, users, showEvaluate, setUserId, evaluateArr, evaluate} = props;
  const {isErrorList, isSuccessList, listEvaluates} = useSelector(state => state.evaluate);

  const getEvaluate = (id) => {
    dispatch(listEvaluate({
      user_id: id,
      issue_id: window.location.href.split('/').pop(),
    }));
  }

  useEffect(() => {
    if (isErrorList) {
      alert(t('error_system'));
    }
    if (isSuccessList) {
      evaluate(listEvaluates?.data?.data?.data?.[0]);
      console.log(listEvaluates);
    }
  }, [isErrorList, isSuccessList]);

  return (
    <Modal show={todos} centered className='modal-list-student-apply'>
      <Modal.Header>
        <Modal.Title>
          <div className="title-form">{t('list_student')}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className='table table-manager'>
          <thead>
          <tr>
            <th>{t('name_email')}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {users.length === 0 &&
            <tr>
              <td colSpan={2} className='no-data'>{t('no_data')}</td>
            </tr>
          }
          {users.map(user => (
            <tr key={user?.id}>
              <td>{user?.name}({user?.email})</td>
              <td><button className='btn-system' onClick={() => {showEvaluate(); setUserId(user?.id); getEvaluate(user?.id)}}>
                {evaluateArr.includes(user?.id) ? t('evaluate.edit') : t('comment_student')}
              </button></td>
            </tr>
          ))}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => closeModal(!todos)}>
          <div>{t('register_company.modal_button')}</div>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ListStudent;
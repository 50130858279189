import React, {useEffect, useState} from 'react';
import {datePicker} from "../../constant";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {getDayMonthYear} from  '../../helper/index';

Index.propTypes = {
  date: PropTypes.func,
  response: PropTypes.string,
}
Index.defaultValue = {
  date: () => {},
  response: '',
}
function Index(props) {
  const { t } = useTranslation();
  const current = new Date();
  const {listYear, listMonth, listDay28, listDay29, listDay30, listDay31} = datePicker;
  const [listDay, setListDay] = useState(listDay31);
  const [leapYear, setLeapYear] = useState(true);
  const [day, setDay] = useState(current.getDate());
  const [month, setMonth] = useState(current.getMonth() + 1);
  const [year, setYear] = useState(current.getFullYear());
  const {date, response} = props;

  useEffect(() => {
    if (response) {
      const birthDay = getDayMonthYear(response);
      setDay(birthDay?.day ?? current.getDate());
      setMonth(birthDay?.month ?? current.getMonth()+1);
      setYear(birthDay?.year ?? current.getFullYear());
    }
  }, [response]);
  function checkLeapYear(year) {
    setLeapYear(isLeapYear(year));
  }

  function isLeapYear(year) {
    return (year % 100 === 0) ? ( year % 400 === 0) : (year % 4 === 0);
  }

  const getDate = () => {
    date({
      day, month, year
    });
  }

  useEffect(() => {
    getDate();

    if (month === 2) {
      leapYear ? setListDay(listDay29) : setListDay(listDay28);
    } else if ([4, 6, 9, 11].includes(month)) {
      setListDay(listDay30);
    } else setListDay(listDay31);

    if ((!leapYear && month === 2 && day > 28) || (month === 2 && day > 29)) {
      setDay(1);
    }
  }, [day, month, year]);

  return (
    <div className="form-group row group-date-establishment">
      <select value={year ?? ''} className={`select-company ${year ? 'color-placeholder' : ''}`} onChange={(e) => { checkLeapYear(parseInt(e.target.value)); setYear(parseInt(e.target.value));}}>
        <option disabled hidden value="">{t('placeholder.date_establishment.year')}</option>
        {listYear.map((val, index) => (
          <option key={index} value={val.value}>{val.name}</option>
        ))}
      </select>
      <select value={month ?? ''} name="" id="" className={`select-company ${month ? 'color-placeholder' : ''}`} onChange={(e) => {setMonth(parseInt(e.target.value));}}>
        <option disabled hidden value="">{t('placeholder.date_establishment.month')}</option>
        {listMonth.map((val, index) => (
          <option key={index} value={val.value}>{val.name}</option>
        ))}
      </select>
      <select value={day ?? ''} name="" id="" className={`select-company ${day ? 'color-placeholder' : ''}`} onChange={(e) => {setDay(parseInt(e.target.value));}}>
        <option disabled hidden value="">{t('placeholder.date_establishment.day')}</option>
        {listDay.map((val, index) => (
          <option key={index} value={val.value}>{val.name}</option>
        ))}
      </select>
    </div>
  );
}

export default Index;
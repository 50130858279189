import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getSettingSchoolYear, updateSettingSchoolYear} from "../../redux/school_year/school_year.slide";
import Notification from "../../components/Modal/Notification";
import PropTypes from "prop-types";

SettingSchoolYear.propTypes = {
  setLoading: PropTypes.func,
}
SettingSchoolYear.defaultProps = {
  setLoading: null,
}
function SettingSchoolYear(props) {
  const { t } = useTranslation();
  const {setLoading} = props;
  const dispatch = useDispatch();
  const {isSuccessSchoolYear, listSchoolYears, isErrorSchoolYearUpdate, isSuccessSchoolYearUpdate, errorMessage} = useSelector((state) => state.schoolYear);
  const [schoolYear, setSchoolYear] = useState(null);
  const [validateSchoolYear, setValidateSchoolYear] = useState('');
  const [contentUpdate, setContentUpdate] = useState('');
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(getSettingSchoolYear());
  }, []);

  useEffect(() => {
    if (isSuccessSchoolYear) {
      setSchoolYear(listSchoolYears?.data?.school_year);
      setLoading(false);
    }
  }, [isSuccessSchoolYear]);

  const updateSchoolYear = (e) => {
    e.preventDefault();
    setLoading(true);
    setValidateSchoolYear('');
    dispatch(updateSettingSchoolYear({
      id: 1,
      school_year: schoolYear,
      _method: 'PUT',
    }));
  }

  useEffect(() => {
    if (isErrorSchoolYearUpdate) {
      if (errorMessage?.status === 422) {
        setValidateSchoolYear(errorMessage?.data?.errors?.school_year?.[0]);
        setLoading(false);
      }
      if (errorMessage?.status === 500) {
        alert(t('error_system'));
        setContentUpdate(t('admin.data.edit_error.setting_school_year'));
        setLoading(false);
      }
    }

    if (isSuccessSchoolYearUpdate) {
      setLoading(false);
      setContentUpdate(t('admin.data.edit_success.setting_school_year'));
      setOpenModal(true);
    }

  }, [isErrorSchoolYearUpdate, isSuccessSchoolYearUpdate]);

  const closeModal = (value) => {
    setOpenModal(value);
    location.reload();
  }

  return (
    <>
      <div className='manager-block d-block'>
        <div className='title-manager mb-5'>{t('admin.manager_school_year')}</div>
        <form onSubmit={updateSchoolYear} className="container-fluid">
          <div className="mb-3 d-flex align-items-center">
            <div className="col-2">{t('admin.setting_school_year_label')}</div>
            <div className="col-3 d-flex align-items-center">
              <input value={schoolYear || ''} type="text" className='input-system input-quantity mr-5'
                     onChange={(e) => setSchoolYear(e.target.value.trim())}
              />
            </div>
          </div>
          <p className='text-error'>{validateSchoolYear}</p>
          <button className='btn-save-company'>{t('update')}</button>
        </form>
      </div>
      <Notification
        todos={openModal}
        title={t('admin.data.title_update_setting_school_year')}
        content={contentUpdate}
        closeModal={closeModal} />
    </>
  );
}

export default SettingSchoolYear;
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {ErrorMessage, Field, Formik, FormikProvider, useFormik} from "formik";
import AvatarUser from "../../components/SelectFile/AvatarUser";
import DatePicker from "../../components/DatePicker/index";
import CitySelect from "../../components/City";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import Loading from "../../components/Loading";
import Notification from "../../components/Modal/Notification";
import {useNavigate} from "react-router-dom";
import GenderSelect from "../../components/Gender";
import {clearState, updateCoach, validateRegister} from "../../redux/coach/coach.slide";
import {typeUser, typeAvatar} from "../../constant";
import {updateCoach as adminUpdateCoach} from '../../redux/admin/admin.slide';
import ModalUpdateMail from "../../components/Modal/UpdateMail";

FormInfo.propTypes = {
  textBack: PropTypes.string,
  textSubmit: PropTypes.string,
  userInfo: PropTypes.any,
  type: PropTypes.string,
  showHideForm: PropTypes.func,
  isShow: PropTypes.bool,
  formData: PropTypes.func,
  typeSubmit: PropTypes.string,
}
FormInfo.defaultProps = {
  textBack: '',
  textSubmit: '',
  userInfo: null,
  type: '',
  showHideForm: null,
  isShow: true,
  formData: null,
  typeSubmit: '',
}

Formik.propTypes = {
  validationSchema: PropTypes.any,
  onSubmit: PropTypes.func,
};
function  FormInfo(props) {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [disableForm, setDisableForm] = useState(true);
  const {textBack, textSubmit, userInfo, type, showHideForm, isShow, formData, typeSubmit} = props;
  const [cityId, setCityId] = useState(null);
  const [file, setFile] = useState(null);
  const [date, setDate] = useState(null);
  const [validateDate, setValidateDate] = useState('');
  const [validateAvatar, setValidateAvatar] = useState('');
  const [validateCity, setValidateCity] = useState('');
  const [birthDay, setBirthDay] = useState('');
  const [validateGender, setValidateGender] = useState('');
  const [validateActive, setValidateActive] = useState('');
  const {isSuccess, isError, errorMessage, isFetching} = useSelector((state) => state.coach);
  const [openModal, setOpenModal] = useState(false);
  const [contentModal, setContentModal] = useState('');
  const [isCompanyView, setIsCompanyView] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [genderId, setGenderId] = useState(null);
  const [active, setActive] = useState(1);
  const [urlBack, setUrlBack] = useState('');
  const {isFetchingAdmin, isSuccessAdmin, isErrorAdmin, errorMessageAdmin} = useSelector(state => state.admin);
  const [openModalMail, setOpenModalMail] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
      phone_number: '',
      skills: '',
      city_id: '',
      birthday: '',
      gender_id: '',
      introduce_yourself: '',
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      formik.setErrors({});
      setIsSubmit(!isSubmit);
      clearValidate();
      const dateMonth = date?.month < 10 ? '0' + date?.month : date?.month;
      const dateDay = date?.day < 10 ? '0' + date?.day : date?.day;
      let data = {...values,
        avatar: file,
        birthday: (!date?.day && !date?.month && !date?.year) ? null : date?.year + '-' + dateMonth + '-' + dateDay,
        city_id: cityId,
        accept_company_view: isCompanyView ? 1 : 0,
        gender_id: genderId,
      };
      if (type === typeUser.admin) {
        data = {...data,
          id: window.location.href.split('/').pop(),
          active: active,
          _method: 'PUT',
        };
        data.email = userInfo?.email || '';
        dispatch(adminUpdateCoach(data));
      } else {
        if (typeSubmit === 'create') {
          dispatch(validateRegister(data));
          formData(data);
        }
        if (typeSubmit === 'update') {
          data.email = userInfo?.email || '';
          dispatch(updateCoach(data));
        }
      }
    }
  });

  useEffect(() => {
    if (isError || isErrorAdmin) {
      const errors = errorMessage || errorMessageAdmin;
      if (errors.status === 500) {
        setContentModal(t('update_coach.error_msg'));
        setOpenModal(true);
        return;
      }
      if (errors.status === 422) {
        formik.setErrors({
          name: errors?.data?.errors?.name?.[0] || '',
          email: errors?.data?.errors?.email?.[0] || '',
          phone_number: errors?.data?.errors?.phone_number?.[0] || '',
          gender_id: errors?.data?.errors?.gender_id?.[0] || '',
          introduce_yourself: errors?.data?.errors?.introduce_yourself?.[0] || '',
          skills: errors?.data?.errors?.skills?.[0] || '',
        });
        setValidateCity(errors?.data?.errors?.city_id?.[0] || '');
        setValidateGender(errors?.data?.errors?.gender_id?.[0] || '');
        setValidateDate(errors?.data?.errors?.birthday?.[0] || '');
        setValidateAvatar(errors?.data?.errors?.avatar?.[0] || '');
        setValidateActive(errors?.data?.errors?.active?.[0] || '');

        for (let i in errors.data.errors) {
          let el = document.querySelector('.' + i);
          if (el) {
            el.scrollIntoView();
            break;
          }
        }
      }
    }
    if (isSuccess || isSuccessAdmin) {
      if (typeSubmit !== 'create') {
        setContentModal(t('update_coach.success_msg'));
        setOpenModal(true);
      } else {
        handleShowHideForm();
      }

    }

    return () => dispatch(clearState());

  }, [isSuccess, isError, isSuccessAdmin, isErrorAdmin]);

  useEffect(() => {
    setOpenModal(false);
    clearValidate();
    if (userInfo) {
      formik.setFieldValue('email', userInfo?.email || '');
      formik.setFieldValue('phone_number', userInfo?.profile?.phone_number || '');
      formik.setFieldValue('name', userInfo?.name || '');
      formik.setFieldValue('introduce_yourself', userInfo?.profile?.introduce_yourself || '');
      formik.setFieldValue('skills', userInfo?.profile?.skills || '');
      setBirthDay(userInfo?.profile?.birthday ?? '');
      setGenderId(userInfo?.profile?.gender?.id);
      setCityId(userInfo?.profile?.city?.id);
      setIsCompanyView(!!userInfo?.profile?.accept_company_view);
      setActive(userInfo?.is_active);
    }
    if (type === typeUser.admin) {
      setUrlBack('/admin/coaches');
    } else {
      setUrlBack(userInfo?.update_flag ? '/coach' : '/');
    }
  }, [userInfo]);

  const clearValidate = () => {
    setValidateActive('');
    setValidateAvatar('');
    setValidateDate('');
    setValidateCity('');
    setValidateGender('');
  }

  const getGender = (id) => {
    setGenderId(id);
  }

  const handleDatePicker = (date) => {
    setDate(date);
  }

  const getFile = (file) => {
    setFile(file);
  }

  const getCity = (id) => {
    setCityId(id);
  }

  const closeModal = (value) => {
    window.scrollTo({top: 0});
    setOpenModal(value);
    if (type === typeUser.admin) {
      navigate('/admin/coaches');
    } else {
      navigate('/coach');
    }
  }

  const closeModalMail = () => {
    setOpenModalMail(false);
  }

  const handleShowHideForm = () => {
    showHideForm(false);
  }

  return (
    <>
      <ModalUpdateMail todos={openModalMail} closeModal={closeModalMail} userId={type === typeUser.admin ? window.location.href.split('/').pop() : null} />
      <Notification todos={openModal} title={t('update_coach.modal_title')} content={contentModal} closeModal={closeModal} />
      <div className={isShow ? 'container container-update-company' : 'd-none'}>
        <div className="form-group body-form-update-company">
          <div className="d-flex justify-content-start align-items-center flex-wrap mb-5 w-100">
            <div className="title-form-update-company">{t('application_form')}</div>
            <div className="info-form-update-company">{t('update_coach.register_info_coach')}</div>
          </div>
          <div className="form-group body-update-company body-update-coach">
            <AvatarUser
              getFile={getFile}
              textBack={textBack}
              textValidate={validateAvatar}
              backUrl={urlBack}
              avatar={userInfo?.profile?.image}
              clearValidate={isSubmit}
              type={typeAvatar.user}
            />
            <div className="form-group row col-md-9">
              <div className="col-lg-12">
                <FormikProvider value={formik}>
                  <form onSubmit={formik.handleSubmit}>
                    <div className="form-group mb-4 row col-md-12">
                      <label htmlFor="name" className='name'>{t('teacher.name')}</label>
                      <Field
                        type="text"
                        name="name"
                        placeholder={t('placeholder.company_name')}
                        className={`form-control input-system ${
                          formik.touched.name && formik.errors.name ? "is-invalid" : ""
                        }`}
                        {...formik.getFieldProps("name")}
                      />
                      <ErrorMessage
                        component="div"
                        name="name"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="form-group mb-4 row col-md-12">
                      <label htmlFor="gender" className='gender_id'>{t('update_coach.gender')}</label>
                      <GenderSelect getGender={getGender} response={userInfo?.profile?.gender?.id} />
                      <div className="text-error">{validateGender}</div>
                    </div>

                    <div className="form-group mb-4 row col-md-12">
                      <label htmlFor="name" className='birthday'>{t('update_coach.birthday')}</label>
                      <DatePicker date={handleDatePicker} response={birthDay} />
                      <div className="text-error">{validateDate}</div>
                    </div>

                    <div className="form-group mb-4 row col-md-12">
                      <label htmlFor="email" className='email'>{t('email_address')}</label>
                      <div className='d-flex justify-content-start align-items-start flex-wrap p-0'>
                        <div className='group-email'>
                          <Field
                            disabled={typeSubmit !== 'create'}
                            type="text"
                            name="email"
                            placeholder={t('placeholder.email')}
                            className={`form-control input-system ${
                              formik.touched.email && formik.errors.email ? "is-invalid" : ""
                            }`}
                            {...formik.getFieldProps("email")}
                          />
                          <ErrorMessage
                            component="div"
                            name="email"
                            className="invalid-feedback"
                          />
                        </div>
                        {typeSubmit !== 'create' &&
                          <button className='btn-update-mail' type='button' onClick={() => setOpenModalMail(true)} >{t('update_email.update')}</button>
                        }
                      </div>
                    </div>

                    <div className="form-group mb-4 row col-md-12">
                      <label htmlFor="phone_number" className='phone_number'>{t('update_coach.phone_number')}</label>
                      <Field
                        type="text"
                        name="phone_number"
                        placeholder={t('update_coach.placeholder.phone_number')}
                        className={`form-control input-system ${
                          formik.touched.phone_number && formik.errors.phone_number ? "is-invalid" : ""
                        }`}
                        {...formik.getFieldProps("phone_number")}
                      />
                      <ErrorMessage
                        component="div"
                        name="phone_number"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="form-group mb-4 row col-md-12">
                      <label htmlFor="city_id" className='city_id'>{t('update_coach.address')}</label>
                      <CitySelect getCity={getCity} response={userInfo?.profile?.city?.id} />
                      <div className="text-error">{validateCity}</div>
                    </div>

                    <div className="form-group mb-4 row col-md-12">
                      <label htmlFor="introduce_yourself" className='introduce_yourself'>{t('update_coach.bio')}</label>
                      <textarea
                        type="text"
                        name="introduce_yourself"
                        placeholder={t('update_coach.placeholder.bio')}
                        className={`form-control text-area-company ${
                          formik.touched.introduce_yourself && formik.errors.introduce_yourself ? "is-invalid" : ""
                        }`}
                        {...formik.getFieldProps("introduce_yourself")}
                      />
                      <ErrorMessage
                        component="div"
                        name="introduce_yourself"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="form-group mb-4 row col-md-12">
                      <label htmlFor="skills" className='skills'>{t('update_coach.skills')}</label>
                      <textarea
                        type="text"
                        name="skills"
                        placeholder={t('update_coach.placeholder.skills')}
                        className={`form-control text-area-company ${
                          formik.touched.skills && formik.errors.skills ? "is-invalid" : ""
                        }`}
                        {...formik.getFieldProps("skills")}
                      />
                      <ErrorMessage
                        component="div"
                        name="skills"
                        className="invalid-feedback"
                      />
                    </div>

                    {type === typeUser.admin &&
                      <div className="form-group mb-4 row col-md-12">
                        <label htmlFor="is_active" className='is_active'>{t('admin.status')}</label>
                        <select value={active} name="is_active" id="" className={`select-company col-md-3 ${active !== null ? 'color-placeholder' : ''}`} onChange={(e) => setActive(parseInt(e.target.value))}>
                          <option value="1">{t('admin.active')}</option>
                          <option value="0">{t('admin.de_active')}</option>
                        </select>
                        <div className="text-error">{validateActive}</div>
                      </div>
                    }

                    <div className="d-flex justify-content-between align-items-start flex-wrap">
                      <div className="d-flex justify-content-start align-items-start flex-wrap checkbox-block">
                        <div className="d-flex justify-content-start align-items-start mr-5 mb-2">
                          <div className="custom-checkbox">
                            <Field id="checkbox1" type="checkbox" className="checkbox-system" value="1" checked={isCompanyView} onClick={(e) => {setIsCompanyView(e.target.checked)}} />
                            <label htmlFor="checkbox1"className=""></label>
                          </div>
                          <label htmlFor="checkbox1" className="main-text">{t('update_coach.accept_company_view')}</label>
                        </div>
                        <div className="d-flex justify-content-start align-items-start mb-4">
                          <div className="custom-checkbox">
                            <Field id="checkbox2" type="checkbox" className="checkbox-system" value="1" checked={!disableForm}  onChange={() => {setDisableForm(!disableForm)}}/>
                            <label htmlFor="checkbox2"className=""></label>
                          </div>
                          <div className="d-flex flex-column align-items-start">
                            <label htmlFor="checkbox2" className="main-text">{t('update_coach.policy')}</label>
                            <label htmlFor="checkbox2" className="main-text font-12">{t('update_coach.policy2')}</label>
                          </div>
                        </div>
                      </div>
                      <div className="parent-btn-save-company">
                        <button type="submit" className="btn-save-company" disabled={disableForm}>{textSubmit}</button>
                      </div>
                    </div>
                  </form>
                </FormikProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isFetching || isFetchingAdmin ? <Loading /> : ''}
    </>
  );
}

export default FormInfo;
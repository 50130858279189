import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import DetailStudent from '../../components/Detail/index';
import {clearState, detailCoach} from "../../redux/coach/coach.slide";
import NotFound from "../../components/404";
import {typeUser} from "../../constant";

function Detail() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {isSuccess, isError, errorMessage, response, isFetching} = useSelector((state) => state.coach);
  const [isCoach, setIsCoach] = useState(true);

  useEffect(() => {
    dispatch(detailCoach({id: window.location.pathname.split('/').pop()}));
    return () => dispatch(clearState());
  }, []);

  useEffect(() => {
    if (isSuccess) {
      window.scrollTo({top: 0});
      if (response?.data?.role !== typeUser.coach) {
        setIsCoach(false);
      }
    }
    if (isError) {
      if (errorMessage.status === 500) {
        alert(t('error_system'));
      }
      if (errorMessage.status === 404) {
        setIsCoach(false);
      }
    }

    return () => dispatch(clearState());
  }, [isSuccess, isError]);

  return (
    <>
      {isCoach ? <DetailStudent response={response} type='coaches' isLoading={isFetching} /> :
        <NotFound />
      }
    </>
  );
}

export default Detail;
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {post} from "../../services";

const initialState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: '',
  fetchingTeacher: false,
  successTeacher: false,
  errorTeacher: false,
  errorMessageTeacher: '',
};


export const validateUpdate = createAsyncThunk(
  'auth/teachers/validate-create-profile',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'auth/teachers/validate-create-profile';
      const response = await post(url, params, true);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

export const updateTeacher = createAsyncThunk(
  'auth/teachers/update-profile',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'auth/teachers/update-profile';
      const response = await post(url, params, true);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);


const teachersSlide = createSlice({
  name: 'teachers',
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isFetching = false;
      state.isSuccess = false;
      state.errorMessage = '';
      state.fetchingTeacher = false;
      state.successTeacher = false;
      state.errorTeacher = false;
      state.errorMessageTeacher = '';
      return state;
    },
  },
  extraReducers: {
    [validateUpdate.pending.type]: (state) => {
      if (!state.isFetching) {
        state.errorMessage = '';
        state.isError = false;
        state.isFetching = true;
        state.isSuccess = false;
      }
    },
    [validateUpdate.fulfilled.type]: (state) => {
      if (state.isFetching) {
        state.isError = false;
        state.isSuccess = true;
        state.isFetching = false;
        state.errorMessage = '';
      }
    },
    [validateUpdate.rejected.type]: (state, { payload }) => {
      if (state.isFetching) {
        state.isError = true;
        state.isSuccess = false;
        state.isFetching = false;
        state.errorMessage = payload;
      }
    },

    [updateTeacher.pending.type]: (state) => {
      if (!state.isFetching) {
        state.errorMessage = '';
        state.isError = false;
        state.isFetching = true;
        state.isSuccess = false;
      }
    },
    [updateTeacher.fulfilled.type]: (state) => {
      if (state.isFetching) {
        state.isError = false;
        state.isSuccess = true;
        state.isFetching = false;
        state.errorMessage = '';
      }
    },
    [updateTeacher.rejected.type]: (state, { payload }) => {
      if (state.isFetching) {
        state.isError = true;
        state.isSuccess = false;
        state.isFetching = false;
        state.errorMessage = payload;
      }
    },
  },
});

export default teachersSlide;

export const { clearState } = teachersSlide.actions;

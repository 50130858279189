import React, {useEffect, useState} from 'react';
import nl2br from "react-nl2br";
import PropTypes from "prop-types";
import avatarDefault from '../../assets/image/avatar_default.png';
import {typeUser} from "../../constant";

Message.propTypes = {
  response: PropTypes.object,
  userId: PropTypes.number,
}

Message.defaultProps = {
  response: [],
  userId: 0,
}
function Message(props) {
  const {response, userId} = props;
  const [msgDefault, setMsgDefault] = useState(false);

  useEffect(() => {
    if (response?.user?.role === typeUser.admin) {
      setMsgDefault(true);
    }
  }, [response]);

  return (
    <>
      {msgDefault ?
        <div className='content-msg-receiver content-msg-block'>
          <div className='msg-receiver'>
            <div className='content-msg'>{nl2br(response?.content)}</div>
          </div>
        </div> :
        <div className={response?.user?.id === userId ? 'content-msg-send content-msg-block' : 'content-msg-receiver content-msg-block'}>
          <div className={response?.user?.id === userId ? 'msg-send' : 'msg-receiver'}>
            <div className='group-avatar-status'>
              <img src={response?.user?.profile?.image ?? avatarDefault} alt="avatar" className='avatar-chat-content' />
            </div>
            <div className='group-content-msg'>
              <div className='group-name-time'>
                <div className='name-user-msg' title={response?.user?.name}>{response?.user?.name}</div>
                <div className='time-send'>{response?.created_at}</div>
              </div>
              <div className='content-msg'>{nl2br(response?.content)}</div>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default Message;
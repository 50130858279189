import React from 'react';
import {Box, ThemeProvider} from '@mui/material';
import AppRoutes from '../../routes';
import { ThemeDispatchProvider, useThemeContext } from '../../contexts/theme';
// import {Router} from "react-router-dom";
function Index() {
  const [themeContext, themeDispatch] = useThemeContext();
  return (
    <ThemeProvider theme={themeContext}>
      <ThemeDispatchProvider value={themeDispatch}>
        <Box className={`${themeContext.palette.mode} full-viewport`}>
          <Box flex={1} className="bg-light dark:bg-dark main-block">
            <AppRoutes />
          </Box>
        </Box>
      </ThemeDispatchProvider>
    </ThemeProvider>
  );
}

export default Index;
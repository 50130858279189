import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {get, post} from "../../services";

const initialState = {
  isFetching: false,
  isSuccessSchoolYear: false,
  isErrorSchoolYear: false,
  errorMessage: '',
  response: null,
  currentRequestId: undefined,
  listSchoolYears: null,
};

export const getSettingSchoolYear = createAsyncThunk(
  'setting-school-years',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'setting-school-years';
      const response = await get(url, {}, params);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

export const updateSettingSchoolYear = createAsyncThunk(
  'setting-school-years/update',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'setting-school-years/' + params?.id;
      const response = await post(url, params, false);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

const schoolYearsSlide = createSlice({
  name: 'school_years',
  initialState,
  reducers: {
    clearState: (state) => {
      state.isErrorSchoolYear = false;
      state.isFetching = false;
      state.isSuccessSchoolYear = false;
      state.errorMessage = '';
      state.response = null;
      return state;
    },
  },
  extraReducers: {
    [getSettingSchoolYear.pending.type]: (state, { meta }) => {
      const { requestId } = meta;
      if (!state.isFetching) {
        state.errorMessage = '';
        state.isErrorSchoolYear = false;
        state.isFetching = true;
        state.isSuccessSchoolYear = false;
        state.response = null;
        state.listSchoolYears = null;
        state.currentRequestId = requestId;
      }
    },
    [getSettingSchoolYear.fulfilled.type]: (state, { payload, meta }) => {
      const { requestId } = meta;
      if (state.isFetching && state.currentRequestId === requestId) {
        state.isErrorSchoolYear = false;
        state.isSuccessSchoolYear = true;
        state.isFetching = false;
        state.errorMessage = '';
        state.response = payload.data;
        state.listSchoolYears = payload.data;
        state.currentRequestId = undefined;
      }
    },
    [getSettingSchoolYear.rejected.type]: (state, { payload, meta }) => {
      const { requestId } = meta;
      if (state.isFetching && state.currentRequestId === requestId) {
        state.isErrorSchoolYear = true;
        state.isSuccessSchoolYear = false;
        state.isFetching = false;
        state.errorMessage = payload;
        state.response = null;
        state.listSchoolYears = null;
        state.currentRequestId = undefined;
      }
    },

    [updateSettingSchoolYear.pending.type]: (state) => {
      state.errorMessage = '';
      state.isErrorSchoolYearUpdate = false;
      state.isFetching = true;
      state.isSuccessSchoolYearUpdate = false;
    },
    [updateSettingSchoolYear.fulfilled.type]: (state) => {
      state.isErrorSchoolYearUpdate = false;
      state.isSuccessSchoolYearUpdate = true;
      state.isFetching = false;
      state.errorMessage = '';
    },
    [updateSettingSchoolYear.rejected.type]: (state, { payload }) => {
      state.isErrorSchoolYearUpdate = true;
      state.isSuccessSchoolYearUpdate = false;
      state.isFetching = false;
      state.errorMessage = payload;
    },
  },
});

export default schoolYearsSlide;

export const { clearState } = schoolYearsSlide.actions;

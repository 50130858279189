import {issueStatus} from "../constant";

export const getTypeIssue = (online, offline) => {
  if (online && offline) return 'Hybrid';
  if (online) return 'Online';
  if (offline) return 'Offline';

  return '';
}

export const getDayMonthYear = (date) => {
  const data = {};
  if (date) {
    const dateArr = date.split('-');
    data.day = parseInt(dateArr[2]);
    data.month = parseInt(dateArr[1]);
    data.year = parseInt(dateArr[0]);
  }

  return data;
}

export const getStatusIssue = (status) => {
  switch (status) {
    case issueStatus.open:
      return '募集中';
    case issueStatus.start:
      return '開催決定・募集終了';
    case issueStatus.end:
      return '終了';
    case issueStatus.temp:
      return '一時保存';
    case issueStatus.complete:
      return '課題完了';
    default:
      return '';
  }
}
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {ErrorMessage, Field, Formik, FormikProvider, useFormik} from "formik";
import AvatarUser from "../../components/SelectFile/AvatarUser";
import DatePicker from "../../components/DatePicker/index";
import CitySelect from "../../components/City";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import Loading from "../../components/Loading";
import Notification from "../../components/Modal/Notification";
import {useNavigate} from "react-router-dom";
import {clearState, updateUserCompany, validateCompany} from "../../redux/user/user.slide";
import {typeAvatar, typeUser} from "../../constant";
import {updateCompany} from "../../redux/admin/admin.slide";
import ModalUpdateMail from '../../components/Modal/UpdateMail';

FormInfo.propTypes = {
  textBack: PropTypes.string,
  textSubmit: PropTypes.string,
  userInfo: PropTypes.any,
  type: PropTypes.string,
  showHideForm: PropTypes.func,
  isShow: PropTypes.bool,
  formData: PropTypes.func,
  typeSubmit: PropTypes.string,
}
FormInfo.defaultProps = {
  textBack: '',
  textSubmit: '',
  userInfo: null,
  type: '',
  showHideForm: null,
  isShow: true,
  formData: null,
  typeSubmit: '',
}

Formik.propTypes = {
  validationSchema: PropTypes.any,
  onSubmit: PropTypes.func,
};
function  FormInfo(props) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {textBack, textSubmit, userInfo, type, formData, isShow, showHideForm, typeSubmit} = props;
  const {isFetching, isSuccess, isError, errorMessage} = useSelector(state => state.user);
  const {isFetchingAdmin, isSuccessAdmin, isErrorAdmin, errorMessageAdmin} = useSelector(state => state.admin);
  const [disableForm, setDisableForm] = useState(true);
  const [cityId, setCityId] = useState(null);
  const [date, setDate] = useState(null);
  const [file, setFile] = useState(null);
  const [validateAvatar, setValidateAvatar] = useState('');
  const [validateDate, setValidateDate] = useState('');
  const [validateCity, setValidateCity] = useState('');
  const [validateActive, setValidateActive] = useState('');
  const [birthDay, setBirthDay] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [contentModal, setContentModal] = useState('');
  const [isStudentView, setIsStudentView] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [urlBack, setUrlBack] = useState('');
  const [active, setActive] = useState(1);
  const [openModalMail, setOpenModalMail] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone_number: '',
      web_url: '',
      bio: '',
      expectations: '',
      address: '',
      birthday: '',
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      setIsSubmit(!isSubmit);
      formik.setErrors({});
      clearValidate();
      const dateMonth = date?.month < 10 ? '0' + date?.month : date?.month;
      const dateDay = date?.day < 10 ? '0' + date?.day : date?.day;
      let data = {...values,
        avatar: file,
        birthday: (!date?.day && !date?.month && !date?.year) ? null : date?.year + '-' + dateMonth + '-' + dateDay,
        city_id: cityId,
        accept_student_view: isStudentView ? 1 : 0,
      };
      if (type === typeUser.admin) {
        data = {...data,
          id: window.location.href.split('/').pop(),
          active: active,
          _method: 'PUT',
        };
        data.email = userInfo?.email || '';
        dispatch(updateCompany(data));
      } else {
        if (typeSubmit === 'create') {
          dispatch(validateCompany(data));
          formData(data);
        }
        if (typeSubmit === 'update') {
          data.email = userInfo?.email || '';
          dispatch(updateUserCompany(data));
        }
      }
    }
  });

  useEffect(() => {
    if (isError || isErrorAdmin) {
      const errors = errorMessage || errorMessageAdmin;
      if (errors.status === 500) {
        setContentModal(t('update_company_error'));
        setOpenModal(true);
        return;
      }
      if (errors.status === 422) {
        formik.setErrors({
          name: errors?.data?.errors?.name?.[0] || '',
          email: errors?.data?.errors?.email?.[0] || '',
          phone_number: errors?.data?.errors?.phone_number?.[0] || '',
          city_id: errors?.data?.errors?.city_id?.[0] || '',
          address: errors?.data?.errors?.address?.[0] || '',
          web_url: errors?.data?.errors?.web_url?.[0] || '',
          bio: errors?.data?.errors?.bio?.[0] ?? '',
          expectations: errors?.data?.errors?.expectations?.[0] || '',
          accept_student_view: errors?.data?.errors?.accept_student_view?.[0] || '',
        });
        setValidateDate(errors?.data?.errors?.birthday?.[0] || '');
        setValidateAvatar(errors?.data?.errors?.avatar?.[0] || '');
        setValidateCity(errors?.data?.errors?.city_id?.[0] || '');
        setValidateActive(errors?.data?.errors?.active?.[0] || '');

        for (let i in errors.data.errors) {
          let el = document.querySelector('.' + i);
          if (el) {
            el.scrollIntoView();
            break;
          }
        }
      }
    }
    if (isSuccess || isSuccessAdmin) {
      if (typeSubmit !== 'create') {
        setContentModal(t('update_company_success'));
        setOpenModal(true);
      } else {
        handleShowHideForm();
      }
    }

    return () => dispatch(clearState());

  }, [isSuccess, isError, isSuccessAdmin, isErrorAdmin]);

  useEffect(() => {
    setOpenModal(false);
    clearValidate();
    if (userInfo) {
      formik.setFieldValue('email', userInfo?.email || '');
      formik.setFieldValue('name', userInfo?.name || '');
      formik.setFieldValue('phone_number', userInfo?.profile?.phone_number || '');
      formik.setFieldValue('address', userInfo?.profile?.address || '');
      formik.setFieldValue('web_url', userInfo?.profile?.web_url || '');
      formik.setFieldValue('address', userInfo?.profile?.address || '');
      formik.setFieldValue('bio', userInfo?.profile?.bio || '');
      formik.setFieldValue('expectations', userInfo?.profile?.expectations || '');
      setBirthDay(userInfo?.profile?.birthday ?? '');
      setCityId(userInfo?.profile?.city_id);
      setIsStudentView(!!userInfo?.profile?.accept_student_view);
      setActive(userInfo?.is_active);
    }
    if (type === typeUser.admin) {
      setUrlBack('/admin/companies');
    } else {
      setUrlBack(userInfo?.update_flag ? '/company/students' : '/');
      if (localStorage.getItem('is_issue')) setUrlBack('/company/my-issue');
    }
  }, [userInfo]);

  const clearValidate = () => {
    setValidateAvatar('');
    setValidateDate('');
    setValidateCity('');
    setValidateActive('');
  }

  const handleDatePicker = (date) => {
    setDate(date);
  }

  const getFile = (file) => {
    setFile(file);
  }

  const getCity = (id) => {
    setCityId(id);
  }

  const closeModal = (value) => {
    window.scrollTo({top: 0});
    setOpenModal(value);
    if (type === typeUser.admin) {
      navigate('/admin/companies');
    } else {
      navigate('/company/students');
      if (localStorage.getItem('is_issue')) navigate('/company/my-issue');
    }
  }

  const closeModalMail = () => {
    setOpenModalMail(false);
  }

  const handleShowHideForm = () => {
    showHideForm(false);
  }

  return (
    <>
      <ModalUpdateMail todos={openModalMail} closeModal={closeModalMail} userId={type === typeUser.admin ? window.location.href.split('/').pop() : null} />
      <Notification todos={openModal} title={t('update_company_title')} content={contentModal} closeModal={closeModal} />
      <div className={isShow ? 'container container-update-company' : 'd-none'}>
        <div className="form-group body-form-update-company">
          <div className="d-flex justify-content-start align-items-center flex-wrap mb-5 w-100">
            <div className="title-form-update-company">{t('application_form')}</div>
            <div className="info-form-update-company">{t('register_info_company')}</div>
          </div>
          <div className="form-group body-update-company body-update-coach">
            <AvatarUser
              getFile={getFile}
              textBack={textBack}
              textValidate={validateAvatar}
              backUrl={urlBack}
              avatar={userInfo?.profile?.image}
              clearValidate={isSubmit}
              type={typeAvatar.user}
            />
            <div className="form-group row col-md-9">
              <div className="col-lg-12">
                <FormikProvider value={formik}>
                  <form onSubmit={formik.handleSubmit}>
                    <div className="form-group mb-4 row col-md-12">
                      <label htmlFor="name" className='name'>{t('company_name')}</label>
                      <Field
                        type="text"
                        name="name"
                        placeholder={t('placeholder.company_name')}
                        className={`form-control input-system ${
                          formik.touched.name && formik.errors.name ? "is-invalid" : ""
                        }`}
                        {...formik.getFieldProps("name")}
                      />
                      <ErrorMessage
                        component="div"
                        name="name"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="form-group mb-4 row col-md-12">
                      <label htmlFor="name" className='birthday'>{t('date_of_establishment')}</label>
                      <DatePicker date={handleDatePicker} response={birthDay} />
                      <div className="text-error">{validateDate}</div>
                    </div>

                    <div className="form-group mb-4 row col-md-12">
                      <label htmlFor="email" className='email'>{t('email_address')}</label>
                      <div className='d-flex justify-content-start align-items-start flex-wrap p-0'>
                        <div className='group-email'>
                          <Field
                            disabled={typeSubmit !== 'create'}
                            type="text"
                            name="email"
                            placeholder={t('placeholder.email')}
                            className={`form-control input-system ${
                              formik.touched.email && formik.errors.email ? "is-invalid" : ""
                            }`}
                            {...formik.getFieldProps("email")}
                          />
                          <ErrorMessage
                            component="div"
                            name="email"
                            className="invalid-feedback"
                          />
                        </div>
                        {typeSubmit !== 'create' &&
                          <button className='btn-update-mail' type='button' onClick={() => setOpenModalMail(true)} >{t('update_email.update')}</button>
                        }
                      </div>
                    </div>

                    <div className="form-group mb-4 row col-md-12">
                      <label htmlFor="phone_number" className='phone_number'>{t('update_coach.phone_number')}</label>
                      <Field
                        type="text"
                        name="phone_number"
                        placeholder={t('update_coach.placeholder.phone_number')}
                        className={`form-control input-system ${
                          formik.touched.phone_number && formik.errors.phone_number ? "is-invalid" : ""
                        }`}
                        {...formik.getFieldProps("phone_number")}
                      />
                      <ErrorMessage
                        component="div"
                        name="phone_number"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="form-group mb-4 row col-md-12">
                      <label htmlFor="city_id" className='city_id address'>{t('update_coach.address')}</label>
                      <CitySelect getCity={getCity} response={userInfo?.profile?.city_id} />
                      <div className="text-error">{validateCity}</div>
                      <Field
                        type="text"
                        name="address"
                        className={`form-control input-system ${
                          formik.touched.address && formik.errors.address ? "is-invalid" : ""
                        }`}
                        {...formik.getFieldProps("address")}
                      />
                      <ErrorMessage
                        component="div"
                        name="address"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="form-group mb-4 row col-md-12">
                      <label htmlFor="web_url" className='web_url'>{t('web_url')}</label>
                      <Field
                        type="text"
                        name="web_url"
                        placeholder={t('placeholder.web_url')}
                        className={`form-control input-system ${
                          formik.touched.web_url && formik.errors.web_url ? "is-invalid" : ""
                        }`}
                        {...formik.getFieldProps("web_url")}
                      />
                      <ErrorMessage
                        component="div"
                        name="web_url"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="form-group mb-4 row col-md-12">
                      <label htmlFor="bio" className='bio'>{t('bio')}</label>
                      <textarea
                        type="text"
                        name="bio"
                        placeholder={t('placeholder.bio')}
                        className={`form-control text-area-company ${
                          formik.touched.bio && formik.errors.bio ? "is-invalid" : ""
                        }`}
                        {...formik.getFieldProps("bio")}
                      />
                      <ErrorMessage
                        component="div"
                        name="bio"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="form-group mb-4 row col-md-12">
                      <label htmlFor="expectations" className='expectations' >{t('expectations')}</label>
                      <textarea
                        type="text"
                        name="expectations"
                        placeholder={t('placeholder.expectations')}
                        className={`form-control text-area-company ${
                          formik.touched.expectations && formik.errors.expectations ? "is-invalid" : ""
                        }`}
                        {...formik.getFieldProps("expectations")}
                      />
                      <ErrorMessage
                        component="div"
                        name="expectations"
                        className="invalid-feedback"
                      />
                    </div>

                    {type === typeUser.admin &&
                      <div className="form-group mb-4 row col-md-12">
                        <label htmlFor="is_active" className='is_active'>{t('admin.status')}</label>
                        <select value={active} name="is_active" id="" className={`select-company col-md-3 ${active !== null ? 'color-placeholder' : ''}`} onChange={(e) => setActive(parseInt(e.target.value))}>
                          <option value="1">{t('admin.active')}</option>
                          <option value="0">{t('admin.de_active')}</option>
                        </select>
                        <div className="text-error">{validateActive}</div>
                      </div>
                    }

                    <div className="d-flex justify-content-between align-items-start flex-wrap">
                      <div className="d-flex justify-content-start align-items-start flex-wrap checkbox-block">
                        <div className="d-flex justify-content-start align-items-start mr-5 mb-2">
                          <div className="custom-checkbox">
                            <Field id="checkbox1" type="checkbox" className="checkbox-system" value="1" checked={isStudentView} onClick={(e) => {setIsStudentView(e.target.checked)}} />
                            <label htmlFor="checkbox1"className=""></label>
                          </div>
                          <label htmlFor="checkbox1" className="main-text">{t('allow_student_view')}</label>
                        </div>
                        <div className="d-flex justify-content-start align-items-start mb-4">
                          <div className="custom-checkbox">
                            <Field id="checkbox2" type="checkbox" className="checkbox-system" value="1" checked={!disableForm}  onChange={() => {setDisableForm(!disableForm)}} />
                            <label htmlFor="checkbox2"className=""></label>
                          </div>
                          <div className="d-flex flex-column align-items-start">
                            <label htmlFor="checkbox2" className="main-text">{t('privacy_policy')}</label>
                            <label htmlFor="checkbox2" className="main-text font-12">{t('process_info')}</label>
                          </div>
                        </div>
                      </div>
                      <div className="parent-btn-save-company">
                        <button type="submit" className="btn-save-company" disabled={disableForm}>{textSubmit}</button>
                      </div>
                    </div>
                  </form>
                </FormikProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isFetching || isFetchingAdmin ? <Loading /> : ''}
    </>
  );
}

export default FormInfo;
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {get} from "../../services";

const initialState = {
  isFetching: false,
  isSuccessCategory: false,
  isErrorCategory: false,
  errorMessage: '',
  listCategories: null,
};

export const ListCategories = createAsyncThunk(
  'categories/list',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'categories';
      const response = await get(url, params);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

const categoriesSlide = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    clearState: (state) => {
      state.isErrorCategory = false;
      state.isFetching = false;
      state.isSuccessCategory = false;
      state.errorMessage = '';
      state.listCategories = null;
      return state;
    },
  },
  extraReducers: {
    [ListCategories.pending.type]: (state) => {
      state.errorMessage = '';
      state.isErrorCategory = false;
      state.isFetching = true;
      state.isSuccessCategory = false;
      state.listCategories = null;
    },
    [ListCategories.fulfilled.type]: (state, { payload }) => {
      state.isErrorCategory = false;
      state.isSuccessCategory = true;
      state.isFetching = false;
      state.errorMessage = '';
      state.listCategories = payload;
    },
    [ListCategories.rejected.type]: (state, { payload }) => {
      state.isErrorCategory = true;
      state.isSuccessCategory = false;
      state.isFetching = false;
      state.errorMessage = payload;
      state.listCategories = null;
    },
  },
});

export default categoriesSlide;

export const { clearState } = categoriesSlide.actions;

import React from 'react';
import {Button, Modal} from "react-bootstrap";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

ConfirmDelete.propTypes = {
  todos: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.string,
  closeModal: PropTypes.func,
  name: PropTypes.string,
  email: PropTypes.string,
  handleDelete: PropTypes.func,
  nameTitle: PropTypes.string,
};

ConfirmDelete.defaultProps = {
  todos: false,
  title: '',
  content: '',
  closeModal: null,
  name: '',
  email: '',
  handleDelete: null,
  nameTitle: '',
};

function ConfirmDelete(props) {
  const {t} = useTranslation();
  const { todos, title, content, closeModal, name, email, handleDelete, nameTitle } = props;

  const handleDeleteCompany = () => {
    handleDelete();
  }

  return (
    <div>
      <Modal show={todos} centered>
        <Modal.Header>
          <Modal.Title>
            <div className="title-form">{title}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="main-text">{content}</div>
          <div className='main-text'>{nameTitle}：{name}</div>
          {email && <div className='main-text'>{t('admin.company.email')}：{email}</div>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => closeModal(!todos)}>
            <div>{t('register_company.modal_button')}</div>
          </Button>
          <Button variant="danger" onClick={handleDeleteCompany}>
            <div>{t('admin.delete')}</div>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ConfirmDelete;
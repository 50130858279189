import React from 'react';
import favoriteIcon from "../../assets/image/favorite.svg";
import favoriteActiveIcon from "../../assets/image/favorite_active.svg";

import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import avatarDefault from '../../assets/image/avatar_default.png';
import {post} from "../../services";

ResultStudent.propTypes = {
  response: PropTypes.array,
}
ResultStudent.defaultProps = {
  response: [],
}
function ResultStudent(props) {
  const { t } = useTranslation();
  const {response} = props;

  const toggleFavoriteStudent = async (e, id) => {
    const url = '/companies/' + id + '/favorite';
    const data = await post(url, {});

    if (data?.status === 200) {
      if(e.target.src.includes(favoriteIcon)) {
        e.target.src = favoriteActiveIcon
      } else {
        e.target.src = favoriteIcon
      }
    }
  }

  return (
    <div className="list-result-search">
      {response.length === 0 ? <div className="no-data">{t('no_data')}</div> : ''}
      {response.map((val) => (
        <div key={val?.id} className="result-item">
          <Link className="result-item-link" to={'/company/students/' + val?.id} >
            <div className='group-avatar-bg' style={{ backgroundImage: `url(${val?.profile?.image ?? avatarDefault})` }}>
              <div className="type-student-tag" title={val?.profile?.type_of_school?.name}>{val?.profile?.type_of_school?.name}</div>
            </div>
            <div className="group-des-item">
              <div className="title-item text-three-dots" title={val?.name}>{val?.name}</div>
              <div className="detail-item text-three-dots" title={val?.profile?.school_name}>{val?.profile?.school_name}</div>
              <div className="detail-item text-three-dots" title={val?.profile?.major?.name}>{val?.profile?.major?.name}</div>
              <div className="detail-item text-three-dots" title={val?.profile?.gender?.name}>{val?.profile?.gender?.name}</div>
            </div>
          </Link>
          <button className="favorite-button" type={"button"} onClick={(event) => toggleFavoriteStudent( event, val?.id)}>
            <img src={ val.is_favorite ? favoriteActiveIcon : favoriteIcon } alt="icon" className="img-favorite" />
          </button>
        </div>
      ))}
    </div>
  );
}

export default ResultStudent;

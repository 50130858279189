import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {get} from "../../services";

const initialState = {
  isFetching: false,
  isSuccessCity: false,
  isErrorCity: false,
  errorMessage: '',
  listCities: null,
};

export const getListCities = createAsyncThunk(
  'cities',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'cities';
      const response = await get(url, params);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

const citiesSlide = createSlice({
  name: 'cities',
  initialState,
  reducers: {
    clearState: (state) => {
      state.isFetching = false;
      state.isErrorCity = false;
      state.isSuccessCity = false;
      state.errorMessage = '';
      state.listCities = null;
      return state;
    },
  },
  extraReducers: {
    [getListCities.pending.type]: (state) => {
      if (!state.isFetching) {
        state.errorMessage = '';
        state.isErrorCity = false;
        state.isFetching = true;
        state.isSuccessCity = false;
        state.listCities = null;
      }
    },
    [getListCities.fulfilled.type]: (state, { payload }) => {
      if (state.isFetching) {
        state.isErrorCity = false;
        state.isSuccessCity = true;
        state.isFetching = false;
        state.errorMessage = '';
        state.listCities = payload.data;
      }
    },
    [getListCities.rejected.type]: (state, { payload }) => {
      if (state.isFetching) {
        state.isErrorCity = true;
        state.isSuccessCity = false;
        state.isFetching = false;
        state.errorMessage = payload;
        state.listCities = null;
      }
    },
  },
});

export default citiesSlide;

export const { clearState } = citiesSlide.actions;

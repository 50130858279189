import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {post} from "../../services";

const initialState = {
  isFetchingAdmin: false,
  isSuccessAdmin: false,
  isErrorAdmin: false,
  errorMessageAdmin: ''
};

export const updateCompany = createAsyncThunk(
  'update/company',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'companies/' + params?.id;
      const response = await post(url, params, true);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

export const updateStudent = createAsyncThunk(
  'update/student',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'students/' + params?.id;
      const response = await post(url, params, true);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

export const updateCoach = createAsyncThunk(
  'update/coach',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'coaches/' + params?.id;
      const response = await post(url, params, true);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

export const updateTeacher = createAsyncThunk(
  'update/teachers',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'teachers/' + params?.id;
      const response = await post(url, params, true);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

const adminsSlide = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    clearState: (state) => {
      state.isFetchingAdmin = null;
      state.isSuccessAdmin = false;
      state.isErrorAdmin = false;
      state.errorMessageAdmin = '';
      return state;
    },
  },
  extraReducers: {
    [updateCompany.pending.type]: (state) => {
      state.errorMessageAdmin = '';
      state.isErrorAdmin = false;
      state.isFetchingAdmin = true;
      state.isSuccessAdmin = false;
    },
    [updateCompany.fulfilled.type]: (state) => {
      state.isErrorAdmin = false;
      state.isSuccessAdmin = true;
      state.isFetchingAdmin = false;
      state.errorMessageAdmin = '';
    },
    [updateCompany.rejected.type]: (state, { payload }) => {
      state.isErrorAdmin = true;
      state.isSuccessAdmin = false;
      state.isFetchingAdmin = false;
      state.errorMessageAdmin = payload;
    },

    [updateStudent.pending.type]: (state) => {
      state.errorMessageAdmin = '';
      state.isErrorAdmin = false;
      state.isFetchingAdmin = true;
      state.isSuccessAdmin = false;
    },
    [updateStudent.fulfilled.type]: (state) => {
      state.isErrorAdmin = false;
      state.isSuccessAdmin = true;
      state.isFetchingAdmin = false;
      state.errorMessageAdmin = '';
    },
    [updateStudent.rejected.type]: (state, { payload }) => {
      state.isErrorAdmin = true;
      state.isSuccessAdmin = false;
      state.isFetchingAdmin = false;
      state.errorMessageAdmin = payload;
    },

    [updateCoach.pending.type]: (state) => {
      state.errorMessageAdmin = '';
      state.isErrorAdmin = false;
      state.isFetchingAdmin = true;
      state.isSuccessAdmin = false;
    },
    [updateCoach.fulfilled.type]: (state) => {
      state.isErrorAdmin = false;
      state.isSuccessAdmin = true;
      state.isFetchingAdmin = false;
      state.errorMessageAdmin = '';
    },
    [updateCoach.rejected.type]: (state, { payload }) => {
      state.isErrorAdmin = true;
      state.isSuccessAdmin = false;
      state.isFetchingAdmin = false;
      state.errorMessageAdmin = payload;
    },

    [updateTeacher.pending.type]: (state) => {
      state.errorMessageAdmin = '';
      state.isErrorAdmin = false;
      state.isFetchingAdmin = true;
      state.isSuccessAdmin = false;
    },
    [updateTeacher.fulfilled.type]: (state) => {
      state.isErrorAdmin = false;
      state.isSuccessAdmin = true;
      state.isFetchingAdmin = false;
      state.errorMessageAdmin = '';
    },
    [updateTeacher.rejected.type]: (state, { payload }) => {
      state.isErrorAdmin = true;
      state.isSuccessAdmin = false;
      state.isFetchingAdmin = false;
      state.errorMessageAdmin = payload;
    },
  },
});

export default adminsSlide;

export const { clearState } = adminsSlide.actions;

import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import {clearState, getSettingParticipant} from "../../redux/participant/participant.slide";

Index.propTypes = {
  getQuantity: PropTypes.func,
  response: PropTypes.number,
  type: PropTypes.string,
}
Index.defaultProps = {
  getQuantity: () => {},
  response: null,
  type: '',
}
function Index(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {getQuantity, response, type} = props;
  const {isSuccessParticipant, listParticipants} = useSelector((state) => state.participant);
  const [quantity, setQuantity] = useState(null);
  const [participantQuantity, setParticipantQuantity] = useState();

  useEffect(() => {
    dispatch(getSettingParticipant());

    return () => dispatch(clearState());
  }, []);

  useEffect(() => {
    setParticipantQuantity(type === 'student' ? listParticipants?.data?.number_of_students_participating : listParticipants?.data?.number_of_participants_from_the_organization);
  }, [isSuccessParticipant]);

  useEffect(() => {
    getQuantity(quantity)
  }, [quantity]);

  useEffect(() => {
    setQuantity(response);
  }, [response]);

  return (
    <select value={quantity || ''} id="" className={`select-company col-md-3 mb-4 ${quantity ? 'color-placeholder' : ''}`} onChange={(e) => {setQuantity(parseInt(e.target.value))}} >
      <option value="" disabled hidden>{t('placeholder.person_join')}</option>
      {_.times(participantQuantity, (i) => (
        <option key={i} value={i + 1}>{i +1}人</option>
      ))}
    </select>
  );
}

export default Index;
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {get, post} from "../../services";

const initialState = {
  isFetching: false,
  isSuccessParticipant: false,
  isErrorParticipant: false,
  errorMessage: '',
  response: null,
  currentRequestId: undefined,
  listParticipants: null,
};

export const getSettingParticipant = createAsyncThunk(
  'setting-issues',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'setting-issues';
      const response = await get(url, {}, params);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

export const updateSettingParticipant = createAsyncThunk(
  'setting-issues/update',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'setting-issues/' + params?.id;
      const response = await post(url, params, false);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

const participantsSlide = createSlice({
  name: 'participants',
  initialState,
  reducers: {
    clearState: (state) => {
      state.isErrorParticipant = false;
      state.isFetching = false;
      state.isSuccessParticipant = false;
      state.errorMessage = '';
      state.response = null;
      return state;
    },
  },
  extraReducers: {
    [getSettingParticipant.pending.type]: (state, { meta }) => {
      const { requestId } = meta;
      if (!state.isFetching) {
        state.errorMessage = '';
        state.isErrorParticipant = false;
        state.isFetching = true;
        state.isSuccessParticipant = false;
        state.response = null;
        state.listParticipants = null;
        state.currentRequestId = requestId;
      }
    },
    [getSettingParticipant.fulfilled.type]: (state, { payload, meta }) => {
      const { requestId } = meta;
      if (state.isFetching && state.currentRequestId === requestId) {
        state.isErrorParticipant = false;
        state.isSuccessParticipant = true;
        state.isFetching = false;
        state.errorMessage = '';
        state.response = payload.data;
        state.listParticipants = payload.data;
        state.currentRequestId = undefined;
      }
    },
    [getSettingParticipant.rejected.type]: (state, { payload, meta }) => {
      const { requestId } = meta;
      if (state.isFetching && state.currentRequestId === requestId) {
        state.isErrorParticipant = true;
        state.isSuccessParticipant = false;
        state.isFetching = false;
        state.errorMessage = payload;
        state.response = null;
        state.listParticipants = null;
        state.currentRequestId = undefined;
      }
    },

    [updateSettingParticipant.pending.type]: (state) => {
      state.errorMessage = '';
      state.isErrorParticipantUpdate = false;
      state.isFetching = true;
      state.isSuccessParticipantUpdate = false;
    },
    [updateSettingParticipant.fulfilled.type]: (state) => {
      state.isErrorParticipantUpdate = false;
      state.isSuccessParticipantUpdate = true;
      state.isFetching = false;
      state.errorMessage = '';
    },
    [updateSettingParticipant.rejected.type]: (state, { payload }) => {
      state.isErrorParticipantUpdate = true;
      state.isSuccessParticipantUpdate = false;
      state.isFetching = false;
      state.errorMessage = payload;
    },
  },
});

export default participantsSlide;

export const { clearState } = participantsSlide.actions;

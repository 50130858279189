import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import {Link} from 'react-router-dom';
import './Login.css';
import {useDispatch, useSelector} from "react-redux";
import {clearState, signIn} from "../../redux/auth/auth.slice";
import {typeUser} from "../../constant";
import { useNavigate } from 'react-router-dom';
import Loading from "../../components/Loading";
import PropTypes from "prop-types";
import {clearState as clearStateUser, currentUser} from "../../redux/user/user.slide";

Login.propTypes = {
  typeLogin: PropTypes.string,
}

Login.defaultProps = {
  typeLogin: '',
}
function Login(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [textEmailValidate, setTextEmailValidate] = useState('');
  const [textPassValidate, setTextPassValidate] = useState('');
  const [classErrorEmail, setClassErrorEmail] = useState('input-system w-100');
  const [classErrorPass, setClassErrorPass] = useState('input-system w-100');
  const dispatch = useDispatch();
  const {isFetching, isSuccess, isError, errorMessage} = useSelector((state) => state.auth);
  const {userInfo, response, isSuccessUser, isErrorUser} = useSelector((state) => state.user);
  const { isSignedIn } = useSelector((state) => state.user);
  const {typeLogin} = props;
  const [urlSignUp, setUrlSignUp] = useState('');

  function handleValueChange(e) {
    if (e.target.name === 'email') {
      setEmail(e.target.value.trim());
    }
    if (e.target.name === 'password') {
      setPassword(e.target.value.trim());
    }
  }

  useEffect(() => {
    localStorage.removeItem('is_issue');
    if (typeLogin === typeUser.company) setUrlSignUp('/register-company');
    if (typeLogin === typeUser.coach) setUrlSignUp('/register-coach');
    if (typeLogin === typeUser.student) setUrlSignUp('/register-student');
    if (typeLogin === typeUser.teacher) setUrlSignUp('/register-teacher');
  }, [typeLogin]);

  async function handleSubmit(e) {
    e.preventDefault();
    setTextEmailValidate('');
    setTextPassValidate('');
    setClassErrorEmail('input-system w-100');
    setClassErrorPass('input-system w-100');
    const formValue = {
      email,
      password,
      type: typeLogin,
    }
    dispatch(signIn(formValue))
  }

  useEffect(() => {
    if (isSignedIn) {
      dispatch(currentUser());
    }
    return () => {
      dispatch(clearState());
      dispatch(clearStateUser());
    };
  }, []);

  useEffect(() => {
    if (isSuccessUser) {
      if (response?.data?.role !== typeLogin) {
        return;
      }
      if (response.data.role === typeUser.company) {
        if (response.data?.update_flag) {
          navigate('/company/students');
        } else {
          navigate('/company/update');
        }
      }
      if (response.data.role === typeUser.coach) {
        if (response.data?.update_flag) {
          navigate('/coach');
        } else {
          navigate('/coach/update');
        }
      }
      if (response.data.role === typeUser.student) {
        if (response.data?.update_flag) {
          navigate('/student');
        } else {
          navigate('/student/update');
        }
      }
      if (response.data.role === typeUser.teacher) {
        if (response.data?.update_flag) {
          navigate('/teacher');
        } else {
          navigate('/teacher/update');
        }
      }
      if (response.data.role === typeUser.admin) {
        navigate('/admin');
      }
    }
    if (isErrorUser) {
      localStorage.removeItem('token');
    }

    return () => {
      dispatch(clearStateUser());
    }
  }, [isSuccessUser, isErrorUser]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(clearState());
      const param = new URLSearchParams(window.location.search).get('issueId');
      if (param) {
        navigate('/issues/' + param + '?apply=true');
        return;
      }
      redirectUser(userInfo?.role, userInfo?.update_flag);
      localStorage.setItem('userId', userInfo?.id);
    }
    if (isError) {
      if (errorMessage.status === 500) {
        alert(t('error_system'));
        return;
      }
      setPassword('');
      if (errorMessage.status === 422) {
        if (errorMessage?.data?.errors?.email) {
          setTextEmailValidate(errorMessage?.data?.errors?.email ? errorMessage?.data?.errors?.email[0] : '')
          setClassErrorEmail('input-system w-100 border-error');
        }
        if (errorMessage?.data?.errors?.password) {
          setTextPassValidate(errorMessage?.data?.errors?.password ? errorMessage?.data?.errors?.password[0] : '');
          setClassErrorPass('input-system w-100 border-error');
        }
      }
      if (errorMessage.status === 400) {
        setTextPassValidate(errorMessage?.data?.message ?? '');
        setClassErrorPass('input-system w-100');
      }
      dispatch(clearState());
    }
  }, [isSuccess, isError]);

  const redirectUser = (type, updateFlag = false) => {
    switch (type) {
      case typeUser.company:
        updateFlag ? navigate('/company/students') : navigate('/company/update');
        break;
      case typeUser.coach:
        updateFlag ? navigate('/coach') : navigate('/coach/update');
        break;
      case typeUser.student:
        updateFlag ? navigate('/student') : navigate('/student/update');
        break;
      case typeUser.teacher:
        updateFlag ? navigate('/teacher') : navigate('/teacher/update');
        break;
      case typeUser.admin:
        navigate('/admin');
        break;
      default:
        navigate('/login');
    }
  }

  return (
    <>
      <form className="form-register" onSubmit={handleSubmit}>
        <div className='w-100 d-flex justify-content-start align-items-center mb-4'>
          <div className="title-form font-20">{t('form_login.login')}</div>
          <div className="title-form-sub">{t('form_login.title')}</div>
        </div>
        <div className="group-register group-login">
          <div className="d-flex flex-column align-items-start mb-4 w-100">
            <label className="main-text mb-2">{t('form_login.email')}</label>
            <input type="text" name="email" placeholder={t('form_login.placeholder_email')} className={classErrorEmail} value={email} onChange={handleValueChange} />
            <span className="text-error">{textEmailValidate}</span>
          </div>
          <div className="d-flex flex-column align-items-start w-100">
            <label className="main-text mb-2">{t('password_login')}</label>
            <input type="password" name="password" placeholder={t('password_placeholder')} className={classErrorPass} value={password} onChange={handleValueChange} />
          </div>
          <span className="text-error">{textPassValidate}</span>
        </div>
        <div className='w-100 d-flex justify-content-end mt-4'>
          <button className="button-primary">{t('login')}</button>
        </div>

        <div className='group-line-area w-100 d-flex justify-content-center align-items-center'>
          {typeLogin !== typeUser.admin && <span className='text-line-area'>{t('form_login.forget_password')}</span>}
          <div className='line-area'></div>
        </div>
        <div className='w-100 d-flex justify-content-between align-items-start mt-4'>
          <Link to="/forget-password" className="text-link text-link-highlight">{t('forgot_password')}</Link>
          {![typeUser.admin, typeUser.coach].includes(typeLogin) &&
            <Link to={urlSignUp}>
              <button type='button' className="button-primary">{t('form_login.register')}</button>
            </Link>
          }
        </div>
      </form>
      {isFetching && <Loading />}
    </>
  );
}

export default Login;
import React from 'react';
import collapseMenu from "../../assets/image/expand_menu.svg";
import PropTypes from "prop-types";

HeaderUser.propTypes = {
  user: PropTypes.object,
}
HeaderUser.defaultProps = {
  user: {},
}
function HeaderUser(props) {
  const {user} = props;
  return (
    <div className='header-body-chat'>
      <div className='d-flex justify-content-start align-items-center'>
        <img className='expand-menu-chat mr-2 pt-2' src={collapseMenu} alt="icon" />
        <div className='name-user-body-chat' title={user?.name}>{user?.name}</div>
      </div>
    </div>
  );
}

export default HeaderUser;
import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {Link, useNavigate} from "react-router-dom";
import loginImg from '../../assets/image/login_icon.svg';
import expandMenuImg from '../../assets/image/expand_menu.svg';
import dotSmallImg from '../../assets/image/dot_small.svg';
import './NavBar.css';
import Dropdown from 'react-bootstrap/Dropdown';
import {clearState, currentUser} from "../../redux/user/user.slide";
import {useDispatch, useSelector} from "react-redux";
import {typeUser} from "../../constant";
import imgLogo from '../../assets/image/logo.png'

function NavBar() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isShow, setIsShow] = useState(false);
  const [username, setUsername] = useState('');
  const [urlRedirect, setUrlRedirect] = useState('');
  const dispatch = useDispatch();
  const {isSuccessUser, isErrorUser, response, isSignedIn} = useSelector(state => state.user);

  useEffect(() => {
    if (isSignedIn) {
      dispatch(currentUser());
      return () => dispatch(clearState());
    } else {
      setUsername('');
    }
  }, [isSignedIn]);

  useEffect(() => {
    if (isErrorUser) {
      alert(t('error_system'));
    }
    if (isSuccessUser && isSignedIn) {
      if (response?.data?.role === typeUser.company) setUrlRedirect('company/students');
      if (response?.data?.role === typeUser.coach) setUrlRedirect('/coach');
      if (response?.data?.role === typeUser.student) setUrlRedirect('/student');
      if (response?.data?.role === typeUser.teacher) setUrlRedirect('/teacher');
      if (response?.data?.role === typeUser.admin) setUrlRedirect('/admin');
      setUsername(response?.data?.name ?? response?.data?.email);

    }
  }, [isSuccessUser, isErrorUser]);

  function collapseMenu() {
    setIsShow(!isShow);
  }

  function closeMenu() {
    setIsShow(false);
  }

  const topPage = () => {
    navigate('/');
  }

  return (
    <div className="position-relative">
      <div className="header-top-page header-pc">
        <div className="header-logo">
          <div className="text-logo cursor-pointer" onClick={topPage}>
            <img src={imgLogo}/>
          </div>
        </div>
        <div className="header-navigation">
          <div className="text-header">{t('project_information')}</div>
          <Link to="/register-student" className="text-header">{t('student_registration')}</Link>
          <Link to="/register-teacher" className="text-header">{t('teacher_registration')}</Link>
          <Link to="/register-company" className="text-header">{t('company_registration')}</Link>
          <Link to="https://alcana.jp/org/company/" target="_blank" className="text-header">{t('opera_company')}</Link>
          <Link to="https://alcana.jp/org/contact/" target="_blank" className="text-header">{t('inquiry')}</Link>
          { username !== '' ? <button className="btn-login-page" onClick={() => location.href = urlRedirect}><div className="text-username">{username}</div></button>
            :
            <Dropdown>
              <Dropdown.Toggle className="btn-login-page">
                <div className="text-login">{t('login')}</div>
                <img src={loginImg} className="" alt="logo" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="/student/login" className="text-type-login">{t('type_login.student')}</Dropdown.Item>
                <Dropdown.Item href="/coach/login" className="text-type-login">{t('type_login.coach')}</Dropdown.Item>
                <Dropdown.Item href="/teacher/login" className="text-type-login">{t('type_login.teacher')}</Dropdown.Item>
                <Dropdown.Item href="/company/login" className="text-type-login">{t('type_login.company')}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          }
        </div>

      </div>

      <div className="header-mb">
        <button onClick={collapseMenu} className="btn-collapse-menu"><img src={expandMenuImg} alt="expand-menu"/></button>
        { username !== '' ? <button className="btn-login-page" onClick={() => location.href = urlRedirect}><div className="text-username">{username}</div></button>
          :
          <Dropdown>
            <Dropdown.Toggle className="btn-login-page">
              <div className="text-login">{t('login')}</div>
              <img src={loginImg} className="" alt="logo" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="/student/login" className="text-type-login">{t('type_login.student')}</Dropdown.Item>
              <Dropdown.Item href="/coach/login" className="text-type-login">{t('type_login.coach')}</Dropdown.Item>
              <Dropdown.Item href="/teacher/login" className="text-type-login">{t('type_login.teacher')}</Dropdown.Item>
              <Dropdown.Item href="/company/login" className="text-type-login">{t('type_login.company')}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        }
      </div>
      { isShow ?
        <>
          <div className="sidebar-mb">
            <div className="text-logo"><img src={imgLogo}/></div>
            <div className="items-menu">
              <img src={dotSmallImg} className="mr-2" alt="logo" />
              <div className="text-header">{t('project_information')}</div>
            </div>
            <div className="items-menu">
              <img src={dotSmallImg} className="mr-2" alt="logo" />
              <Link to="/register-student" className="text-header">{t('student_registration')}</Link>
            </div>
            <div className="items-menu">
              <img src={dotSmallImg} className="mr-2" alt="logo" />
              <Link to="/register-teacher" className="text-header">{t('teacher_registration')}</Link>
            </div>
            <div className="items-menu">
              <img src={dotSmallImg} className="mr-2" alt="logo" />
              <Link to="/register-company" className="text-header">{t('company_registration')}</Link>
            </div>
            <div className="items-menu">
              <img src={dotSmallImg} className="mr-2" alt="logo" />
              <div className="text-header">{t('opera_company')}</div>
            </div>
            <div className="items-menu">
              <img src={dotSmallImg} className="mr-2" alt="logo" />
              <div className="text-header">{t('inquiry')}</div>
            </div>
          </div>
          <div onClick={closeMenu} className="sidebar-backdrop"></div>
        </>
         : ''}
    </div>
  );
}
export default NavBar;

import React, {useEffect, useState} from 'react';
import RegisterSuccess from "../../components/Modal/RegisterSuccess";
import Loading from "../../components/Loading";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {typeUser} from '../../constant/index';
import {useDispatch, useSelector} from "react-redux";
import './Register.css';
import {useNavigate} from "react-router-dom";
import {clearState, currentUser, createTeacher, createCompany, createStudent, createCoach} from "../../redux/user/user.slide";
import Notification from "../../components/Modal/Notification";

SignUp.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  formData: PropTypes.object,
  back: PropTypes.func,
};

SignUp.defaultProps = {
  title: '',
  type: typeUser.company,
  formData: {},
  back: null,
};
function SignUp(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const {title, type, formData, back} = props;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailClass, setEmailClass] = useState('input-system w-100');
  const [passClass, setPassClass] = useState('input-system w-100');
  const [emailValidate, setEmailValidate] = useState('');
  const [passValidate, setPassValidate] = useState('');
  const [dataUser, setDataUser] = useState({});
  const {isSuccessUser, isErrorUser, response, isSuccessRegister, isErrorRegister, isFetchingRegister, errorMessageRegister} = useSelector(state => state.user);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (type === typeUser.coach) {
      dispatch(currentUser());
    }
  }, [type]);

  useEffect(() => {
    if (isSuccessUser && type === typeUser.coach) {
      if (response?.data?.role !== typeUser.admin) {
        navigate('/');
      }
    }
    if (isErrorUser) {
      alert(t('error_system'));
    }
  }, [isErrorUser, isSuccessUser]);
  async function handleSubmit(e) {
    e.preventDefault();
    if (formData?.email !== email) {
      setOpenModal(true);
      return;
    }
    setEmailValidate('');
    setEmailClass('input-system w-100');
    setPassValidate('');
    setPassClass('input-system w-100');

    dataUser.email = email;
    dataUser.password = password;
    dataUser.type = type;

    switch (type) {
      case typeUser.teacher:
        dispatch(createTeacher(dataUser));
        break;
      case typeUser.company:
        dispatch(createCompany(dataUser));
        break;
      case typeUser.coach:
        dispatch(createCoach(dataUser));
        break;
      case typeUser.student:
        dispatch(createStudent(dataUser));
        break;
      default:
        return null;
    }
  }

  useEffect(() => {
    setEmail(formData?.email ?? '');
    setDataUser(formData);
  }, [formData]);

  useEffect(() => {
    if (isSuccessRegister) {
      setModal(true);
      setEmail('');
      setPassword('');
    }
    if (isErrorRegister) {
      if (errorMessageRegister?.status === 500) {
        alert(t('error_system'));
        return;
      }
      if (errorMessageRegister?.status === 422) {
        if (errorMessageRegister?.data?.errors?.email?.[0]) {
          setEmailValidate(errorMessageRegister?.data?.errors?.email?.[0] || '');
          setEmailClass('input-system w-100 border-error');
        }
        if (errorMessageRegister?.data?.errors?.password?.[0]) {
          setPassValidate(errorMessageRegister?.data?.errors?.password[0] || '');
          setPassClass('input-system w-100 border-error');
        }
      }
    }

    return () => dispatch(dispatch(clearState()));
  }, [isErrorRegister, isSuccessRegister]);

  function closeModal(value) {
    setModal(value);
    if (type === typeUser.coach) {
      navigate('/admin/coaches');
    } else {
      navigate('/');
    }
  }

  return (
    <>
      <Notification todos={openModal} title={title} content={t('same_email')} closeModal={back} />
      <form className="form-register" onSubmit={handleSubmit}>
        <div>{<RegisterSuccess todos={modal} closeModal={closeModal} title={title} />}</div>
        <div className="title-form">{title}</div>
        <div className="group-register group-register-password">
          <div className="d-flex flex-column align-items-start mb-4 w-100">
            <label className="main-text mb-2">{t('email_input')}</label>
            <input type="text" name="email" placeholder={t('placeholder_email')} className={emailClass} value={email}
              onChange={(e) => {setEmail(e.target.value.trim())}}
              disabled={true}
            />
            <span className="text-error">{emailValidate}</span>
          </div>
          <div className="d-flex flex-column align-items-start mb-4 w-100">
            <label className="main-text mb-2">{t('password')}</label>
            <input type="password" name="password" placeholder={t('password_placeholder')} className={passClass}
                   value={password} onChange={(e) => setPassword(e.target.value)}/>
            <span className="text-error">{passValidate}</span>
          </div>
        </div>
        <div className='d-flex justify-content-center align-items-center'>
          <button type='button' className="button-secondary mr-2" onClick={back}>{t('user_info.back')}</button>
          <button className="button-primary">{t('register')}</button>
        </div>
      </form>
      {isFetchingRegister && <Loading />}
    </>
  );
}

export default SignUp;
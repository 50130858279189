import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import LikeIcon from '../../assets/image/like_icon.svg';
import LikeActiveIcon from '../../assets/image/like_active_icon.svg';
import {useDispatch, useSelector} from "react-redux";
import {clearState, listCriteria} from "../../redux/criteria/criteria.slide";
import nl2br from "react-nl2br";

EvaluateDetail.propTypes = {
  todos: PropTypes.bool,
  closeModal: PropTypes.func,
  response: PropTypes.any,
};
EvaluateDetail.defaultProps = {
  todos: false,
  closeModal: null,
  response: null,
};
function EvaluateDetail(props) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const { todos, closeModal, response } = props;
  const {isSuccess, isError, listCriterias} = useSelector(state => state.criteria);
  const [criterias, setCriterias] = useState([]);
  const [criteriaArr, setCriteriaArr] = useState([]);
  const [comment, setComment] = useState('');

  useEffect(() => {
    dispatch(listCriteria());

    return () => dispatch(clearState());
  }, []);

  useEffect(() => {
    setCriteriaArr(Object.keys(response?.criteria ?? {}));
    setComment(response?.comment);
  }, [response]);

  useEffect(() => {
    if (isError) {
      alert(t('error_system'));
    }
    if (isSuccess) {
      setCriterias(Object.entries(listCriterias?.data?.data ?? {}));
    }

    return () => dispatch(clearState());
  }, [isSuccess, isError]);

  return (
    <Modal show={todos} centered className='modal-evaluate modal-evaluate-detail'>
      <div className='evaluate-content'>
        <div className='title-evaluate'>{t('evaluate.show')}</div>
        <div className='rate-block'>
          {criterias.map(criteria => (
            <div key={criteria?.[0]} className='vote-rate'>
              <img src={criteriaArr.includes(criteria?.[0]) ? LikeActiveIcon : LikeIcon} alt="icon" className='icon-vote-rate' style={{cursor: 'auto'}} />
              <div className='text-vote'>{criteria?.[1]}</div>
            </div>
          ))}
        </div>
        <div className='title-comment-block'>
          <div className='parent-title-comment'>
            <div className='title-comment'>{t('evaluate.comment')}</div>
          </div>
          <div className='comment-evaluate detail-comment-evaluate'>{nl2br(comment)}</div>
        </div>
        <div className='btn-evaluate-block'>
          <button className='button-secondary' onClick={() => closeModal(!todos)}>{t('register_company.modal_button')}</button>
        </div>
      </div>
    </Modal>
  );
}

export default EvaluateDetail;
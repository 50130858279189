import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './contexts/i18n';
import './App.css';
import PublicRouter from "./router/PublicRouter";
import Layout from "./containers/layout";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Login from './containers/Auth/Login';
import PrivateRouter from "./router/PrivateRouter";
import UpdateCompany from "./containers/Company/UpdateCompany";
import ForgetPassword from "./containers/ResetPassword/ForgetPassword";
import ResetPassword from "./containers/ResetPassword";
import Coach from "./containers/Company/Coach";
import Student from "./containers/Company/Student";
import CompleteSignUp from "./containers/Auth/CompleteSignUp";
import AddIssueCompany from "./containers/Company/Issue/AddIssue";
import UpdateCoach from "./containers/Coach/Update";
import UpdateStudent from "./containers/Student/Update";
import DetailStudent from "./containers/Student/Detail";
import DetailCoach from "./containers/Coach/Detail";
import Message from "./containers/Message";
import MyPage from './containers/MyPage/index';
import NotFound from "./components/404";
import {typeUser, manager} from "./constant";
import IssueDetail from './containers/Issue/Detail';
import UpdateTeacher from './containers/Teacher/Update';
import Admin from './containers/Admin';
import UpdateUser from './containers/Admin/UpdateUser';
import UpdateIssue from './containers/Admin/UpdateIssue';
import MyIssue from './containers/Company/Issue/MyIssue';
import UpdateEmail from "./containers/Auth/UpdateEmail";
import Register from './containers/Auth/Register';
function App() {
  return (
    <Routes>
      <Route element={<PublicRouter />}>
        <Route path='/' element={<Layout />} />
        <Route path='/register-company' element={<Register type={typeUser.company} />} />
        <Route path='/register-student' element={<Register type={typeUser.student} />} />
        <Route path='/register-teacher' element={<Register type={typeUser.teacher} />} />
        <Route path='/register/complete' element={<CompleteSignUp />} />
        <Route path='/company/login' element={<Login typeLogin={typeUser.company} />} />
        <Route path='/coach/login' element={<Login typeLogin={typeUser.coach} />} />
        <Route path='/student/login' element={<Login typeLogin={typeUser.student} />} />
        <Route path='/teacher/login' element={<Login typeLogin={typeUser.teacher} />} />
        <Route path='/admin/login' element={<Login typeLogin={typeUser.admin} />} />
        <Route path='/forget-password' element={<ForgetPassword />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='/update-email' element={<UpdateEmail />} />
        <Route path='/issues/:id' element={<IssueDetail />} />
        <Route path='*' element={<NotFound />} />
      </Route>
      <Route element={<PrivateRouter />}>
        <Route path='/register-coach' element={<Register type={typeUser.coach} />} />
        <Route path='/company/update' element={<UpdateCompany />} />
        <Route path='/company/coaches' element={<Coach />} />
        <Route path='/company/students' element={<Student />} />
        <Route path='/company/students/:id' element={<DetailStudent />} />
        <Route path='/company/coaches/:id' element={<DetailCoach />} />
        <Route path='/company/add-issue' element={<AddIssueCompany />} />
        <Route path='/company/my-issue' element={<MyIssue type={typeUser.company} />} />
        <Route path='/coach/update' element={<UpdateCoach />} />
        <Route path='/student/update' element={<UpdateStudent />} />
        <Route path='/teacher/update' element={<UpdateTeacher />} />
        <Route path='/student' element={<MyPage type={typeUser.student} />} />
        <Route path='/student/issue-apply' element={<MyIssue type={typeUser.student} />} />
        <Route path='/coach' element={<MyPage type={typeUser.coach} />} />
        <Route path='/coach/issue-apply' element={<MyIssue type={typeUser.coach} />} />
        <Route path='/teacher' element={<MyPage type={typeUser.teacher} />} />
        <Route path='/list-issue-draft' element={<div>list issue draft</div>} />
        <Route path='/edit-issue' element={<div>edit issue</div>} />
        <Route path='/message' element={<Message isOverview={true} />} />
        <Route path='/message/:id' element={<Message isOverview={false} />} />
        <Route path='/admin'>
          <Route path='' element={<Admin type={manager.default} />} />
          <Route path='companies' element={<Admin type={manager.company} />} />
          <Route path='students' element={<Admin type={manager.student} />} />
          <Route path='coaches' element={<Admin type={manager.coach} />} />
          <Route path='teachers' element={<Admin type={manager.teacher} />} />
          <Route path='users/:id' element={<UpdateUser />} />
          <Route path='issues' element={<Admin type={manager.issue} />} />
          <Route path='issues/:id' element={<UpdateIssue />} />
          <Route path='genders' element={<Admin type={manager.gender} />} />
          <Route path='setting-issue' element={<Admin type={manager.quantity_participant} />} />
          <Route path='student-objects' element={<Admin type={manager.object_student} />} />
          <Route path='school-types' element={<Admin type={manager.school_type} />} />
          <Route path='majors' element={<Admin type={manager.major} />} />
          <Route path='setting-school-years' element={<Admin type={manager.school_year} />} />
          <Route path='categories' element={<Admin type={manager.category} />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;

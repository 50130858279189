import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from "prop-types";
import {useTranslation} from 'react-i18next';
import {Link} from "react-router-dom";

ModalUsers.propTypes = {
  todos: PropTypes.bool,
  closeModal: PropTypes.func,
  users: PropTypes.array,
};

ModalUsers.defaultProps = {
  todos: false,
  closeModal: null,
  users: [],
};

function ModalUsers(props) {
  const {t} = useTranslation();
  const { todos, closeModal, users } = props;
  return (
    <div>
      <Modal show={todos} centered className='modal-list-user-apply'>
        <div className='group-user-apply'>
          <div className='data-application'>{t('message.data_application')}</div>
          <div className='student-name'>{t('message.name_student')}</div>
          <div className='list-users-apply'>
            {users.length === 0 && <div className='no-data'>{t('no_data')}</div>}
            {users.map(user => (
              <Link key={user?.id} to={'/company/students/' + user?.id}>
                <span className='text-name-apply'>{user?.name}（{user?.email}）</span>
              </Link>
            ))}
          </div>
          <Button variant="primary" className='btn-close-list-user-apply' onClick={() => closeModal(!todos)}>
            {t('register_company.modal_button')}
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default ModalUsers;
import React from 'react';
import NavBar from "../../components/NavBar";
import SlideImage from './SlideImage';
import './TopPage.css';
import Feature from "./Feature";
import PickUp from "./PickUp";
import Loading from "../../components/Loading";
import {useSelector} from "react-redux";

export default function TopPage() {
  const {isFetching} = useSelector((state) => state.issue);
  return (
    <>
      <div className="container container-app">
        <NavBar />
        <SlideImage />
        <Feature />
        <PickUp />
      </div>
      {isFetching && <Loading />}
    </>
  );
}
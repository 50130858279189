import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
import {clearState, statusIssue as getStatusIssue} from "../../redux/issue/issue.slide";
import {getStatusIssue as getStatusIssueName} from '../../helper/index';

Index.propTypes = {
  getIssueStatus: PropTypes.func,
  response: PropTypes.string,
  type: PropTypes.string,
}
Index.defaultProps = {
  getLevelStudent: () => {},
  response: '',
  type: '',
}
function Index(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {getIssueStatus, response, type} = props;
  const {listStatusIssue, isSuccessStatusIssue} = useSelector(state => state.issue);
  const [issueStatus, setIssueStatus] = useState([]);
  const [statusIssue, setStatusIssue] = useState(null);

  useEffect(() => {
    dispatch(getStatusIssue());

    return () => dispatch(clearState());
  }, []);

  useEffect(() => {
    if (isSuccessStatusIssue) {
      setIssueStatus(listStatusIssue?.data?.data ?? []);
    }

  }, [isSuccessStatusIssue]);

  useEffect(() => {
    getIssueStatus(statusIssue)
  }, [statusIssue]);

  useEffect(() => {
    setStatusIssue(response);
  }, [response]);

  return (
    <select value={statusIssue || ''} id="" className={`select-company ${type === 'search' ? '' : 'col-md-3'} ${statusIssue ? 'color-placeholder' : ''}`} onChange={(e) => {setStatusIssue(e.target.value)}} >
      <option value="" disabled hidden>{t('please_choose')}</option>
      {type === 'search' &&
        <option value="">{t('please_choose')}</option>
      }
      {issueStatus.map((val, index) => (
        <option key={index} value={val}>{getStatusIssueName(val)}</option>
      ))}
    </select>
  );
}

export default Index;
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import ConfirmDelete from "../../components/Modal/ConfirmDelete";
import ModalListCoach from '../../components/Modal/List';
import ModalInfoUser from '../../components/Modal/Info';
import {useDispatch, useSelector} from "react-redux";
import {clearState, listIssue} from "../../redux/issue/issue.slide";
import {getStatusIssue} from "../../helper";
import PropTypes from "prop-types";
import {issueDelete} from "../../redux/admin/manage-issue.slide";
import Notification from "../../components/Modal/Notification";
import {userInfo} from "../../redux/admin/manage.slide";
import {Link} from "react-router-dom";
import {paginationAdmin} from "../../constant";
import Pagination from 'react-paginate';

ListIssues.propTypes = {
  setLoading: PropTypes.func,
}
ListIssues.defaultProps = {
  setLoading: null,
}
function ListIssues(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {setLoading} = props;
  const [issues, setIssues] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [name, setName] = useState('');
  const [openModalList, setOpenModalList] = useState(false);
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const {isSuccessIssue, isErrorIssue, errorMessage, listIssues} = useSelector(state => state.issue);
  const [keyword, setKeyWord] = useState('');
  const [issueId, setIssueId] = useState(null);
  const {isErrorDelete, isSuccessDelete} = useSelector(state => state.managerIssue);
  const [openModalNotification, setOpenModalNotification] = useState(false);
  const [contentDelete, setContentDelete] = useState('');
  const [coachJoinId, setCoachJoinId] = useState(null);
  const {isSuccess, isError, responseUser, errorMessageUser} = useSelector(state => state.managerUser);
  const [coach, setCoach] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    setLoading(true);
    dispatch(listIssue({paginate: paginationAdmin,}));
    return () => dispatch(clearState());
  }, []);

  useEffect(() => {
    if (isErrorIssue) {
      if (errorMessage?.status === 500) {
        alert(t('error_system'));
      }
      setLoading(false);
    }
    if (isSuccessIssue) {
      setIssues(listIssues?.data?.data ?? []);
      setCurrentPage(listIssues?.data?.meta.current_page);
      setTotalPages(listIssues?.data?.meta?.last_page);
      setLoading(false);
    }

    return () => dispatch(clearState());
  }, [isSuccessIssue, isErrorIssue]);

  const handlePageChange = (pageNumber) => {
    setLoading(true);
    dispatch(listIssue({
      page: pageNumber?.selected + 1,
      theme_name: keyword,
      paginate: paginationAdmin,
    }));
  }

  useEffect(() => {
    closeModal(false);
    if (isErrorDelete) {
      setOpenModalNotification(true);
      setContentDelete(t('admin.issue.delete_error'));
      setLoading(false);
    }
    if (isSuccessDelete) {
      setOpenModalNotification(true);
      setContentDelete(t('admin.issue.delete_success'));
      setLoading(false);
    }
  }, [isErrorDelete, isSuccessDelete]);

  useEffect(() => {
    if (isError && errorMessageUser?.status === 500) {
      setLoading(false);
      alert(t('error_system'));
    }
    if (isSuccess) {
      setLoading(false);
      setCoach(responseUser?.data);
    }
  }, [isSuccess, isError]);

  const searchIssue = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(listIssue({
      paginate: paginationAdmin,
      theme_name: keyword
    }));
  }

  const closeModalNotification = (value) => {
    setOpenModalNotification(value);
    location.reload();
  }

  const closeModal = (value) => {
    setOpenModal(value);
  }

  const closeModalList = (value) => {
    setOpenModalList(value);
  }

  const closeModalInfo = (value) => {
    setOpenModalInfo(value);
  }

  const handleDelete = () => {
    setLoading(true);
    dispatch(issueDelete({id: issueId}));
  }

  const showInfoCoach = (id) => {
    setLoading(true);
    setOpenModalInfo(true);
    dispatch(userInfo({id}));
  }

  return (
    <>
      <div className='manager-block'>
        <div>
          <div className='title-manager'>{t('admin.manager_issue')}</div>
          <form onSubmit={searchIssue} className='d-flex justify-content-end align-items-center w-100 mb-3'>
            <div className='mr-3 main-text'>{t('admin.company.title_search')}</div>
            <input type="text" className='input-system mr-3 w-40' placeholder={t('admin.issue.placeholder_search')} maxLength={500} onChange={(e) => setKeyWord(e.target.value.trim())} />
            <button className='btn-save-company'>{t('admin.company.btn_search')}</button>
          </form>
          <table className='table table-manager'>
            <thead>
            <tr>
              <th className='w-20'>{t('admin.issue.name')}</th>
              <th className='w-20'>{t('admin.issue.status')}</th>
              <th className='w-20'>{t('admin.issue.coach')}</th>
              <th className='w-20'>{t('admin.issue.coach_selected')}</th>
              <th className='th__action'></th>
            </tr>
            </thead>
            <tbody>
            {issues.length === 0 && <tr><td colSpan={5} className="no-data">{t('no_data')}</td></tr>}
            {issues.map((issue) => (
              <tr key={issue?.id}>
                <td>{issue?.theme_name}</td>
                <td>{getStatusIssue(issue?.status)}</td>
                <td>
                  <div className='d-flex justify-content-center align-items-center'>
                    <button className={issue?.status === 'end' || issue?.status === 'temp' ? 'btn-action-manager btn-choose-coach btn-disabled' : 'btn-action-manager btn-choose-coach'}
                            onClick={() => {setOpenModalList(true); setCoachJoinId(issue?.coach?.[0]?.id); setIssueId(issue?.id)}}
                            disabled={issue?.status === 'end' || issue?.status === 'temp'}
                    >
                      {issue?.coach.length ? t('admin.issue.choose_coach') : t('admin.issue.change_coach')}
                    </button>
                  </div>
                </td>
                <td>{issue?.coach?.[0]?.name}</td>
                <td>
                  <div className='d-flex justify-content-center align-items-center'>
                    <Link to={'/admin/issues/' + issue?.id}>
                      <button className='btn-action-manager btn-edit-manager'>{t('admin.edit')}</button>
                    </Link>
                    <button className='btn-action-manager btn-delete-manager' onClick={() => {setOpenModal(true); setName(issue?.theme_name); setIssueId(issue?.id)}}>{t('admin.delete')}</button>
                  </div>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
        {totalPages > 1 &&
          <Pagination
            containerClassName='pagination justify-content-center'
            pageLinkClassName='page-link'
            breakClassName="page-item"
            breakLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            activeClassName="active"
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageChange}
            pageCount={totalPages}
            previousLabel="<"
            renderOnZeroPageCount={null}
            forcePage={currentPage - 1}
          />
        }
      </div>
      <ModalListCoach todos={openModalList} closeModal={closeModalList} showInfo={showInfoCoach} coachId={coachJoinId} issueId={issueId} />
      <ConfirmDelete
        title={t('admin.issue.title_delete')}
        todos={openModal}
        content={t('admin.issue.content_delete')}
        closeModal={closeModal}
        name={name}
        email=''
        handleDelete={handleDelete}
        nameTitle={t('admin.issue.name')}
      />
      <ModalInfoUser todos={openModalInfo} closeModal={closeModalInfo} response={coach} />
      <Notification todos={openModalNotification} title={t('admin.issue.title_delete')} content={contentDelete} closeModal={closeModalNotification} />
    </>
  );
}

export default ListIssues;
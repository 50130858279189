import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {get, post} from "../../services";

const initialState = {
  isFetching: false,
  isSuccessIssue: false,
  isErrorIssue: false,
  errorMessage: '',
  currentRequestId: undefined,
  listIssues: null,
  issueInfo: null,
  listStatusIssue: null,
  isSuccessJoin: false,
  isErrorJoin: false,
  isErrorComplete: false,
  isSuccessComplete: false,
};

export const listIssue = createAsyncThunk(
  'issues',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'issues';
      const response = await get(url, {}, params);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

export const detailIssue = createAsyncThunk(
  'issues/detail',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'issues/' + params?.id;
      const response = await get(url, {}, params);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

export const statusIssue = createAsyncThunk(
  'issues/status',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'status-issues';
      const response = await get(url, {}, params);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

export const studentJoin = createAsyncThunk(
  'issues/joined-by-student',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'issues/' + params?.id + '/joined-by-student';
      const response = await post(url, params, false);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

export const completeIssue = createAsyncThunk(
  'issues/complete',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'issues/' + params?.id + '/update-status';
      const response = await post(url, params, false);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

const issuesSlide = createSlice({
  name: 'issues',
  initialState,
  reducers: {
    clearState: (state) => {
      state.isErrorIssue = false;
      state.isFetching = false;
      state.isSuccessIssue = false;
      state.errorMessage = '';
      state.errorMessageIssueUpdate = '';
      state.listIssues = null;
      state.issueInfo = null;
      state.isErrorJoin = false;
      state.isSuccessJoin = false;
      state.isErrorComplete = false;
      state.isSuccessComplete = false;
      return state;
    },
  },
  extraReducers: {
    [listIssue.pending.type]: (state) => {
      state.errorMessage = '';
      state.isErrorIssue = false;
      state.isFetching = true;
      state.isSuccessIssue = false;
      state.listIssues = null;
    },
    [listIssue.fulfilled.type]: (state, { payload }) => {
      state.isErrorIssue = false;
      state.isSuccessIssue = true;
      state.isFetching = false;
      state.errorMessage = '';
      state.listIssues = payload.data;
    },
    [listIssue.rejected.type]: (state, { payload }) => {
      state.isErrorIssue = true;
      state.isSuccessIssue = false;
      state.isFetching = false;
      state.errorMessage = payload;
      state.listIssues = null;
    },

    [detailIssue.pending.type]: (state) => {
      state.errorMessage = '';
      state.isErrorIssue = false;
      state.isFetching = true;
      state.isSuccessIssue = false;
      state.issueInfo = null;
    },
    [detailIssue.fulfilled.type]: (state, { payload }) => {
      state.isErrorIssue = false;
      state.isSuccessIssue = true;
      state.isFetching = false;
      state.errorMessage = '';
      state.issueInfo = payload.data;
    },
    [detailIssue.rejected.type]: (state, { payload }) => {
      state.isErrorIssue = true;
      state.isSuccessIssue = false;
      state.isFetching = false;
      state.errorMessage = payload;
      state.issueInfo = null;
    },

    [statusIssue.pending.type]: (state) => {
      state.errorMessage = '';
      state.isErrorStatusIssue = false;
      state.isFetching = true;
      state.isSuccessStatusIssue = false;
      state.listStatusIssue = null;
    },
    [statusIssue.fulfilled.type]: (state, {payload}) => {
      state.isErrorStatusIssue = false;
      state.isSuccessStatusIssue = true;
      state.isFetching = false;
      state.errorMessage = '';
      state.listStatusIssue = payload;
    },
    [statusIssue.rejected.type]: (state, { payload }) => {
      state.isErrorStatusIssue = true;
      state.isSuccessStatusIssue = false;
      state.isFetching = false;
      state.errorMessage = payload;
      state.listStatusIssue = null;
    },

    [studentJoin.pending.type]: (state) => {
      state.errorMessage = '';
      state.isErrorJoin = false;
      state.isFetching = true;
      state.isSuccessJoin = false;
    },
    [studentJoin.fulfilled.type]: (state) => {
      state.isErrorJoin = false;
      state.isSuccessJoin = true;
      state.isFetching = false;
      state.errorMessage = '';
    },
    [studentJoin.rejected.type]: (state, { payload }) => {
      state.isErrorJoin = true;
      state.isSuccessJoin = false;
      state.isFetching = false;
      state.errorMessage = payload;
    },

    [completeIssue.pending.type]: (state) => {
      state.errorMessage = '';
      state.isErrorComplete = false;
      state.isFetching = true;
      state.isSuccessComplete = false;
    },
    [completeIssue.fulfilled.type]: (state) => {
      state.isErrorComplete = false;
      state.isSuccessComplete = true;
      state.isFetching = false;
      state.errorMessage = '';
    },
    [completeIssue.rejected.type]: (state, { payload }) => {
      state.isErrorComplete = true;
      state.isSuccessComplete = false;
      state.isFetching = false;
      state.errorMessage = payload;
    },
  },
});

export default issuesSlide;

export const { clearState } = issuesSlide.actions;

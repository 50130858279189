import apiClient from './api-client';

// Base function for GET requests
const get = async (route, user, params = {}) => {
  const headers = {
    Accept: 'application/json',
  };

  if (user?.token) {
    headers.Authorization = `Bearer ${user.token}`;
  } else {
    const token = localStorage.getItem('token');
    headers.Authorization = `Bearer ${token}`;
  }

  return apiClient({
    method: 'get',
    url: route,
    headers,
    params,
  });
};

// Base function for POST requests
const post = async (route, data, file = false) => {
  const headers = {
    Accept: 'application/json',
  };
  if (file) {
    headers['Content-Type'] = 'multipart/form-data';
  }
    const token = localStorage.getItem('token');
    headers.Authorization = `Bearer ${token}`;

  return apiClient({
    method: 'post',
    url: route,
    headers,
    data,
  });
};

const deleteMethod = async (route, data) => {
  const headers = {
    Accept: 'application/json',
  };
  const token = localStorage.getItem('token');
  headers.Authorization = `Bearer ${token}`;

  return apiClient({
    method: 'delete',
    url: route,
    headers,
    data,
  });
};

export { get, post, deleteMethod };

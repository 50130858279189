import React from 'react';
import coachImg1 from '../../assets/image/coach_img_1.svg';
import coachImg2 from '../../assets/image/coach_img_2.svg';
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {typeUser} from "../../constant";

BannerCoach.propTypes = {
  title: PropTypes.string,
  des: PropTypes.string,
  type: PropTypes.string,
}

BannerCoach.defaultProps = {
  title: '',
  des: '',
  type: ''
}
function BannerCoach(props) {
  const { t } = useTranslation();
  const { title, des, type } = props;

  return (
    <div className="banner-my-page">
      <img src={coachImg1} alt="image"/>
      <div className="group-text-banner">
        <div className="list-coach">{title}</div>
        <div className="btn-for-company">{ type === typeUser.teacher ? '' : des || t('coach.for_company')}</div>
      </div>
      <img src={coachImg2} alt="image"/>
    </div>
  );
}

export default BannerCoach;
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {get} from "../../services";

const initialState = {
  isFetching: false,
  errorMessage: '',
  isSuccess: false,
  isError: false,
  listCriterias: null,
};

export const listCriteria = createAsyncThunk(
  'criteria/list',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'criteria';
      const response = await get(url, {}, params);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

const criteriaSlide = createSlice({
  name: 'criteria',
  initialState,
  reducers: {
    clearState: (state) => {
      state.isFetching = false;
      state.errorMessage = '';
      state.isSuccess = null;
      state.isError = false;
      state.listCriterias = false;
      return state;
    },
  },
  extraReducers: {
    [listCriteria.pending.type]: (state) => {
      state.errorMessage = '';
      state.isSuccess = false;
      state.isFetching = true;
      state.isError = false;
      state.listCriterias = null;
    },
    [listCriteria.fulfilled.type]: (state, { payload }) => {
      state.isError = false;
      state.isSuccess = true;
      state.isFetching = false;
      state.errorMessage = '';
      state.listCriterias = payload;
    },
    [listCriteria.rejected.type]: (state, { payload }) => {
      state.isError = true;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = payload;
      state.listCriterias = null;
    },
  },
});

export default criteriaSlide;

export const { clearState } = criteriaSlide.actions;

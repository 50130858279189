import React, {useEffect, useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {clearState, listUser} from "../../redux/user/user.slide";
import {paginationAdmin, typeUser} from "../../constant";
import Pagination from 'react-paginate';
import {clearState as clearStateJoin, coachJoin} from "../../redux/admin/manage-issue.slide";
import Notification from "./Notification";

List.propTypes = {
  todos: PropTypes.bool,
  closeModal: PropTypes.func,
  showInfo: PropTypes.func,
  coachId: PropTypes.number,
  issueId: PropTypes.number,
};

List.defaultProps = {
  todos: false,
  closeModal: null,
  showInfo: null,
  coachId: null,
  issueId: null,
};

function List(props) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const { todos, closeModal, showInfo, coachId, issueId } = props;
  const [users, setUsers] = useState([]);
  const {isSuccess, isError, listUsers, errorMessage} = useSelector((state) => state.user);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [keyWord, setKeyWord] = useState('');
  const {isErrorJoin, isSuccessJoin} = useSelector(state => state.managerIssue);
  const [openModal, setOpenModal] = useState(false);
  const [msgJoin, setMsgJoin] = useState('');

  useEffect(() => {
    if (todos) {
      dispatch(listUser({
        paginate: paginationAdmin,
        role: typeUser.coach,
      }));
      return () => dispatch(clearState());
    }
  }, [todos]);

  useEffect(() => {
    if (isError && errorMessage?.status === 500) {
      alert(t('error_system'));
    }
    if (isSuccess) {
      setUsers(listUsers?.data?.data ?? []);
      setCurrentPage(listUsers?.data?.meta.current_page);
      setTotalPages(listUsers?.data?.meta?.last_page);
    }
  }, [isSuccess, isError]);

  const handlePageChange = (pageNumber) => {
    dispatch(listUser({
      page: pageNumber?.selected + 1,
      name: keyWord,
      paginate: paginationAdmin,
      role: typeUser.coach,
    }));
  }

  const searchUser = (e) => {
    e.preventDefault();
    dispatch(listUser({
      name: keyWord,
      paginate: paginationAdmin,
      role: typeUser.coach,
    }));
  }

  const join = (id) => {
    dispatch(coachJoin({id: issueId, user_id: id}));
  }

  useEffect(() => {
    closeModal(false);
    if (isSuccessJoin) {
      setMsgJoin(t('admin.issue.join_error'));
      setOpenModal(true);
    }
    if (isSuccessJoin) {
      setMsgJoin(t('admin.issue.join_success'));
      setOpenModal(true);
    }

    return () => dispatch(clearStateJoin());
  }, [isErrorJoin, isSuccessJoin]);

  const closeModalNotification = () => {
    setOpenModal(false);
    location.reload();
  }

  return (
    <div>
      <Modal show={todos} centered className='modal-list-user'>
        <Modal.Header>
          <Modal.Title>
            <div className="title-form">{t('admin.list_coach')}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={searchUser} className='d-flex justify-content-end align-items-center w-100 mb-2'>
            <div className='mr-3 main-text'>{t('admin.company.title_search')}</div>
            <input type="text" className='input-system mr-3 w-60' placeholder={t('admin.company.placeholder_search')} maxLength={500} onChange={(e) => setKeyWord(e.target.value)} />
            <button className='btn-save-company'>{t('admin.company.btn_search')}</button>
          </form>
          <table className='table table-manager'>
            <thead>
            <tr>
              <th className='w-50'>{t('admin.name_coach')}</th>
              <th></th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            {users.length === 0 && <tr><td colSpan={3} className="no-data">{t('no_data')}</td></tr>}
            {users.map(user => (
              <tr key={user?.id}>
                <td>{user?.name}</td>
                <td>{coachId !== user?.id && <button className='btn-switch-choose-coach' onClick={() => join(user?.id)}>{t('admin.choose')}</button>}</td>
                <td><div onClick={() => showInfo(user?.id)} className='view-profile'>{t('admin.view_profile')}</div></td>
              </tr>
            ))}
            </tbody>
          </table>
          {totalPages > 1 &&
            <Pagination
              containerClassName='pagination justify-content-center unset-position'
              pageLinkClassName='page-link'
              breakClassName="page-item"
              breakLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              activeClassName="active"
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageChange}
              pageCount={totalPages}
              previousLabel="<"
              renderOnZeroPageCount={null}
              forcePage={currentPage - 1}
            />
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => closeModal(!todos)}>{t('close')}</Button>
        </Modal.Footer>
      </Modal>

      <Notification todos={openModal} title={t('admin.issue.title_join')} content={msgJoin} closeModal={closeModalNotification} />
    </div>
  );
}

export default List;
import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import {clearState, listIssue} from "../../../redux/issue/issue.slide";
import imageDefault from '../../../assets/image/issue_default.svg';
import {getTypeIssue} from "../../../helper";
import {Link} from "react-router-dom";
import {issueStatus} from "../../../constant";
import soundImg from "../../../assets/image/sound.svg";
import '../../../containers/MyPage/index.css';

export default function PickUp() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {listIssues} = useSelector((state) => state.issue);
  const [issues, setIssues] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const statusArr = [issueStatus.start, issueStatus.end, issueStatus.open, issueStatus.complete];
  useEffect(() => {
    dispatch(listIssue({status: statusArr}));
    return () => dispatch(clearState());
  },[]);

  useEffect(() => {
    if (listIssues) {
      if (listIssues?.data?.meta) {
        const newData = [...issues, ...listIssues?.data?.data ?? []];
        if (isLoadMore) {
          setIssues(newData);
        } else {
          setIssues(listIssues?.data?.data ?? []);
        }
        setLastPage(listIssues?.data.meta.last_page);
        setCurrentPage(listIssues?.data.meta.current_page);
      }
    }
  }, [listIssues]);

  const loadMore = () => {
    setIsLoadMore(true);
    if (currentPage < lastPage) {
      dispatch(listIssue({page: currentPage + 1, status: statusArr}));
    }
  }


  return (
      <div className="result-search-block" style={{backgroundColor: '#f1f2f6'}}>
        <div className="title-part-top-page mb-3">
          <div className="text-service mr-3">{t('pick_up')}</div>
          <div className="text-service-feature">{t('project_pick_up')}</div>
        </div>
        <div className="list-course">
          {issues.length === 0 && <div className="no-data">{t('no_data_issue')}</div>}
          {issues.map((issue) => (
            <div key={issue?.id} className='course-items items-issue'>
              {issue?.avatar ?
                <img className='image-pick-up' src={issue?.avatar} alt="image"/> :
                <img className='image-pick-up pick-up-default' src={imageDefault} alt="image"/>
              }
              <div className='header-course'>
                <div className='d-flex justify-content-start align-items-center'>
                  <div className='type-issue-tag mr-3'>
                    <img src={soundImg} alt="icon" className='mr-2'/>
                    <div className='type-issue'>{getTypeIssue(issue?.is_remote, issue?.is_face_to_face)}</div>
                  </div>
                </div>
                <div className='quantity-student-apply'>{issue?.remain_date > 0 ? 'あと' + issue?.remain_date + '日' : issue?.remain_date === 0 ? t('last_day') : ''}</div>
              </div>
              <div className='info-course'>
                <div className='title-course' title={issue?.theme_name}>{issue?.theme_name}</div>
                <div className='w-100 d-flex justify-content-center'>
                  <Link to={'/issues/' + issue?.id} >
                    <button className='btn-detail-issue'>{t('see_more')}</button>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
        {currentPage < lastPage && <button className="btn-see-more mt-4" onClick={loadMore}>{t('see_more_text')}</button>}
      </div>
  );
}
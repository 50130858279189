import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {get} from "../../services";

const initialState = {
  isFetching: false,
  isSuccessSchool: false,
  isErrorSchool: false,
  errorMessage: '',
  listTypeSchools: null,
};

export const listTypeSchool = createAsyncThunk(
  'type-of-schools',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'type-of-schools';
      const response = await get(url, params);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

const typeSchoolsSlide = createSlice({
  name: 'majors',
  initialState,
  reducers: {
    clearState: (state) => {
      state.isErrorSchool = false;
      state.isFetching = false;
      state.isSuccessSchool = false;
      state.errorMessage = '';
      state.listTypeSchools = null;
      return state;
    },
  },
  extraReducers: {
    [listTypeSchool.pending.type]: (state) => {
      if (!state.isFetching) {
        state.errorMessage = '';
        state.isErrorSchool = false;
        state.isFetching = true;
        state.isSuccessSchool = false;
        state.listTypeSchools = null;
      }
    },
    [listTypeSchool.fulfilled.type]: (state, { payload }) => {
        state.isErrorSchool = false;
        state.isSuccessSchool = true;
        state.isFetching = false;
        state.errorMessage = '';
        state.currentRequestId = undefined;
        state.listTypeSchools = payload.data;
    },
    [listTypeSchool.rejected.type]: (state, { payload }) => {
      if (state.isFetching) {
        state.isErrorSchool = true;
        state.isSuccessSchool = false;
        state.isFetching = false;
        state.errorMessage = payload;
        state.currentRequestId = undefined;
        state.listTypeSchools = null;
      }
    },
  },
});

export default typeSchoolsSlide;

export const { clearState } = typeSchoolsSlide.actions;

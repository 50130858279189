import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import avatarDefault from '../../assets/image/avatar_default.png';
import issueAvatarDefault from '../../assets/image/issue_default.svg';
import userDeleteAvatar from '../../assets/image/user_delete.png';
import {Link} from "react-router-dom";
import {TYPE_CHAT_SINGLE, typeUser} from "../../constant";

OverviewMessage.propTypes = {
  response: PropTypes.object,
  conversationId: PropTypes.number,
  userId: PropTypes.number,
  switchMsg: PropTypes.func,
  type: PropTypes.bool,
}

OverviewMessage.defaultProps = {
  response: {},
  conversationId: 0,
  userId: 0,
  switchMsg: () => {},
  type: false,
}
function OverviewMessage(props) {
  const {response, conversationId, userId, switchMsg, type} = props;
  const [infoConver, setInfoConver] = useState({});
  const [name, setName] = useState('');
  const [srcImg, setSrcImg] = useState('');
  const [textPreview, setTextPreview] = useState('');
  const [isIssueImage, setIsIssueImage] = useState(false);

  useEffect(() => {
    if (response) {
      const receiverUser = response.users.find(u => u?.id !== userId);
      setInfoConver({
        userId: receiverUser?.id,
        converId: response?.id,
      });
      if (response?.type === TYPE_CHAT_SINGLE) {
        setName(receiverUser?.name);
        setSrcImg(receiverUser?.profile?.image ?? avatarDefault);
        setTextPreview(response?.latest_message?.content);
      } else {
        const company = response.users.find(user => user.role === typeUser.company);
        setName(response?.name);
        setIsIssueImage(response?.issue?.avatar === null);
        setSrcImg(response?.issue?.avatar ?? issueAvatarDefault);
        setTextPreview(company?.name);
      }
      if (receiverUser?.is_deleted && response?.type === TYPE_CHAT_SINGLE) setSrcImg(userDeleteAvatar);
    }
  }, [response]);

  const handleClick = () => {
    switchMsg(infoConver);
  }

  return (
    <Link to={'/message/' + response?.id} style={{width: '100%'}}>
      <div className={response.id === conversationId && !type ? 'message-item active ' : 'message-item '}
           onClick={() => {handleClick()}}>
        <div className='group-name-avatar'>
          {isIssueImage ?
            <div className='avatar-chat-block'>
              <img src={srcImg} alt="avatar" className='avatar-issue-default' />
            </div> :
            <img src={srcImg} alt="avatar" className='avatar-chat-sidebar' />
          }
          <div className={response.is_read === 0 ? 'group-name-chat un-read' : 'group-name-chat'}>
            <div className='name-user-chat' title={name}>{name}</div>
            <div className='name-user-chat-sub' title={textPreview}>{textPreview}</div>
          </div>
        </div>
        <div className='day-last-msg'>{response.latest_message?.created_at}</div>
      </div>
    </Link>
  );
}

export default OverviewMessage;
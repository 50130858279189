import React, {useEffect, useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

CreateData.propTypes = {
  todos: PropTypes.bool,
  closeModal: PropTypes.func,
  title: PropTypes.string,
  handleCreate: PropTypes.func,
  message: PropTypes.string,
}
CreateData.defaultProps = {
  todos: false,
  closeModal: null,
  title: '',
  handleCreate: null,
  message: '',
}
function CreateData(props) {
  const {t} = useTranslation();
  const {todos, closeModal, title, handleCreate, message} = props;
  const [name, setName] = useState('');

  useEffect(() => {
    const input = document.getElementById('create-data');
    input?.focus();
  }, [todos]);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleCreate(name);
  }

  return (
    <div>
      <Modal show={todos} centered>
        <form onSubmit={handleSubmit}>
          <Modal.Header>
            <Modal.Title>
              <div className="title-form">{title}</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='d-flex justify-items-start align-items-center'>
              <label htmlFor="create-data" className='name-create-data' >{t('admin.name_title')}</label>
              <input id='create-data' type="text" className='input-system w-100' placeholder={t('admin.data.placeholder_name')} onChange={(e) => setName(e.target.value.trim())} />
            </div>
            <span className='text-error'>{message}</span>
          </Modal.Body>
          <Modal.Footer>
            <Button type='button' variant="secondary" onClick={() => closeModal(!todos)}>{t('close')}</Button>
            <Button type='submit' variant="primary">{t('register')}</Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}

export default CreateData;
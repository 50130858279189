import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {get, post} from "../../services";

const initialState = {
  isFetching: false,
  listConversations: null,
  message: null,
  listMessages: null,
  conversation: null,
  errorSendMsg: false,
  successSendMsg: false,
  successListMsg: false,
  statusConversation: false,
  errorConversation: false,
  msgErrorConversation: '',
  msgChat: '',
  successDetail: false,
  errorDetail: false,
  detailConver: null,
  msgErrorDetail: '',
};

export const createConversation = createAsyncThunk(
  'post_conversations',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'conversations';
      const response = await post(url, params);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

export const listConversation = createAsyncThunk(
  'get_conversations',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'conversations';
      const response = await get(url, null, params);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

export const detailConversation = createAsyncThunk(
  'get_conversations/detail',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'conversations/' + params?.id;
      const response = await get(url, null, params);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

export const chatMessage = createAsyncThunk(
  'messages',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'messages';
      const response = await post(url, params);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

export const listMessage = createAsyncThunk(
  'messages_detail',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'messages/' + params.id;
      const response = await get(url, null, params);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

export const markRead = createAsyncThunk(
  'conversations/mark-read',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'conversations/mark-read/' + params.id;
      const response = await post(url, null, params);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

const messagesSlide = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    clearState: (state) => {
      state.isFetching = false;
      state.listConversations = null;
      state.conversation = null;
      state.errorSendMsg = false;
      state.successSendMsg = false;
      state.statusConversation = false;
      state.errorConversation = false;
      state.msgErrorConversation = '';
      state.msgChat = '';
      state.errorDetail = false;
      state.successDetail = false;
      state.detailConver = null;
      state.msgErrorDetail = '';
      return state;
    },
  },
  extraReducers: {
    [createConversation.pending.type]: (state) => {
      state.isFetching = true;
      state.conversation = null;
      state.statusConversation = false;
      state.errorConversation = false;
      state.msgErrorConversation = '';
    },
    [createConversation.fulfilled.type]: (state, {payload}) => {
      state.isFetching = false;
      state.conversation = payload.data;
      state.statusConversation = true;
      state.errorConversation = false;
      state.msgErrorConversation = '';
    },
    [createConversation.rejected.type]: (state, {payload}) => {
      state.isFetching = false;
      state.conversation = null;
      state.statusConversation = false;
      state.errorConversation = true;
      state.msgErrorConversation = payload;
    },

    [detailConversation.pending.type]: (state) => {
      state.isFetching = true;
      state.detailConver = null;
      state.successDetail = false;
      state.errorDetail = false;
      state.msgErrorDetail = '';
    },
    [detailConversation.fulfilled.type]: (state, {payload}) => {
      state.isFetching = false;
      state.detailConver = payload.data;
      state.successDetail = true;
      state.errorDetail = false;
      state.msgErrorDetail = '';
    },
    [detailConversation.rejected.type]: (state, {payload}) => {
      state.isFetching = false;
      state.detailConver = null;
      state.successDetail = false;
      state.errorDetail = true;
      state.msgErrorDetail = payload;
    },

    [listConversation.pending.type]: (state,) => {
      state.isFetching = true;
      state.listConversations = null;
    },
    [listConversation.fulfilled.type]: (state, { payload }) => {
      state.isFetching = false;
      state.listConversations = payload.data;
    },
    [listConversation.rejected.type]: (state) => {
      state.isFetching = false;
      state.listConversations = null;
    },

    [chatMessage.pending.type]: (state,) => {
      state.isFetching = true;
      state.message = null;
      state.errorSendMsg = false;
      state.successSendMsg = false;
      state.msgChat = '';
    },
    [chatMessage.fulfilled.type]: (state, { payload }) => {
      state.isFetching = false;
      state.message = payload.data;
      state.errorSendMsg = false;
      state.successSendMsg = true;
      state.msgChat = '';
    },
    [chatMessage.rejected.type]: (state, { payload }) => {
      state.isFetching = false;
      state.message = null;
      state.errorSendMsg = true;
      state.successSendMsg = false;
      state.msgChat = payload;
    },

    [listMessage.pending.type]: (state,) => {
      state.isFetching = true;
      state.listMessages = null;
      state.successListMsg = false;
    },
    [listMessage.fulfilled.type]: (state, { payload }) => {
      state.isFetching = false;
      state.listMessages = payload.data;
      state.successListMsg = true;

    },
    [listMessage.rejected.type]: (state) => {
      state.isFetching = false;
      state.listMessages = null;
      state.successListMsg = false
    },
  },
});

export default messagesSlide;

export const { clearState } = messagesSlide.actions;

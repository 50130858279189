import React, {useEffect, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {typeAvatar} from "../../constant";
import addIcon from "../../assets/image/add_avatar.svg";

AvatarUser.propTypes = {
  getFile: PropTypes.func,
  textBack: PropTypes.string,
  textValidate: PropTypes.string,
  avatar: PropTypes.string,
  backUrl: PropTypes.string,
  clearValidate: PropTypes.bool,
  type: PropTypes.string,
}
AvatarUser.defaultProps = {
  getFile: () => {},
  textBack: '',
  textValidate: '',
  avatar: null,
  backUrl: '',
  clearValidate: false,
  type: '',
}
function AvatarUser(props) {
  const { t } = useTranslation();
  const {getFile, textBack, textValidate, avatar, backUrl, clearValidate, type} = props;
  const inputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [validateAvatar, setValidateAvatar] = useState('');

  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      return
    }
    const MAX_FILE_SIZE = 1024;
    const IMAGE_UPLOADED_FILE =  e.target.files[0].size / 1024;
    if (IMAGE_UPLOADED_FILE > MAX_FILE_SIZE) {
      setValidateAvatar(t('upload_large_size_avatar'));
      return;
    }
    setSelectedFile(e.target.files[0]);
  }

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    getFile(selectedFile);
    setValidateAvatar('');

    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile]);

  const handleClick = () => {
    inputRef.current.click();
  };

  useEffect(() => {
    setValidateAvatar(textValidate);
  }, [textValidate, clearValidate]);


  useEffect(() => {
    setPreview(avatar ?? null);
  }, [avatar]);

  return (
    <>
      {type === typeAvatar.user ?
        <div className="group-left-info col-md-3">
          <div>
            <input type='file' hidden ref={inputRef} onChange={onSelectFile} accept=".png, .jpg, .jpeg" />
          </div>
          {(selectedFile || preview) ?
            <div className="d-flex flex-column parent-avatar mr-avatar avatar-update-block">
              <img className="preview-avatar" onClick={handleClick} src={preview}/>
              <div className="mb-4 text-error avatar w-100">{validateAvatar}</div>
            </div> :
            <div className="d-flex flex-column mr-avatar avatar-update-block">
              <div className="col-md-3 avatar-block" onClick={handleClick}>
                <div className="des-avatar">{t('avatar')}</div>
                <div className="des-avatar">{t('can_setting')}</div>
              </div>
              <div className="mb-4 text-error avatar w-100">{validateAvatar}</div>
            </div>
          }
          <Link to={backUrl}>
            <button type="button" className="back-from-update">{textBack}</button>
          </Link>
        </div> :
        <>
          <div>
            <input type='file' hidden ref={inputRef} onChange={onSelectFile} accept=".png, .jpg, .jpeg" />
          </div>
          {selectedFile || preview ?
            <div className="d-flex flex-column w-100">
              <div className="image-add-issue-block avatar" onClick={handleClick}>
                <img className="preview-image" src={preview}/>
              </div>
              <div className="mb-4 text-error w-100">{validateAvatar}</div>
            </div> :
            <div className="d-flex flex-column w-100">
              <div className="image-add-issue-block avatar" onClick={handleClick}>
                <img src={addIcon} alt=""/>
                <div className="des-image-add">{t('can_set_image')}</div>
              </div>
              <div className="mb-4 text-error w-100">{validateAvatar}</div>
            </div>
          }
        </>
      }
    </>
  );
}

export default AvatarUser;
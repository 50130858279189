import React from 'react';
import BannerCoach from "./BannerCoach";
import Search from "./Search";
import PropTypes from "prop-types";


Content.propTypes = {
  title: PropTypes.string,
  activeStudent: PropTypes.string,
  activeCoach: PropTypes.string,
  titleResult: PropTypes.string,
  listResult: PropTypes.string,
  isStudent: PropTypes.bool,
};

Content.defaultProps = {
  title: '',
  activeStudent: '',
  activeCoach: '',
  titleResult: '',
  listResult: '',
  isStudent: false,
};
function Content(props) {
  const {title, activeCoach, activeStudent, listResult, titleResult, isStudent} = props;

  return (
    <>
      <BannerCoach title={title} des='' type={''}/>
      <Search activeStudent={activeStudent}
              activeCoach={activeCoach}
              titleResult={titleResult}
              listResult={listResult}
              isStudent={isStudent}
      />
    </>
  );
}

export default Content;
import React from 'react';
import { useTranslation } from 'react-i18next';
import inboxIcon from '../../assets/image/inbox_icon.svg';
import settingIcon from '../../assets/image/setting_icon.svg';
import logoutIcon from "../../assets/image/logout_icon.svg";
import './index.css';
import {signOut} from "../../redux/user/user.slide";
import PropTypes from "prop-types";
import {typeUser} from "../../constant";
import {useDispatch} from "react-redux";
import {Link, useNavigate} from "react-router-dom";

Index.propTypes = {
  type: PropTypes.string,
}
Index.defaultProps = {
  type: '',
}
function Index(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {type} = props;
  const logout = () => {
    dispatch(signOut());
    navigate('/');
  }

  return (
    <div className="header-my-page">
      <Link to='/'>
        <div className="text-logo">{t('logo')}</div>
      </Link>
      <div className="group-action-header">
        {type  !== typeUser.teacher &&
          <Link to='/message'>
            <img src={inboxIcon} alt="logo" className="icon-header" />
          </Link>
        }
        <img src={settingIcon} alt="logo" className="icon-header" />
        <button className="group-logout" onClick={logout}>
          <div className="text-logout mr-2">{t('logout')}</div>
          <img src={logoutIcon} className="" alt="logo" />
        </button>
      </div>
    </div>
  );
}

export default Index;
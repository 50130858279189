import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {Button, Modal} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {clearState, confirmUpdateEmail} from "../../redux/user/user.slide";
import Notification from "./Notification";

UpdateMail.propTypes = {
  todos: PropTypes.bool,
  closeModal: PropTypes.func,
  userId: PropTypes.any,
}
UpdateMail.defaultProps = {
  todos: false,
  closeModal: null,
  userId: null,
}
function UpdateMail(props) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {todos, closeModal, userId} = props;
  const [email, setEmail] = useState('');
  const [validate, setValidate] = useState('');
  const {isErrorConfirm, isSuccessConfirm, errorMessage} = useSelector(state => state.user);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setEmail('');
    setValidate('');
  }, [todos]);
  const handleUpdate = (e) => {
    e.preventDefault();
    setValidate('');
    dispatch(confirmUpdateEmail({email, id: userId}));
  }

  useEffect(() => {
    if (isErrorConfirm) {
      if (errorMessage?.status === 500) {
        alert(t('error_system'));
      }
      if (errorMessage?.status === 422) {
        setValidate(errorMessage?.data?.errors?.email?.[0]);
      }
    }
    if (isSuccessConfirm) {
      closeModal();
      setEmail('');
      setOpenModal(true);
    }

    return () => dispatch(clearState());
  }, [isErrorConfirm, isSuccessConfirm]);

  const closeModalConfirm = () => {
    setOpenModal(false);
  }

  return (
    <>
      <Modal show={todos} centered>
        <form onSubmit={handleUpdate}>
          <Modal.Header>
            <Modal.Title>
              <div className="title-form">{t('update_email.title')}</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='main-text'>{t('update_email.tutorial')}</div>
            <div className='main-text mb-3'>{t('update_email.tutorial2')}</div>
            <div>
              <label className='main-text mb-2'>{t('update_email.email')}</label>
              <input type="text" placeholder={t('placeholder.email')} className='input-system w-100' onChange={(e) => setEmail(e.target.value.trim())} />
              <div className='text-error'>{validate}</div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              {t('close')}
            </Button>
            <Button variant="primary" type='submit'>
              {t('update_email.btn')}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      <Notification todos={openModal} title={t('update_email.confirm')} content={t('update_email.send_success')} closeModal={closeModalConfirm} />
    </>
  );
}

export default UpdateMail;
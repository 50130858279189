import React, {useEffect, useState} from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import './index.css';
import PropTypes from "prop-types";
import {typeUser, manager} from "../../constant";
import ListUsers from './ListUsers';
import {useDispatch, useSelector} from "react-redux";
import {clearState, currentUser} from "../../redux/user/user.slide";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import Loading from "../../components/Loading";
import ListIssues from './ListIssues';
import ListData from './Data';
import QuantityParticipant from './QuantityParticipant';
import SettingSchoolYear from './SettingSchoolYear';

Index.propTypes = {
  type: PropTypes.number,
}
Index.defaultProps = {
  type: null,
}
function Index(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {type} = props;
  const dispatch = useDispatch();
  const {isSuccessUser, isErrorUser, response, errorMessageUser, isFetching} = useSelector(state => state.user);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(currentUser());
    return () => dispatch(clearState());
  }, []);

  useEffect(() => {
    if (isErrorUser) {
      if (errorMessageUser.status === 500) {
        return alert(t('error_system'));
      }
    }
    if (isSuccessUser) {
      setUser(response?.data);
      if (response?.data?.role !== typeUser.admin) {
        navigate('/');
      }
    }
  }, [isSuccessUser, isErrorUser]);

  const handleLoading = (value) => {
    setIsLoading(value);
  }

  const getTemplate = () => {
    switch (type) {
      case manager.company:
        return <ListUsers setLoading={handleLoading} type={typeUser.company} />;
      case manager.student:
        return <ListUsers setLoading={handleLoading} type={typeUser.student} />;
      case manager.coach:
        return <ListUsers setLoading={handleLoading} type={typeUser.coach} />;
      case manager.teacher:
        return <ListUsers setLoading={handleLoading} type={typeUser.teacher} />;
      case manager.issue:
        return <ListIssues setLoading={handleLoading} />;
      case manager.gender:
        return <ListData type={manager.gender} setLoading={handleLoading} />;
      case manager.object_student:
        return <ListData type={manager.object_student} setLoading={handleLoading} />;
      case manager.quantity_participant:
        return <QuantityParticipant setLoading={handleLoading} />;
      case manager.school_type:
        return <ListData type={manager.school_type} setLoading={handleLoading} />;
      case manager.school_name:
        return <ListData type={manager.school_name} setLoading={handleLoading} />;
      case manager.major:
        return <ListData type={manager.major} setLoading={handleLoading} />;
      case manager.year_school:
        return <ListData type={manager.year_school} setLoading={handleLoading} />;
      case manager.member_status:
        return <ListData type={manager.member_status} setLoading={handleLoading} />;
      case manager.issue_status:
        return <ListData type={manager.issue_status} setLoading={handleLoading} />;
      case manager.school_year:
        return <SettingSchoolYear setLoading={handleLoading} />;
      case manager.category:
        return <ListData type={manager.category} setLoading={handleLoading} />;
      default:
        return null;
    }
  }

  return (
    <>
      <div className='admin-container'>
        <Sidebar user={user} />
        <div></div>
        <div className='content-admin-group'>
          <Header />
          {getTemplate()}
        </div>
      </div>
      {isFetching || isLoading ? <Loading /> : null}
    </>
  );
}

export default Index;
import React, {useEffect, useState} from 'react';
import FormCompany from "../Company/FormInfo";
import FormStudent from "../Student/FormInfo";
import FormCoach from "../Coach/FormInfo";
import FormTeacher from "../Teacher/FormInfo";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {clearState, currentUser} from "../../redux/user/user.slide";
import {typeUser} from "../../constant";
import {useNavigate} from "react-router-dom";
import {clearState as clearStateInfo, userInfo} from "../../redux/admin/manage.slide";
import NotFound from '../../components/404';
import Loading from "../../components/Loading";

function Update() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {isErrorUser, isSuccessUser, response, errorMessage} = useSelector(state => state.user);
  const {isFetching, isSuccess, isError, responseUser, errorMessageUser} = useSelector(state => state.managerUser);
  const [user, setUser] = useState(null);
  const [isNotFound, setIsNotFound] = useState(false);

  useEffect(() => {
    dispatch(currentUser());
    dispatch(userInfo({id: window.location.href.split('/').pop()}));
    return () => {
      dispatch(clearState());
      dispatch(clearStateInfo());
    }
  }, []);

  useEffect(() => {
    if (isErrorUser) {
      if (errorMessage?.status === 500) {
        return alert(t('error_system'));
      }
    }
    if (isSuccessUser) {
      if (response?.data.role !== typeUser.admin) {
        navigate('/');
      }
    }
  }, [isSuccessUser, isErrorUser]);

  useEffect(() => {
    if (isError) {
      if (errorMessageUser?.status === 500) {
        return alert(t('error_system'));
      }
      if (errorMessageUser?.status === 404) {
        setIsNotFound(true);
      }
    }
    if (isSuccess) {
      const isUser = responseUser?.data?.role === typeUser.company ||
        responseUser?.data?.role === typeUser.student ||
        responseUser?.data?.role === typeUser.coach ||
        responseUser?.data?.role === typeUser.teacher;
      if (!isUser) {
        navigate('/admin');
      }
      setUser(responseUser?.data);
    }

    return () => dispatch(clearStateInfo());
  }, [isError, isSuccess]);

  const getFormInfo = () => {
    switch (user?.role) {
      case typeUser.company:
        return <FormCompany
          textBack={t('admin.back_top_manger_company')}
          textSubmit={t('update')}
          userInfo={user}
          type='admin'
        />;
      case typeUser.student:
        return <FormStudent
          textBack={t('admin.back_top_manager_student')}
          textSubmit={t('update')}
          userInfo={user}
          type='admin'
        />;
      case typeUser.coach:
        return <FormCoach
          textBack={t('admin.back_top_manager_coach')}
          textSubmit={t('update')}
          userInfo={user}
          type='admin'
        />;
      case typeUser.teacher:
        return <FormTeacher
          textBack={t('admin.back_top_manager_teacher')}
          textSubmit={t('update')}
          userInfo={user}
          type='admin'
          showHideForm={null}
          formData={null}
          typeSubmit=''
          isShow={true}
        />;
      default:
        return null;
    }
  }

  return (
    <>
      {isNotFound ? <NotFound /> :
        <>
          {getFormInfo()}
          {isFetching && <Loading />}
        </>
      }
    </>
  );
}

export default Update;
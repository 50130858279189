import React from 'react';
import 'react-slideshow-image/dist/styles.css'
import background from '../../../assets/image/TOP.png'
import './SildeImage.css';


const Slideshow = () => {

  return (
    <div className="feature-block">
      <div className="slide-container">
        <div className="each-slide">
          <div className="group-slide">
            <div className="image-slide-block">
              <div className="image-slide" style={{'backgroundImage': `url(${background})`}}></div>
            </div>
            <div className="description-slide">
              <div className="slide-title-description">アクションラーニングを活用した<br/>
                マイクロ・インターンシップです。</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Slideshow;
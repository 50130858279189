import React from 'react';
import './index.css';
function Index() {
  return (
    <div className='not-found-block'>
      <div className="code-not-found">404 Not Found</div>
      <div className="text-not-found">お探しのページは見つかりませんでした</div>
    </div>
  );
}

export default Index;
import React, {useState} from 'react';
import PropTypes from "prop-types";
import {Button, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import './index.css';
import {typeUser} from "../../constant";
import {useNavigate} from "react-router-dom";

Login.propTypes = {
  todos: PropTypes.bool,
  closeModal: PropTypes.func,
  issueId: PropTypes.any,
}
Login.defaultProps = {
  todos: false,
  closeModal: null,
  issueId: null,
}
function Login(props) {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {todos, closeModal, issueId} = props;
  const [selected, setSelected] = useState(typeUser.student);

  const handleSubmit = () => {
    switch (selected) {
      case typeUser.student:
        navigate('/student/login?issueId=' + issueId);
        break;
      case typeUser.coach:
        navigate('/coach/login?issueId=' + issueId);
        break;
      case typeUser.teacher:
        navigate('/teacher/login?issueId=' + issueId);
        break;
      case typeUser.company:
        navigate('/company/login?issueId=' + issueId);
        break;
      default:
        navigate('/');
    }
  }

  return (
    <Modal show={todos} centered>
      <Modal.Header>
        <Modal.Title>
          <div className="title-form">{t('modal_login.title')}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='group-radio-modal'>
          <input id='student-login' type="radio" name='type_login' value={typeUser.student} checked={selected === typeUser.student} onChange={(e) => setSelected(e.target.value)} />
          <label htmlFor='student-login' className='main-text'>{t('modal_login.student')}</label>
        </div>
        <div className='group-radio-modal'>
          <input id='coach-login' type="radio" name='type_login' value={typeUser.coach} checked={selected === typeUser.coach} onChange={(e) => setSelected(e.target.value)} />
          <label htmlFor='coach-login' className='main-text'>{t('modal_login.coach')}</label>
        </div>
        <div className='group-radio-modal'>
          <input id='teacher-login' type="radio" name='type_login' value={typeUser.teacher} checked={selected === typeUser.teacher} onChange={(e) => setSelected(e.target.value)} />
          <label htmlFor='teacher-login' className='main-text'>{t('modal_login.teacher')}</label>
        </div>
        <div className='group-radio-modal'>
          <input id='company-login' type="radio" name='type_login' value={typeUser.company} checked={selected === typeUser.company} onChange={(e) => setSelected(e.target.value)} />
          <label htmlFor='company-login' className='main-text'>{t('modal_login.company')}</label>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => closeModal(!todos)}>
          <div>{t('cancel')}</div>
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          <div>{t('login')}</div>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Login;
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {get} from "../../services";

const initialState = {
  isFetching: false,
  isSuccessLevel: false,
  isErrorLevel: false,
  errorMessage: '',
  listLevel: null,
};

export const listLevelStudent = createAsyncThunk(
  'levels',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'levels';
      const response = await get(url, params);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

const levelsSlide = createSlice({
  name: 'company',
  initialState,
  reducers: {
    clearState: (state) => {
      state.isErrorLevel = false;
      state.isFetching = false;
      state.isSuccessLevel = false;
      state.errorMessage = '';
      state.listLevel = null;
      return state;
    },
  },
  extraReducers: {
    [listLevelStudent.pending.type]: (state) => {
      if (!state.isFetching) {
        state.errorMessage = '';
        state.isErrorLevel = false;
        state.isFetching = true;
        state.isSuccessLevel = false;
        state.listLevel = null;
      }
    },
    [listLevelStudent.fulfilled.type]: (state, { payload }) => {
      if (state.isFetching) {
        state.isErrorLevel = false;
        state.isSuccessLevel = true;
        state.isFetching = false;
        state.errorMessage = '';
        state.currentRequestId = undefined;
        state.listLevel = payload.data;
      }
    },
    [listLevelStudent.rejected.type]: (state, { payload }) => {
      if (state.isFetching) {
        state.isErrorLevel = true;
        state.isSuccessLevel = false;
        state.isFetching = false;
        state.errorMessage = payload;
        state.currentRequestId = undefined;
        state.listLevel = null;
      }
    },
  },
});

export default levelsSlide;

export const { clearState } = levelsSlide.actions;

import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {clearState, currentUser, updateEmail} from "../../redux/user/user.slide";
import UrlExpired from "../../components/Modal/UrlExpired";
import {typeUser} from "../../constant";
import {Link} from "react-router-dom";

function UpdateEmail() {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [isExpired, setIsExpired] = useState(false);
  const {isError, isSuccess, errorMessage, responseUpdate, isSignedIn, response, isSuccessUser} = useSelector(state => state.user);
  const [backUrl, setBackUrl] = useState('');

  useEffect(() => {
    dispatch(updateEmail({token: window.location.href.split('=').pop()}));
    if (isSignedIn) {
      dispatch(currentUser());
    }
    return () => dispatch(clearState());
  }, []);

  useEffect(() => {
    if (isError) {
      if (errorMessage?.status === 500) {
        alert(t('error_system'));
      }
      if (errorMessage?.status === 410) {
        setIsExpired(true);
      }
    }
    if (isSuccess && !backUrl) {
      if (responseUpdate?.data?.data?.role === typeUser.company) {
        setBackUrl('/company/students');
      }
      if (responseUpdate?.data?.data?.role === typeUser.coach) {
        setBackUrl('/coach');
      }
      if (responseUpdate?.data?.data?.role === typeUser.student) {
        setBackUrl('/student');
      }
      if (responseUpdate?.data?.data?.role === typeUser.teacher) {
        setBackUrl('/teacher');
      }
    }
  }, [isError, isSuccess]);

  useEffect(() => {
    if (response?.data?.role === typeUser.admin) {
      setBackUrl('/admin');
    }
  }, [isSuccessUser]);

  return (
    <>
      {isExpired ? <UrlExpired title={t('update_email.title_complete')} /> :
        <div className="form-register form-complete d-flex flex-column justify-content-between">
          <div className="title-form">{t('update_email.title_complete')}</div>
          <div className="main-text">{t('update_email.complete_success')}</div>
          <Link to={backUrl}>
            <button className="button-primary">{t('update_email.back_to_my_page')}</button>
          </Link>
        </div>
      }
    </>
  );
}

export default UpdateEmail;
import React, {useEffect, useState} from 'react';
import collapseMenu from "../../assets/image/expand_menu.svg";
import PropTypes from "prop-types";
import {typeUser} from "../../constant";
import {useTranslation} from "react-i18next";

HeaderIssue.propTypes = {
  setOpenModal: PropTypes.func,
  response: PropTypes.object,
  userId: PropTypes.number,
  role: PropTypes.string,
}
HeaderIssue.defaultProps = {
  setOpenModal: () => {},
  response: {},
  userId: null,
  role: '',
}
function HeaderIssue(props) {
  const { t } = useTranslation();
  const {response, setOpenModal, userId, role} = props;
  const [companyName, setCompanyName] = useState('');
  const [timeApply, setTimeApply] = useState('');

  useEffect(() => {
    const company = response?.users.find(user => user?.role === typeUser.company);
    setCompanyName(company?.name);
    const currentUser = response?.users.find(user => user?.id === userId);
    setTimeApply(currentUser?.joined_issue_at);
    if (company?.id === userId) {
      setTimeApply(response?.issue?.created_at);
    }
  }, [response]);

  return (
    <div className='header-body-chat-issue'>
      <div className='d-flex justify-content-start align-items-start'>
        <img className='expand-menu-chat mr-2 pt-2' src={collapseMenu} alt="icon" />
        <div className='d-flex flex-column align-items-start header-issue-group'>
          <div className='name-user-body-chat' title={response?.name}>{response?.name}</div>
          <div className='name-company-chat' title={companyName}>{companyName}</div>
          <div className='group-date-apply'>
            {role === typeUser.company || role === typeUser.coach ?
              <button className='btn-list-user-apply' onClick={() => setOpenModal(true)}>{t('message.btn_list_apply')}</button> : null
            }
            <div className='date-apply'>{timeApply}</div>
            <div className='text-date-apply'>に応募しました</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderIssue;
export const typeUser = {
  admin: 'admin',
  company: 'company',
  student: 'student',
  coach: 'coach',
  teacher: 'teacher',
};

function getListYear() {
  let listYear = [];
  const currentYear = new Date().getFullYear();
  for (let i = 1953; i <= currentYear; i++) {
    listYear.push({value: i, name: i + '年'});
  }

  return listYear;
}

function getListYearIssue() {
  let listYear = [];
  const currentYear = new Date().getFullYear();
  for (let i = currentYear; i <= currentYear +5; i++) {
    listYear.push({value: i, name: i + '年'});
  }

  return listYear;
}

function getListMonth() {
  let listMonth = [];
  for (let i = 1; i <= 12; i++) {
    listMonth.push({value: i, name: i + '月'})
  }

  return listMonth;
}

function getListDay(value) {
  let listDay = [];
  for (let i = 1; i <= value; i++) {
    listDay.push({value: i, name: i + '日'});
  }

  return listDay;
}

export const datePicker = {
  listYear: getListYear(),
  listMonth: getListMonth(),
  listDay28: getListDay(28),
  listDay29: getListDay(29),
  listDay30: getListDay(30),
  listDay31: getListDay(31),
};

export const datePickerIssue = {
  listYear: getListYearIssue(),
  listMonth: getListMonth(),
  listDay28: getListDay(28),
  listDay29: getListDay(29),
  listDay30: getListDay(30),
  listDay31: getListDay(31),
}

export const japanDistrictList = [
  '北海道',
  '青森県',
  '岩手県',
  '宮城県',
  '秋田県',
  '山形県',
  '福島県',
  '茨城県',
  '栃木県',
  '群馬県',
  '埼玉県',
  '千葉県',
  '東京都',
  '神奈川県',
  '新潟県',
  '富山県',
  '石川県',
  '福井県',
  '山梨県',
  '長野県',
  '岐阜県',
  '静岡県',
  '愛知県',
  '三重県',
  '滋賀県',
  '京都府',
  '大阪府',
  '兵庫県',
  '奈良県',
  '和歌山県',
  '鳥取県',
  '島根県',
  '岡山県',
  '広島県',
  '山口県',
  '徳島県',
  '香川県',
  '愛媛県',
  '高知県',
  '福岡県',
  '佐賀県',
  '長崎県',
  '熊本県',
  '大分県',
  '宮崎県',
  '鹿児島県',
  '沖縄県',
];

export const typeSignUp = {
  admin: 1,
  company: 2,
  student: 3,
  coach: 4,
  teacher: 5,
};

export const defaultSelect = 1000000000;

export const manager = {
  default: 0,
  company: 1,
  student: 2,
  coach: 3,
  teacher: 4,
  issue: 5,
  gender: 6,
  object_student: 7,
  quantity_student: 8,
  quantity_organizer: 9,
  school_type: 10,
  school_name: 11,
  major: 12,
  year_school: 13,
  member_status: 14,
  issue_status: 15,
  quantity_participant: 16,
  school_year: 17,
  category: 18,
}

export const paginationAdmin = 10;

export const typeAvatar = {
  user: 'user',
  issue: 'issue',
}

export const issueStatus = {
  open: 'open',
  start: 'start',
  end: 'end',
  temp: 'temp',
  complete: 'complete',
}

export const MAX_QUANTITY_STUDENT_APPLY = 99;

export const TYPE_CHAT_SINGLE = '1vs1';

export const TYPE_CHAT_GROUP = 'group';
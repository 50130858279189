import React, {useEffect, useState} from 'react';
import Header from "../../components/Header";
import {Link} from "react-router-dom";
import backIcon from "../../assets/image/back_icon.svg";
import {useTranslation} from "react-i18next";
import '../../containers/Student/index.css';
import PropTypes from "prop-types";
import nl2br from 'react-nl2br';
import Loading from "../Loading";
import AvatarDefault from '../../assets/image/avatar_default.png';
import {useDispatch, useSelector} from "react-redux";
import {clearState, createConversation} from "../../redux/mesage/message.slide";
import {TYPE_CHAT_SINGLE} from "../../constant";
import {clearState as clearStateEvaluate, detailEvaluate, listEvaluate} from "../../redux/evaluate/evaluate.slide";
import EvaluateDetail from "../Modal/EvaluateDetail";

Index.propTypes = {
  response: PropTypes.object,
  type: PropTypes.string,
  isLoading: PropTypes.bool,
}
Index.defaultProps = {
  response: {},
  type: '',
  isLoading: true,
}
function Index(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {response, type, isLoading} = props;
  const [url, setUrl] = useState('');
  const [name, setName] = useState('');
  const [gender, setGender] = useState('');
  const [birthday, setBirthday] = useState('');
  const [address, setAddress] = useState('');
  const [school, setSchool] = useState('');
  const [major, setMajor] = useState('');
  const [bio, setBio] = useState('');
  const [skills, setSkills] = useState('');
  const [avatar, setAvatar] = useState(null);
  const [userId, setUserId] = useState(null);
  const [conversationId, setConversationId] = useState(null);
  const {statusConversation, errorConversation, conversation} = useSelector(state => state.message);
  const {
    isFetching,
    isErrorList,
    isSuccessList,
    listEvaluates,
    evaluateDetail,
    isErrorDetail,
    isSuccessDetail,
  } = useSelector(state => state.evaluate);
  const [evaluates, setEvaluates] = useState([]);
  const [evaluate, setEvaluate] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    dispatch(listEvaluate({
      user_id: window.location.href.split('/').pop()
    }));

    return () => dispatch(clearStateEvaluate());
  }, []);

  useEffect(() => {
    if (isErrorList) {
      alert(t('error_system'));
    }
    if (isSuccessList) {
      setEvaluates(listEvaluates?.data?.data?.data);
    }

    return () => dispatch(clearStateEvaluate());
  }, [isErrorList, isSuccessList]);

  useEffect(() => {
    if (type) {
      setUrl('/company/' + type);
    }
    if (response) {
      setName(response?.data?.name ?? null);
      setGender(response?.data?.profile?.gender?.name ?? null);
      setBirthday(response?.data?.profile?.birthday ?? null);
      setAddress(response?.data?.profile?.city?.name ?? null);
      setSchool(response?.data?.profile?.school_name ?? null);
      setMajor(response?.data?.profile?.major?.name ?? null);
      setAvatar(response?.data?.profile?.image ?? null);
      setBio(response?.data?.profile?.introduce_yourself ?? null);
      setSkills(response?.data?.profile?.skills ?? null);
      setUserId(response?.data?.id);
    }
  }, [response, type]);

  useEffect(() => {
    if (userId) {
      dispatch(createConversation({
        type: TYPE_CHAT_SINGLE,
        user_id: userId,
      }));
    }

    return () => dispatch(clearState());
  }, [userId]);


  useEffect(() => {
    if (errorConversation) return alert(t('error_system'));
    if (statusConversation) {
      setConversationId(conversation?.data?.id);
    }

    return () => dispatch(clearState());
  }, [statusConversation, errorConversation]);

  const getDetailEvaluate = (id) => {
    dispatch(detailEvaluate({id}));
  }

  useEffect(() => {
    if (isErrorDetail) {
      alert(t('error_system'));
      return;
    }
    if (isSuccessDetail) {
      setEvaluate(evaluateDetail?.data?.data);
      setOpenModal(true);
    }

    return () => dispatch(clearStateEvaluate());
  }, [isErrorDetail, isSuccessDetail]);

  const closeModal = (value) => {
    setOpenModal(value);
  }

  return (
    <>
      <div className="container container-detail">
        <Header type={type}/>
        <div className="body-detail">
          <div className="back-group">
            <Link to={url}>
              <img src={backIcon} alt="icon" className="cursor-pointer" />
            </Link>
            <div className="text-back">{t('my_page')}</div>
            <div className="text-back">{t('back')}</div>
          </div>
          <div className="content-detail-block">
            <div className="first-info-detail">
              <div className="basic-info">
                {name && <div className="name-detail mb-3">{name}</div>}
                {gender && <div className="text-detail">{gender}</div>}
                {birthday && type === 'students' && <div className="text-detail">{birthday}</div>}
                {address && type === 'students' && <div className="text-detail">{address}</div>}
                {school && type === 'students' && <div className="text-detail">{school}</div>}
                {major && type === 'students' && <div className="text-detail">{major}</div>}
              </div>
              <div className="avatar-info" style={{backgroundImage: `url(${avatar ?? AvatarDefault})`}}></div>
            </div>
            <div className="second-info-detail">
              <div className="basic-info justify-content-between">
                <div className="detail-second">{t('update_coach.bio')}</div>
                {bio && <div className="text-detail text-break detail-bottom">{nl2br(bio)}</div> }
                <div className="detail-second">{t('skill_detail')}</div>
                {skills && <div className="text-detail text-break detail-bottom">{nl2br(skills)}</div>}
                {type === 'students' &&
                  <Link to={'/message/' + conversationId}>
                    <button className="btn-send-msg">{t('send_message')}</button>
                  </Link>
                }
              </div>
              {type === 'students' &&
                <div className="list-comment-block">
                  <div className="detail-second mb-3">{t('list_comment')}</div>
                  <div className="comment-block">
                    <div className="comment-block-child">
                      {!evaluates.length && <div className='text-error text-center'>{t('evaluate.no_data')}</div>}
                      {evaluates.map(value => (
                        <div key={value?.id} className="d-flex justify-content-start align-items-center">
                          <div className='info-comment' onClick={() => {getDetailEvaluate(value?.id)}}>詳細</div>
                          <div className='evaluate-group'>
                            <div className='user-evaluate'>{value?.issue?.user?.name}</div>
                            <div className="text-evaluate">{value?.comment}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <EvaluateDetail todos={openModal} closeModal={closeModal} response={evaluate} />
      {isLoading || isFetching ? <Loading /> : null}
    </>
  );
}

export default Index;
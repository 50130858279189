import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {get, post, deleteMethod} from "../../services";

const initialState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: '',
  response: null,
  isErrorDelete: false,
  isSuccessDelete: false,
  isFetchingDelete: false,
  isErrorJoin: false,
  isSuccessJoin: false,
  isFetchingIssue: false,
  errorMessageIssueUpdate: '',
  isErrorIssueUpdate: false,
  isSuccessIssueUpdate: false,
};
export const issueDetail = createAsyncThunk(
  'issues/detail',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'issues/' + params?.id;
      const response = await get(url, {}, params);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

export const issueUpdate = createAsyncThunk(
  'issues/update',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'issues/' + params?.id;
      const response = await post(url, params, true);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

export const coachJoin = createAsyncThunk(
  'issues/coach-join',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'issues/' + params?.id + '/joined-by-coach';
      const response = await post(url, params, false);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

export const issueDelete = createAsyncThunk(
  'issues/delete',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'issues/' + params?.id;
      const response = await deleteMethod(url, params);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

const managerIssueSlide = createSlice({
  name: 'managerIssues',
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isFetching = false;
      state.isSuccess = false;
      state.errorMessage = '';
      state.response = null;
      state.isSuccessDelete = false;
      state.isErrorDelete = false;
      state.isFetchingDelete = false;
      state.isErrorJoin = false;
      state.isSuccessJoin = false;
      state.listStatusIssue = null;
      state.isSuccessIssueUpdate = false;
      state.isErrorIssueUpdate = false;
      state.isFetchingIssue = false;
      return state;
    },
  },
  extraReducers: {
    [issueDetail.pending.type]: (state) => {
      state.errorMessage = '';
      state.isError = false;
      state.isFetching = true;
      state.isSuccess = false;
      state.response = null;
    },
    [issueDetail.fulfilled.type]: (state, { payload }) => {
      state.isError = false;
      state.isSuccess = true;
      state.isFetching = false;
      state.errorMessageUser = '';
      state.response = payload.data;
    },
    [issueDetail.rejected.type]: (state, { payload }) => {
      state.isError = true;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessageUser = payload;
      state.response = null;
    },

    [issueUpdate.pending.type]: (state) => {
      state.errorMessageIssueUpdate = '';
      state.isErrorIssueUpdate = false;
      state.isFetchingIssue = true;
      state.isSuccessIssueUpdate = false;
    },
    [issueUpdate.fulfilled.type]: (state) => {
      state.isErrorIssueUpdate = false;
      state.isSuccessIssueUpdate = true;
      state.isFetchingIssue = false;
      state.errorMessageIssueUpdate = '';
    },
    [issueUpdate.rejected.type]: (state, { payload }) => {
      state.isErrorIssueUpdate = true;
      state.isSuccessIssueUpdate = false;
      state.isFetchingIssue = false;
      state.errorMessageIssueUpdate = payload;
    },

    [coachJoin.pending.type]: (state) => {
      state.isErrorJoin = false;
      state.isSuccessJoin = false;
    },
    [coachJoin.fulfilled.type]: (state) => {
      state.isErrorJoin = false;
      state.isSuccessJoin = true;
    },
    [coachJoin.rejected.type]: (state) => {
      state.isErrorJoin = true;
      state.isSuccessJoin = false;
    },

    [issueDelete.pending.type]: (state) => {
      state.isErrorDelete = false;
      state.isFetchingDelete = true;
      state.isSuccessDelete = false;
    },
    [issueDelete.fulfilled.type]: (state) => {
      state.isErrorDelete = false;
      state.isSuccessDelete = true;
      state.isFetchingDelete = false;
    },
    [issueDelete.rejected.type]: (state) => {
      state.isErrorDelete = true;
      state.isSuccessDelete = false;
      state.isFetchingDelete = false;
    },
  },
});

export default managerIssueSlide;

export const { clearState } = managerIssueSlide.actions;

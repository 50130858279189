import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import imageDefault from "../../../assets/image/issue_default.svg";
import soundImg from "../../../assets/image/sound.svg";
import {getTypeIssue} from "../../../helper";
import Search from './Search';
import {useDispatch, useSelector} from "react-redux";
import {clearState, listIssue} from "../../../redux/issue/issue.slide";
import {issueStatus, typeUser} from "../../../constant";
import {Link} from "react-router-dom";

List.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  setLoading: PropTypes.func,
}
List.defaultProps = {
  title: '',
  type: '',
  setLoading: null,
}
function List(props) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {title, type, setLoading} = props;
  const [issues, setIssues] = useState([]);
  const {isSuccessIssue, isErrorIssue, listIssues} = useSelector(state => state.issue);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [params, setParams] = useState({});
  const [statusArr, setStatusArray] = useState([]);
  const [isCall, setIsCall] = useState(false);

  useEffect(() => {
    const data = {};
    if (type === typeUser.company) {
      data.get_by_company_owner = 1;
      setStatusArray([issueStatus.start, issueStatus.end, issueStatus.open, issueStatus.temp]);
    }
    if (type === typeUser.student) {
      data.get_by_student_joined = 1;
      setStatusArray([issueStatus.start, issueStatus.end, issueStatus.open]);
    }
    if (type === typeUser.coach) {
      data.get_by_coach_joined = 1;
      setStatusArray([issueStatus.start, issueStatus.end, issueStatus.open]);
    }
    data.status = statusArr;
    window.scrollTo(0, 0);
    dispatch(listIssue(data));
    return () => dispatch(clearState());
  }, [type]);

  useEffect(() => {
    if (isSuccessIssue) {
      if (isLoadMore) {
        setIssues([...issues, ...listIssues?.data?.data ?? []]);
      } else {
        setIssues(listIssues?.data?.data ?? []);
      }
      setCurrentPage(listIssues?.data.meta.current_page);
      setLastPage(listIssues?.data.meta.last_page);
      setIsCall(false);
      window.scrollTo(0, window.scrollY - 10);
    }

    return () => dispatch(clearState());
  }, [isSuccessIssue, isErrorIssue]);

  const handleScroll = () => {
    const bottom = Math.ceil(window.innerHeight + window.scrollY) === document.documentElement.scrollHeight;
    if (bottom && currentPage < lastPage && !isCall) {
      setIsCall(true);
      setIsLoadMore(true);
      const paramSearch = {...params, page: currentPage + 1};
      if ((type === typeUser.company)) {
        paramSearch.get_by_company_owner = 1;
      }
      if (type === typeUser.student) {
        paramSearch.get_by_student_joined = 1;
      }
      if (type === typeUser.coach) {
        paramSearch.get_by_coach_joined = 1;
      }
      dispatch(listIssue(paramSearch));
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {
      passive: true
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [currentPage, lastPage]);

  const search = (response) => {
    setIsLoadMore(false);
    setIssues(response?.data?.data ?? []);
    setCurrentPage(response?.data.meta.current_page);
    setLastPage(response?.data.meta.last_page);
  }

  const paramSearch = (value) => {
    setIsLoadMore(false);
    setParams(value);
  }

  return (
    <div className='search-coach-block min-vh-100'>
      <div className='result-search-block'>
        <div className='title-result p-0'>{title}</div>
        {<Search response={search} params={paramSearch} type={type} setLoading={setLoading} />}
          <div className={issues.length > 0 ? 'list-course' : 'w-100 mt-5'}>
          {issues.length === 0 && <div className="no-data">{t('no_data_issue')}</div>}
          {issues.map(issue => (
            <div key={issue?.id} className='course-items items-issue'>
              {issue?.avatar ?
                <img className='image-pick-up' src={issue?.avatar} alt="image"/> :
                <img className='image-pick-up pick-up-default' src={imageDefault} alt="image"/>
              }
              <div className='header-course'>
                <div className='d-flex justify-content-start align-items-center'>
                  <div className='type-issue-tag mr-3'>
                    <img src={soundImg} alt="icon" className='mr-2'/>
                    <div className='type-issue'>{getTypeIssue(issue?.is_remote, issue?.is_face_to_face)}</div>
                  </div>
                </div>
                <div className='quantity-student-apply'>{issue?.remain_date > 0 ? 'あと' + issue?.remain_date + '日' : issue?.remain_date === 0 ? t('last_day') : ''}</div>
              </div>
              <div className='info-course'>
                <div className='title-course' title={issue?.theme_name}>{issue?.theme_name}</div>
                <div className='w-100 d-flex justify-content-center'>
                  <Link to={'/issues/' + issue?.id} >
                    <button className='btn-detail-issue'>{t('see_more')}</button>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default List;
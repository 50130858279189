import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
import {clearState, listLevelStudent} from "../../redux/company/levels.slide";

Index.propTypes = {
  getLevelStudent: PropTypes.func,
  response: PropTypes.number,
}
Index.defaultProps = {
  getLevelStudent: () => {},
  response: null,
}
function Index(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {getLevelStudent, response} = props;
  const {listLevel, isSuccessLevel} = useSelector(state => state.level);
  const [levelStudents, setLevelStudents] = useState([]);
  const [levelId, setLevelId] = useState(null);

  useEffect(() => {
    dispatch(listLevelStudent());

    return () => dispatch(clearState());
  }, []);

  useEffect(() => {
    setLevelStudents(listLevel?.data ?? []);
  }, [isSuccessLevel]);

  useEffect(() => {
    getLevelStudent(levelId)
  }, [levelId]);

  useEffect(() => {
    setLevelId(response);
  }, [response]);

  return (
    <select value={levelId || ''} id="" className={`select-company col-md-3 mb-4 ${levelId ? 'color-placeholder' : ''}`} onChange={(e) => {setLevelId(parseInt(e.target.value))}} >
      <option value="" disabled hidden>{t('placeholder.register_object')}</option>
      {levelStudents.map((val) => (
        <option key={val.id} value={val.id}>{val.name}</option>
      ))}
    </select>
  );
}

export default Index;
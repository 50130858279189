import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import ConfirmDelete from "../../components/Modal/ConfirmDelete";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import Notification from "../../components/Modal/Notification";
import {paginationAdmin, typeUser} from "../../constant";
import {clearState, listUser} from "../../redux/user/user.slide";
import {deleteUser} from "../../redux/admin/manage.slide";
import Pagination from 'react-paginate';

ListUsers.propTypes = {
  type: PropTypes.string,
  setLoading: PropTypes.func,
}
ListUsers.defaultProps = {
  type: '',
  setLoading: null,
}
function ListUsers(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {type,setLoading} = props;
  const {isSuccess, isError, listUsers, errorMessage} = useSelector((state) => state.user);
  const {isSuccessDelete, isErrorDelete, errorMessageDeleteUser} = useSelector((state) => state.managerUser);
  const [openModal, setOpenModal] = useState(false);
  const [users, setUser] = useState([]);
  const [isComplete, setIsComplete] = useState(false);
  const [keyword, setKeyWord] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [userId, setUserId] = useState(null);
  const [openModalNotification, setOpenModalNotification] = useState(false);
  const [contentDelete, setContentDelete] = useState('');
  const [nameUser, setNameUser] = useState('');
  const [titleConfirmDelete, setTitleConfirmDelete] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const handlePageChange = (pageNumber) => {
    dispatch(listUser({
      page: pageNumber?.selected + 1,
      name: keyword,
      paginate: paginationAdmin,
      role: type,
    }));
  }

  const closeModal = (value) => {
    setOpenModal(value);
  }

  useEffect(() => {
    setKeyWord('');
    dispatch(listUser({
      paginate: paginationAdmin,
      role: type,
    }));
    setNameUser(getTitleUser(type));
    setTitleConfirmDelete(getTitleDelete(type));
    return () => dispatch(clearState());
  }, [type]);

  useEffect(() => {
    if (isError) {
      if (errorMessage?.status === 500) {
        alert(t('error_system'));
      }
    }
    if (isSuccess) {
      setIsComplete(true);
      setUser(listUsers?.data?.data ?? []);
      setCurrentPage(listUsers?.data?.meta.current_page);
      setTotalPages(listUsers?.data?.meta?.last_page);
    }

    return () => dispatch(clearState());
  }, [isSuccess, isError]);

  const getTitleUser = (value) => {
    switch (value) {
      case typeUser.company:
        return t('admin.company.name');
      case typeUser.student:
        return t('admin.student.name');
      case typeUser.coach:
        return t('admin.coach.name');
      case typeUser.teacher:
        return t('admin.teacher.name');
      default:
        return '';
    }
  }

  const getTitleDelete = (value) => {
    switch (value) {
      case typeUser.company:
        return t('admin.company.title_delete');
      case typeUser.student:
        return t('admin.student.title_delete');
      case typeUser.coach:
        return t('admin.coach.title_delete');
      case typeUser.teacher:
        return t('admin.teacher.title_delete');
      default:
        return '';
    }
  }

  const getContentDeleteSuccess = (value) => {
    switch (value) {
      case typeUser.company:
        return t('admin.modal_delete.content_success');
      case typeUser.student:
        return t('admin.student.delete_success');
      case typeUser.coach:
        return t('admin.coach.delete_success');
      case typeUser.teacher:
        return t('admin.teacher.delete_success');
      default:
        return '';
    }
  }

  const getContentDeleteError = (value, status = null) => {
    if (status === 422) {
      switch (value) {
        case typeUser.company:
          return t('admin.company.delete_active_company_error');
        case typeUser.student:
          return t('admin.student.delete_active_student_error');
        case typeUser.coach:
          return t('admin.coach.delete_active_coach_error');
        case typeUser.teacher:
          return t('admin.teacher.delete_error');
        default:
          return '';
      }
    } else {
      switch (value) {
        case typeUser.company:
          return t('admin.modal_delete.content_error');
        case typeUser.student:
          return t('admin.student.delete_error');
        case typeUser.coach:
          return t('admin.coach.delete_error');
        case typeUser.teacher:
          return t('admin.teacher.delete_error');
        default:
          return '';
      }
    }
  }

  const getTitleManager = () => {
    switch (type) {
      case typeUser.company:
        return t('admin.manager_company');
      case typeUser.student:
        return t('admin.manager_student');
      case typeUser.coach:
        return t('admin.manager_coach');
      case typeUser.teacher:
        return t('admin.manager_teacher');
      default:
        return '';
    }
  }

  const searchUser = (e) => {
    e.preventDefault();
    dispatch(listUser({
      name: keyword,
      paginate: paginationAdmin,
      role: type,
    }));
  }

  const handleDelete = () => {
    setLoading(true);
    dispatch(deleteUser({id: userId}));
  }

  useEffect(() => {
    closeModal(false);
    if (isErrorDelete) {
      setOpenModalNotification(true);
      setContentDelete(getContentDeleteError(type, errorMessageDeleteUser?.status));
      setLoading(false);
    }
    if (isSuccessDelete) {
      setOpenModalNotification(true);
      setContentDelete(getContentDeleteSuccess(type));
      setLoading(false);
    }
  }, [isErrorDelete, isSuccessDelete]);

  const closeModalNotification = (value) => {
    setOpenModalNotification(value);
    location.reload();
  }

  return (
    <>
      {isComplete ?
        <>
          <div className='manager-block'>
            <div>
              <div className='title-manager'>{getTitleManager()}</div>
              <div className='w-100 d-flex justify-content-between align-items-center mb-3'>
                {type === typeUser.coach &&
                  <Link to='/register-coach'>
                    <button className='btn-register-coach'>{t('register_coach')}</button>
                  </Link>}
                <form onSubmit={searchUser} className='d-flex justify-content-end align-items-center w-100'>
                  <div className='mr-3 main-text'>{t('admin.company.title_search')}</div>
                  <input type="text" className='input-system mr-3 w-40' placeholder={t('admin.company.placeholder_search')} value={keyword || ''} maxLength={500} onChange={(e) => setKeyWord(e.target.value)} />
                  <button className='btn-save-company'>{t('admin.company.btn_search')}</button>
                </form>
              </div>
              <table className='table table-manager'>
                <thead>
                <tr>
                  <th className='w-30'>{nameUser}</th>
                  <th className='w-30'>{t('admin.company.email')}</th>
                  <th className='th__action'></th>
                </tr>
                </thead>
                <tbody>
                {users.length === 0 && <tr><td colSpan={3} className="no-data">{t('no_data')}</td></tr>}
                {users.map((user) => (
                  <tr key={user?.id}>
                    <td>{user?.name}</td>
                    <td>{user?.email}</td>
                    <td>
                      <div className='d-flex justify-content-center align-items-center'>
                        <Link to={'/admin/users/' + user?.id}>
                          <button className='btn-action-manager btn-edit-manager'>{t('admin.edit')}</button>
                        </Link>
                        <button className='btn-action-manager btn-delete-manager' onClick={() => {setOpenModal(true); setName(user?.name); setEmail(user?.email); setUserId(user?.id)}} >{t('admin.delete')}</button>
                      </div>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
            {totalPages > 1 &&
              <Pagination
                containerClassName='pagination justify-content-center'
                pageLinkClassName='page-link'
                breakClassName="page-item"
                breakLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                activeClassName="active"
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageChange}
                pageCount={totalPages}
                previousLabel="<"
                renderOnZeroPageCount={null}
                forcePage={currentPage - 1}
              />
            }
          </div>
          <ConfirmDelete
            title={titleConfirmDelete}
            todos={openModal}
            content={t('admin.company.confirm_delete')}
            closeModal={closeModal}
            name={name}
            email={email}
            handleDelete={handleDelete}
            nameTitle={nameUser}
          />
          <Notification todos={openModalNotification} title={titleConfirmDelete} content={contentDelete} closeModal={closeModalNotification} />
        </> : <div></div>
      }
    </>
  );
}

export default ListUsers;
import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import LikeIcon from '../../assets/image/like_icon.svg';
import LikeActiveIcon from '../../assets/image/like_active_icon.svg';
import {useDispatch, useSelector} from "react-redux";
import {createEvaluate, updateEvaluate} from "../../redux/evaluate/evaluate.slide";
import {clearState, listCriteria} from "../../redux/criteria/criteria.slide";

Evaluate.propTypes = {
  todos: PropTypes.bool,
  closeModal: PropTypes.func,
  userId: PropTypes.number,
  setLoading: PropTypes.func,
  response: PropTypes.any,
};
Evaluate.defaultProps = {
  todos: false,
  closeModal: null,
  userId: null,
  setLoading: null,
  response: null,
};
function Evaluate(props) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const { todos, closeModal, userId, setLoading, response } = props;
  const [criteriaIds, setCriteriaIds] = useState([]);
  const [comment, setComment] = useState('');
  const {isSuccessEvaluate, isErrorEvaluate, errorMessage} = useSelector(state => state.evaluate);
  const [validateComment, setValidateComment] = useState('');
  const [disabled, setDisabled] = useState(true);
  const {isSuccess, isError, listCriterias} = useSelector(state => state.criteria);
  const [criterias, setCriterias] = useState([]);
  const [criteriaArr, setCriteriaArr] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    dispatch(listCriteria());

    return () => dispatch(clearState());
  }, []);

  useEffect(() => {
    if (isError) {
      alert(t('error_system'));
    }
    if (isSuccess) {
      setCriterias(Object.entries(listCriterias?.data?.data ?? {}));
    }

    return () => dispatch(clearState());
  }, [isSuccess, isError]);

  const chooseVote = (e) => {
    const src = e.target.getAttribute('src');
    const criteriaId = e.target.getAttribute('data-id');
    let newSrc = '';
    if (src.includes('like_active_icon')) {
      newSrc = LikeIcon;
      const newCriteriaIds = criteriaIds.filter(item => item !== criteriaId);
      setCriteriaIds(newCriteriaIds);
    } else {
      newSrc = LikeActiveIcon;
      setCriteriaIds([...criteriaIds, criteriaId]);
    }
    e.target.setAttribute('src', newSrc);
  }

  const handleSubmit = () => {
    if (comment || criteriaIds.length) {
      setLoading(true);
      if (isUpdate) {
        dispatch(updateEvaluate({
          id: response?.id,
          criteria_id: criteriaIds,
          comment,
          _method: 'PUT',
        }));
      } else {
        dispatch(createEvaluate({
          issue_id: window.location.href.split('/').pop(),
          user_id: userId,
          criteria_id: criteriaIds,
          comment,
        }));
      }
    }
  }

  useEffect(() => {
    setDisabled(!comment.trim() && !criteriaIds.length);
  }, [comment, criteriaIds])

  useEffect(() => {
    if (isErrorEvaluate) {
      if (errorMessage?.status === 500) {
        alert(t('error_system'));
      }
      if (errorMessage?.status === 422) {
        setValidateComment(errorMessage?.data?.errors?.comment?.[0]);
      }

      setLoading(false);
      return;
    }
    if (isSuccessEvaluate) {
      location.reload();
    }
  }, [isSuccessEvaluate, isErrorEvaluate]);

  useEffect(() => {
    if (response) {
      setComment(response?.comment);
      if  (response?.criteria !== 0) {
        const criArr = Object.keys(response?.criteria ?? {});
        setCriteriaArr(criArr);
        setCriteriaIds(criArr);
      }
      setDisabled(false);
      setIsUpdate(true);
    } else setIsUpdate(false);

  }, [response]);

  return (
    <Modal show={todos} centered className='modal-evaluate'>
      <div className='evaluate-content'>
        <div className='title-evaluate'>{t('evaluate.title')}</div>
        <div className='rate-block'>
          {criterias.map(criteria => (
            <div key={criteria?.[0]} className='vote-rate'>
              <img src={criteriaArr.includes(criteria?.[0]) ? LikeActiveIcon : LikeIcon} alt="icon" className='icon-vote-rate' data-id={criteria?.[0]} onClick={chooseVote} />
              <div className='text-vote'>{criteria?.[1]}</div>
            </div>
          ))}
        </div>
        <div className='title-comment-block'>
          <div className='parent-title-comment'>
            <div className='title-comment'>{t('evaluate.comment')}</div>
          </div>
          <textarea
            className='comment-evaluate'
            placeholder={t('please_type')}
            onChange={(e) => setComment(e.target.value)}
            maxLength={5000}
            value={comment ?? ''}
          >
          </textarea>
          <div className='text-error parent-title-comment'>{validateComment}</div>
        </div>
        <div className='btn-evaluate-block'>
          <button className='button-secondary mr-2' onClick={() => closeModal(!todos)}>{t('register_company.modal_button')}</button>
          <button className='btn-system' disabled={disabled} onClick={handleSubmit}>{t('evaluate.sent')}</button>
        </div>
      </div>
    </Modal>
  );
}

export default Evaluate;
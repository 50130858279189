import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {post} from "../../services";
import {setUser, setUserSignIn} from "../user/user.slide";

const initialState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: '',
  currentRequestId: undefined,
  response: null,
  isErrorExpired: false,
  typeUserRes: null,
  responseForgetPassword: null,
};

export const signUp = createAsyncThunk(
  '',
  async (params, { getState, requestId, rejectWithValue }) => {
    try {
      const { currentRequestId, isFetching } = getState().auth;
      if (!isFetching || requestId !== currentRequestId) {
        return;
      }
      const url = `auth/register`;
      const response = await post(url, params);
      if (response.status === 201) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      return rejectWithValue(e.response);
    }
  },
);

export const createPasswordCompany = createAsyncThunk(
  'auth/register-company/create-password',
  async (params, { getState, requestId, rejectWithValue }) => {
    try {
      const { currentRequestId, isFetching } = getState().auth;
      if (!isFetching || requestId !== currentRequestId) {
        return;
      }
      const url = `auth/register-company/create-password`;
      const response = await post(url, params);
      if (response.status === 201) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const signUpComplete = createAsyncThunk(
  'auth/complete',
  async (params, { getState, requestId, rejectWithValue }) => {
    try {
      const { currentRequestId, isFetching } = getState().auth;
      if (!isFetching || requestId !== currentRequestId) {
        return;
      }
      const url = `auth/complete`;
      const response = await post(url, params);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const signIn = createAsyncThunk(
  'auth/login',
  async (params, { getState, dispatch, requestId, rejectWithValue }) => {
    try {
      const { currentRequestId, isFetching } = getState().auth;
      if (!isFetching || requestId !== currentRequestId) {
        return;
      }
      const url = `auth/login`;
      const response = await post(url, params);
      if (response.status === 200) {
        dispatch(setUser(response.data.data.user));
        dispatch(setUserSignIn(true));

        return response;
      } else {
        dispatch(setUserSignIn(false));
        return rejectWithValue(response);
      }
    } catch (e) {
      return rejectWithValue(e.response);
    }
  },
);

export const checkExpiredUrl = createAsyncThunk(
  'auth/check-expired-url',
  async (params, { rejectWithValue }) => {
    try {
      const url = `auth/check-expired-url`;
      const response = await post(url, params);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const forgetPassword = createAsyncThunk(
  'auth/forget-password',
  async (params, { rejectWithValue }) => {
    try {
      const url = `auth/forget-password`;
      const response = await post(url, params);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const updateUserCompany = createAsyncThunk(
  'auth/update-company',
  async (params, { rejectWithValue }) => {
    try {
      const url = `auth/update-company`;
      const response = await post(url, params);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (e) {
      return rejectWithValue(e.response);
    }
  },
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isFetching = false;
      state.isSuccess = false;
      state.isErrorExpired = false;
      state.response = null;
      state.typeUserRes = null;
      state.responseForgetPassword = null;
      return state;
    },
  },
  extraReducers: {
    [signUp.pending.type]: (state, { meta }) => {
      const { requestId } = meta;
      if (!state.isFetching) {
        state.errorMessage = '';
        state.isError = false;
        state.isFetching = true;
        state.isSuccess = false;
        state.currentRequestId = requestId;
      }
    },
    [signUp.fulfilled.type]: (state, { meta }) => {
      const { requestId } = meta;
      if (state.isFetching && state.currentRequestId === requestId) {
        state.isError = false;
        state.isSuccess = true;
        state.isFetching = false;
        state.errorMessage = '';
        state.currentRequestId = undefined;
      }
    },
    [signUp.rejected.type]: (state, { payload, meta }) => {
      const { requestId } = meta;
      if (state.isFetching && state.currentRequestId === requestId) {
        state.isError = true;
        state.isSuccess = false;
        state.isFetching = false;
        state.errorMessage = payload;
        state.currentRequestId = undefined;
      }
    },

    [createPasswordCompany.pending.type]: (state, { meta }) => {
      const { requestId } = meta;
      if (!state.isFetching) {
        state.errorMessage = '';
        state.isError = false;
        state.isFetching = true;
        state.isSuccess = false;
        state.currentRequestId = requestId;
        state.typeUserRes = null;
      }
    },
    [createPasswordCompany.fulfilled.type]: (state, { payload, meta }) => {
      const { requestId } = meta;
      if (state.isFetching && state.currentRequestId === requestId) {
        state.isError = false;
        state.isSuccess = true;
        state.isFetching = false;
        state.errorMessage = '';
        state.currentRequestId = undefined;
        state.typeUserRes = payload;
      }
    },
    [createPasswordCompany.rejected.type]: (state, { payload, meta }) => {
      const { requestId } = meta;
      if (state.isFetching && state.currentRequestId === requestId) {
        state.isError = true;
        state.isSuccess = false;
        state.isFetching = false;
        state.errorMessage = payload;
        state.currentRequestId = undefined;
        state.typeUserRes = null;
      }
    },

    [signIn.pending.type]: (state, { meta }) => {
      const { requestId } = meta;
      if (!state.isFetching) {
        state.errorMessage = '';
        state.isError = false;
        state.isFetching = true;
        state.isSuccess = false;
        state.currentRequestId = requestId;
      }
    },
    [signIn.fulfilled.type]: (state, { payload, meta }) => {
      const { requestId } = meta;
      const { accessToken, refreshToken } = payload.data.data;
      if (state.isFetching && state.currentRequestId === requestId) {
        state.isError = false;
        state.isSuccess = true;
        state.isFetching = false;
        state.errorMessage = '';
        state.currentRequestId = undefined;
        localStorage.setItem('token', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
      }
    },
    [signIn.rejected.type]: (state, { payload, meta }) => {
      const { requestId } = meta;
      if (state.isFetching && state.currentRequestId === requestId) {
        state.isError = true;
        state.isSuccess = false;
        state.isFetching = false;
        state.errorMessage = payload;
        state.currentRequestId = undefined;
      }
    },

    [forgetPassword.pending.type]: (state, { meta }) => {
      const { requestId } = meta;
      if (!state.isFetching) {
        state.errorMessage = '';
        state.isError = false;
        state.isFetching = true;
        state.isSuccess = false;
        state.currentRequestId = requestId;
      }
    },
    [forgetPassword.fulfilled.type]: (state, { payload, meta }) => {
      const { requestId } = meta;
      if (state.isFetching && state.currentRequestId === requestId) {
        state.isError = false;
        state.isSuccess = true;
        state.isFetching = false;
        state.errorMessage = '';
        state.currentRequestId = undefined;
        state.responseForgetPassword = payload;
      }
    },
    [forgetPassword.rejected.type]: (state, { payload, meta }) => {
      const { requestId } = meta;
      if (state.isFetching && state.currentRequestId === requestId) {
        state.isError = true;
        state.isSuccess = false;
        state.isFetching = false;
        state.errorMessage = payload;
        state.currentRequestId = undefined;
      }
    },

    [checkExpiredUrl.pending.type]: (state, { meta }) => {
      const { requestId } = meta;
      if (!state.isFetching) {
        state.errorMessage = '';
        state.isError = false;
        state.isErrorExpired = false;
        state.isFetching = true;
        state.isSuccess = false;
        state.currentRequestId = requestId;
      }
    },
    [checkExpiredUrl.fulfilled.type]: (state, { payload, meta }) => {
      const { requestId } = meta;
      if (state.isFetching && state.currentRequestId === requestId) {
        state.isError = false;
        state.isErrorExpired = false;
        state.isSuccess = false;
        state.isFetching = false;
        state.errorMessage = '';
        state.currentRequestId = undefined;
        state.response = payload;
      }
    },
    [checkExpiredUrl.rejected.type]: (state, { payload, meta }) => {
      const { requestId } = meta;
      if (state.isFetching && state.currentRequestId === requestId) {
        state.isError = true;
        state.isErrorExpired = true;
        state.isSuccess = false;
        state.isFetching = false;
        state.errorMessage = payload;
        state.currentRequestId = undefined;
      }
    },

    [signUpComplete.pending.type]: (state, { meta }) => {
      const { requestId } = meta;
      if (!state.isFetching) {
        state.errorMessage = '';
        state.isError = false;
        state.isErrorExpired = false;
        state.isFetching = true;
        state.isSuccess = false;
        state.currentRequestId = requestId;
        state.response = null;
      }
    },
    [signUpComplete.fulfilled.type]: (state, { payload, meta }) => {
      const { requestId } = meta;
      if (state.isFetching && state.currentRequestId === requestId) {
        state.isError = false;
        state.isErrorExpired = false;
        state.isSuccess = true;
        state.isFetching = false;
        state.errorMessage = '';
        state.currentRequestId = undefined;
        state.response = payload;
      }
    },
    [signUpComplete.rejected.type]: (state, { payload, meta }) => {
      const { requestId } = meta;
      if (state.isFetching && state.currentRequestId === requestId) {
        state.isError = true;
        state.isErrorExpired = true;
        state.isSuccess = false;
        state.isFetching = false;
        state.errorMessage = payload;
        state.currentRequestId = undefined;
        state.response = null;
      }
    },
  },
});

export default authSlice;

export const { clearState } = authSlice.actions;

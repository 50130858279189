const en = {
  translation: {
    homepage: 'Homepage',
    about: 'ProjectInformation',
    login: 'Login',
    logout: 'Logout',
    dashboard: 'Dashboard',
    profile: 'Profile',
  },
};

export default en;

import { configureStore } from '@reduxjs/toolkit';
import authSlice from './auth/auth.slice';
import userSlice from "./user/user.slide";
import citiesSlide from "./company/cities.slide";
import levelsSlide from "./company/levels.slide";
import companiesSlide from "./company/company.slide";
import coachesSlide from "./coach/coach.slide";
import genderSlide from "./gender/gender.slide";
import majorsSlide from "./major/major.slide";
import typeSchoolsSlide from "./type_school/type_school.slide";
import studentsSlide from "./student/student.slide";
import participantsSlide from "./participant/participant.slide";
import schoolYearsSlide from "./school_year/school_year.slide";
import issuesSlide from "./issue/issue.slide";
import messagesSlide from "./mesage/message.slide";
import teachersSlide from "./teacher/teacher.slide";
import managerUserSlide from "./admin/manage.slide";
import adminsSlide from "./admin/admin.slide";
import managerIssueSlide from "./admin/manage-issue.slide";
import evaluatesSlide from "./evaluate/evaluate.slide";
import criteriaSlide from "./criteria/criteria.slide";
import categoriesSlide from "./category/category.slide";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    user: userSlice.reducer,
    city: citiesSlide.reducer,
    level: levelsSlide.reducer,
    company: companiesSlide.reducer,
    coach: coachesSlide.reducer,
    gender: genderSlide.reducer,
    major: majorsSlide.reducer,
    typeSchool: typeSchoolsSlide.reducer,
    student: studentsSlide.reducer,
    participant: participantsSlide.reducer,
    schoolYear: schoolYearsSlide.reducer,
    issue: issuesSlide.reducer,
    message: messagesSlide.reducer,
    teacher: teachersSlide.reducer,
    admin: adminsSlide.reducer,
    managerUser: managerUserSlide.reducer,
    managerIssue: managerIssueSlide.reducer,
    evaluate: evaluatesSlide.reducer,
    criteria: criteriaSlide.reducer,
    category: categoriesSlide.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;

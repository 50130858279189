import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
import {clearState, listTypeSchool} from "../../redux/type_school/type_school.slide";

Index.propTypes = {
  getTypeSchool: PropTypes.func,
  response: PropTypes.number,
}
Index.defaultProps = {
  getTypeSchool: () => {},
  response: null,
}
function Index(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {getTypeSchool, response} = props;
  const {isSuccessSchool, listTypeSchools} = useSelector(state => state.typeSchool);
  const [typeSchools, setTypeSchools] = useState([]);
  const [typeSchoolId, setTypeSchoolId] = useState(null);

  useEffect(() => {
    dispatch(listTypeSchool());

    return () => dispatch(clearState());
  }, []);

  useEffect(() => {
    setTypeSchools(listTypeSchools?.data ?? []);
  }, [isSuccessSchool]);

  useEffect(() => {
    getTypeSchool(typeSchoolId)
  }, [typeSchoolId]);

  useEffect(() => {
    setTypeSchoolId(response);
  }, [response]);

  return (
    <select value={typeSchoolId ?? ''} name="type_of_school_id" id="" className={`select-company col-md-3 mb-4 ${typeSchoolId ? 'color-placeholder' : ''}`} onChange={(e) => {setTypeSchoolId(parseInt(e.target.value))}} >
      <option value="" disabled hidden>{t('please_choose')}</option>
      {typeSchools.map((val) => (
        <option key={val.id} value={val.id}>{val.name}</option>
      ))}
    </select>
  );
}

export default Index;
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import ResultCoach from "./ResultCoach";
import ResultStudent from "./ResultStudent";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {clearState as clearStateCity, getListCities} from "../../redux/company/cities.slide";
import {clearState, listUser} from "../../redux/user/user.slide";
import {typeUser} from "../../constant";
import {clearState as clearStateSchool, listTypeSchool} from "../../redux/type_school/type_school.slide";

Search.propTypes = {
  activeStudent: PropTypes.string,
  activeCoach: PropTypes.string,
  titleResult: PropTypes.string,
  listResult: PropTypes.string,
  isStudent: PropTypes.bool,
}

Search.defaultProps = {
  activeStudent: '',
  activeCoach: '',
  titleResult: '',
  listResult: '',
  isStudent: false,
}
function Search(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectType, setSelectType] = useState('');
  const [selectLocation, setSelectLocation] = useState('');
  const {activeStudent, activeCoach, titleResult, listResult, isStudent} = props;
  const { isSuccessCity, isErrorCity, listCities} = useSelector((state) => state.city);
  const [cityArray, setCityArray] = useState([]);
  const {isSuccess, isError, listUsers} = useSelector((state) => state.user);
  const [result, setResult] = useState([]);
  const [cityId, setCityId] = useState('');
  const [name, setName] = useState('');
  const [typeSchool, setTypeSchool] = useState('');
  const [lastPage, setLastPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [isFavorite, setIsFavorite] = useState(0);
  const [selectFavorite, setSelectFavorite] = useState('');
  const {isSuccessSchool, listTypeSchools} = useSelector(state => state.typeSchool);
  const [typeSchools, setTypeSchools] = useState([]);
  const [isCall, setIsCall] = useState(true);

  useEffect(() => {
    dispatch(listTypeSchool());
    dispatch(getListCities());
    if (isStudent) {
      dispatch(listUser({role: typeUser.student, is_favorite: isFavorite}));
    } else {
      dispatch(listUser({role: typeUser.coach}));
    }
    window.scrollTo(0, window.scrollY - 10);
    return () => {
      dispatch(clearStateSchool());
      dispatch(clearStateCity());
      dispatch(clearState());
    };
  }, []);

  useEffect(() => {
    if (isSuccessSchool) {
      setTypeSchools(listTypeSchools?.data ?? []);
      dispatch(clearStateSchool());
    }
    if (isSuccessCity) {
      setCityArray(listCities?.data ?? []);
      dispatch(clearStateCity());
    }
    if (isSuccess) {
      let newData = listUsers?.data?.data ?? [];
      if (isLoadMore) {
        newData = [...result, ...listUsers?.data?.data ?? []];
      }
      setResult(newData);
      setCurrentPage(listUsers?.data?.meta?.current_page);
      setLastPage(listUsers?.data?.meta?.last_page);
      dispatch(clearState());
      setIsCall(true);
      window.scrollTo(0, window.scrollY - 10);
    }
    if (isError) {
      alert(t('error_system'));
      dispatch(clearState());
    }
  }, [isSuccessSchool, isSuccessCity, isErrorCity, isSuccess, isError]);

  function handleSubmit(e) {
    e.preventDefault();
    setIsCall(true);
    setIsLoadMore(false);
    if (isStudent) {
      if (isFavorite) {
        dispatch(listUser({
          role: typeUser.student,
          city_id: cityId,
          name,
          type_of_school_id: typeSchool,
          is_favorite: isFavorite
        }));
      } else {
        dispatch(listUser({
          role: typeUser.student,
          city_id: cityId,
          name,
          type_of_school_id: typeSchool,
        }));
      }

    } else {
      dispatch(listUser({
        city_id: cityId,
        name,
        level_id: typeSchool,
        role: typeUser.coach,
      }));
    }
  }

  const handleScroll = () => {
    const bottom = Math.ceil(window.innerHeight + window.scrollY) === document.documentElement.scrollHeight;
    if (bottom && currentPage < lastPage && isCall) {
      setIsCall(false);
      setIsLoadMore(true);
      if(isStudent) {
        dispatch(listUser({
          role: typeUser.student,
          city_id: cityId,
          name,
          type_of_school_id: typeSchool,
          is_favorite: isFavorite,
          page: currentPage + 1,
        }));
      } else {
        dispatch(listUser({
          role: typeUser.coach,
          city_id: cityId,
          name,
          page: currentPage + 1,
        }));
      }
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {
      passive: true
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [currentPage, lastPage]);

  return (
    <>
      <div className="search-coach-block min-vh-100">
        <div className="group-action-company">
          <Link to='/company/my-issue'>
            <button className="btn-feature btn-mission">{t('list_issue_register')}</button>
          </Link>
          <div className="group-btn-second">
            <Link to="/company/coaches">
              <button className={"btn-feature " + activeCoach}>{t('company.find_coach')}</button>
            </Link>
            <Link to="/company/students">
              <button className={"btn-feature " + activeStudent}>{t('company.find_student')}</button>
            </Link>
            <Link to="/company/add-issue">
              <button className="btn-feature">{t('company.add_project')}</button>
            </Link>
            <Link to="/company/update">
              <button className="btn-feature" onClick={() => localStorage.removeItem('is_issue')}>{t('company.edit_company')}</button>
            </Link>
          </div>
        </div>

        <form className="condition-search" onSubmit={handleSubmit}>
          <div className="title-search">{t('company.find_person')}</div>
          {isStudent &&
            <select defaultValue="" name="" id="" className={"select-company select-search select-type " + selectType} onChange={(e) => {setSelectType('select-focus'); setTypeSchool(e.target.value)}}>
              <option value="" disabled hidden>{t('company.type_school')}</option>
              <option value="">{t('company.type_school')}</option>
              {typeSchools.map((val) => (
                <option key={val?.id} value={val?.id}>{val?.name}</option>
              ))}
            </select>
          }
          <select defaultValue='' className={"select-company select-search select-location " + selectFavorite} onChange={(e) => {setIsFavorite(parseInt(e.target.value)); setSelectFavorite('select-focus')}} >
            <option value='' disabled hidden>{t('my_page_student.placeholder_favorite')}</option>
            <option value=''>{t('company.please_choose')}</option>
            <option value='1'>{t('company.favorite_student')}</option>
          </select>
          <select defaultValue="" name="" id="" className={"select-company select-search select-location " + selectLocation} onChange={(e) => {setSelectLocation('select-focus'); setCityId(e.target.value)}}>
            <option value="" disabled hidden>{t('company.location')}</option>
            <option value="">{t('company.location')}</option>
            {cityArray.map((val) => (
              <option key={val?.id} value={val?.id}>{val?.name}</option>
            ))}
          </select>
          <input type="text" className="input-search-keyword" placeholder={t('company.keyword')} onChange={(e) => setName(e.target.value.trim())} />
          <button className="btn-search">{t('company.search')}</button>
        </form>

        <div className="result-search">
          <div className="d-flex justify-content-start align-items-center mb-5">
            <div className="title-result">{titleResult}</div>
            <div className="list-result">{listResult}</div>
          </div>
          { isStudent ? <ResultStudent response={result} /> : <ResultCoach response={result} /> }
        </div>
      </div>
    </>
  );
}

export default Search;

import React from 'react';
import CompanyIcon from '../../assets/image/company_icon.svg';
import StudentIcon from '../../assets/image/student_icon.svg';
import CoachIcon from '../../assets/image/coach_icon.png';
import CourseIcon from '../../assets/image/course_icon.png';
import TeacherIcon from '../../assets/image/teacher_icon.png';
import DataIcon from '../../assets/image/data_icon.svg';
import GenderIcon from '../../assets/image/gender_icon.svg';
import ObjectStudentIcon from '../../assets/image/object_icon.svg';
import QuantityStudentIcon from '../../assets/image/quantity_student_icon.png';
import SchoolTypeIcon from '../../assets/image/school_type_icon.png';
import majorIcon from '../../assets/image/major_icon.png';
import SchoolYearIcon from '../../assets/image/icon-school-year.png';
import CategoryIcon from '../../assets/image/category_icon.png';
import {useTranslation} from "react-i18next";
import avatarDefault from "../../assets/image/avatar_default.png";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

Sidebar.propTypes = {
  user: PropTypes.object,
}
Sidebar.defaultProps = {
  user: null,
}
function Sidebar(props) {
  const { t } = useTranslation();
  const {user} = props;

  const getActive = (url) => {
    return window.location.pathname === url ? 'active' : '';
  }

  return (
    <div className='admin-sidebar'>
      <div className='admin-info'>
        <div className='main-text name-admin' title={user?.name}>{user?.name}</div>
        <img src={user?.profile?.image ?? avatarDefault} alt="avatar" className='avatar-admin' />
      </div>
      <Link to='/admin/companies'>
        <div className={'admin-sidebar__items ' + getActive('/admin/companies')} >
          <img src={CompanyIcon} alt="icon"/>
          <div className='name'>{t('admin.manager_company')}</div>
        </div>
      </Link>
      <Link to='/admin/students'>
        <div className={'admin-sidebar__items ' + getActive('/admin/students')}>
          <img src={StudentIcon} alt="icon"/>
          <div className='name'>{t('admin.manager_student')}</div>
        </div>
      </Link>
      <Link to='/admin/coaches'>
        <div className={'admin-sidebar__items ' + getActive('/admin/coaches')}>
          <img src={CoachIcon} alt="icon"/>
          <div className='name'>{t('admin.manager_coach')}</div>
        </div>
      </Link>
      <Link to='/admin/issues'>
        <div className={'admin-sidebar__items ' + getActive('/admin/issues')}>
          <img src={CourseIcon} alt="icon"/>
          <div className='name'>{t('admin.manager_issue')}</div>
        </div>
      </Link>
      <Link to='/admin/teachers'>
        <div className={'admin-sidebar__items ' + getActive('/admin/teachers')}>
          <img src={TeacherIcon} alt="icon"/>
          <div className='name'>{t('admin.manager_teacher')}</div>
        </div>
      </Link>
      <div className='admin-sidebar__items' style={{cursor: 'auto'}}>
        <img src={DataIcon} alt="icon"/>
        <div className='name'>{t('admin.manager_data_general')}</div>
      </div>
      <Link to='/admin/genders'>
        <div className={'admin-sidebar__items child-items ' + getActive('/admin/genders')}>
          <img src={GenderIcon} alt="icon"/>
          <div className='name'>{t('admin.manager_gender')}</div>
        </div>
      </Link>
      <Link to='/admin/student-objects'>
        <div className={'admin-sidebar__items child-items ' + getActive('/admin/student-objects')}>
          <img src={ObjectStudentIcon} alt="icon"/>
          <div className='name'>{t('admin.manager_object_student')}</div>
        </div>
      </Link>
      <Link to='/admin/setting-issue'>
        <div className={'admin-sidebar__items child-items ' + getActive('/admin/setting-issue')}>
          <img src={QuantityStudentIcon} alt="icon"/>
          <div className='name'>{t('admin.manager_quantity')}</div>
        </div>
      </Link>
      <Link to='/admin/school-types'>
        <div className={'admin-sidebar__items child-items ' + getActive('/admin/school-types')}>
          <img src={SchoolTypeIcon} alt="icon"/>
          <div className='name'>{t('admin.manager_school_type')}</div>
        </div>
      </Link>
      <Link to='/admin/majors'>
        <div className={'admin-sidebar__items child-items ' + getActive('/admin/majors')}>
          <img src={majorIcon} alt="icon"/>
          <div className='name'>{t('admin.manager_major')}</div>
        </div>
      </Link>
      <Link to='/admin/setting-school-years'>
        <div className={'admin-sidebar__items child-items ' + getActive('/admin/setting-school-years')}>
          <img src={SchoolYearIcon} alt="icon"/>
          <div className='name'>{t('admin.manager_school_year')}</div>
        </div>
      </Link>
      <Link to='/admin/categories'>
        <div className={'admin-sidebar__items child-items ' + getActive('/admin/categories')}>
          <img src={CategoryIcon} alt="icon"/>
          <div className='name'>{t('admin.manager_category')}</div>
        </div>
      </Link>
    </div>
  );
}

export default Sidebar;
import axios from 'axios';
import statusHandler from './status-handle';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API,
});
instance.defaults.headers.post['Content-Type'] = 'application/json';

instance.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    statusHandler(error);
    return error.response;
  },
);

export default instance;

import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {get} from "../../services";

const initialState = {
  isFetching: false,
  isSuccessMajor: false,
  isErrorMajor: false,
  errorMessage: '',
  listMajors: null,
};

export const listMajor = createAsyncThunk(
  'majors',
  async (params, {rejectWithValue }) => {
    try {
      const url = 'majors';
      const response = await get(url, params);
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response.data);
    }
  },
);

const majorsSlide = createSlice({
  name: 'majors',
  initialState,
  reducers: {
    clearState: (state) => {
      state.isErrorMajor = false;
      state.isFetching = false;
      state.isSuccessMajor = false;
      state.errorMessage = '';
      state.listMajors = null;
      return state;
    },
  },
  extraReducers: {
    [listMajor.pending.type]: (state) => {
      if (!state.isFetching) {
        state.errorMessage = '';
        state.isErrorMajor = false;
        state.isFetching = true;
        state.isSuccessMajor = false;
        state.listMajors = null;
      }
    },
    [listMajor.fulfilled.type]: (state, { payload }) => {
        state.isErrorMajor = false;
        state.isSuccessMajor = true;
        state.isFetching = false;
        state.errorMessage = '';
        state.currentRequestId = undefined;
        state.listMajors = payload.data;
    },
    [listMajor.rejected.type]: (state, { payload }) => {
      if (state.isFetching) {
        state.isErrorMajor = true;
        state.isSuccessMajor = false;
        state.isFetching = false;
        state.errorMessage = payload;
        state.currentRequestId = undefined;
        state.listMajors = null;
      }
    },
  },
});

export default majorsSlide;

export const { clearState } = majorsSlide.actions;

/**
 * Status Handler - For handling network responses
 */

const statusHandler = (err) => {
  if (err.response) {
    if (err.response.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/';
    }
    return err;
  }
};

export default statusHandler;

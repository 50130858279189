import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getSettingParticipant, updateSettingParticipant} from "../../redux/participant/participant.slide";
import Notification from "../../components/Modal/Notification";
import PropTypes from "prop-types";

QuantityParticipant.propTypes = {
  setLoading: PropTypes.func,
}
QuantityParticipant.defaultProps = {
  setLoading: null,
}
function QuantityParticipant(props) {
  const { t } = useTranslation();
  const {setLoading} = props;
  const dispatch = useDispatch();
  const {isSuccessParticipant, listParticipants, isErrorParticipantUpdate, isSuccessParticipantUpdate, errorMessage} = useSelector((state) => state.participant);
  const [quantityStudent, setQuantityStudent] = useState(null);
  const [quantityOrganizer, setQuantityOrganizer] = useState(null);
  const [validateStudent, setValidateStudent] = useState('');
  const [validateOrganizer, setValidateOrganizer] = useState('');
  const [contentUpdate, setContentUpdate] = useState('');
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(getSettingParticipant());
  }, []);

  useEffect(() => {
    if (isSuccessParticipant) {
      setQuantityStudent(listParticipants?.data?.number_of_students_participating);
      setQuantityOrganizer(listParticipants?.data?.number_of_participants_from_the_organization);
      setLoading(false);
    }
  }, [isSuccessParticipant]);

  const updateQuantity = (e) => {
    e.preventDefault();
    setLoading(true);
    setValidateStudent('');
    setValidateOrganizer('');
    dispatch(updateSettingParticipant({
      id: 1,
      number_of_students_participating: quantityStudent,
      number_of_participants_from_the_organization: quantityOrganizer,
      _method: 'PUT',
    }));
  }

  useEffect(() => {
    if (isErrorParticipantUpdate) {
      if (errorMessage?.status === 422) {
        setValidateStudent(errorMessage?.data?.errors?.number_of_students_participating?.[0]);
        setValidateOrganizer(errorMessage?.data?.errors?.number_of_participants_from_the_organization?.[0]);
        setLoading(false);
      }
      if (errorMessage?.status === 500) {
        alert(t('error_system'));
        setContentUpdate(t('admin.data.edit_error.quantity'));
        setLoading(false);
      }
    }

    if (isSuccessParticipantUpdate) {
      setLoading(false);
      setContentUpdate(t('admin.data.edit_success.quantity'));
      setOpenModal(true);
    }

  }, [isErrorParticipantUpdate, isSuccessParticipantUpdate]);

  const closeModal = (value) => {
    setOpenModal(value);
    location.reload();
  }

  return (
    <>
      <div className='manager-block d-block'>
        <div className='title-manager mb-5'>{t('admin.manager_quantity')}</div>
        <form onSubmit={updateQuantity} className="container-fluid">
          <div className="mb-3 d-flex align-items-center">
            <div className="col-2">{t('admin.quantity.student')}</div>
            <div className="col-3 d-flex align-items-center">
              <input value={quantityStudent || ''} type="text" className='input-system input-quantity mr-5'
                     onChange={(e) => setQuantityStudent(e.target.value.trim())}
              />
            </div>
          </div>
          <p className='text-error'>{validateStudent}</p>
          <div className="mb-3 d-flex align-items-center">
            <div className="col-2">{t('admin.quantity.organizer')}</div>
            <div className="col-3 d-flex align-items-center">
              <input value={quantityOrganizer || ''} type="text" className='input-system input-quantity mr-5'
                onChange={(e) => setQuantityOrganizer(e.target.value.trim())}
              />
            </div>
          </div>
          <p className='text-error'>{validateOrganizer}</p>
          <button className='btn-save-company'>{t('update')}</button>
        </form>
      </div>
      <Notification
        todos={openModal}
        title={t('admin.data.title_update_quantity')}
        content={contentUpdate}
        closeModal={closeModal} />
    </>
  );
}

export default QuantityParticipant;
import React, {useEffect, useState} from 'react';
import Header from "../../components/Header";
import Content from "../MyPage/Content";
import {useDispatch, useSelector} from "react-redux";
import {clearState, currentUser} from "../../redux/user/user.slide";
import './index.css';
import PropTypes from "prop-types";
import Loading from "../../components/Loading";
import {useNavigate} from "react-router-dom";


Index.propTypes = {
  type: PropTypes.string,
}
Index.defaultProps = {
  type: '',
}
function Index(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {response} = useSelector(state => state.user);
  const {type} = props;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(currentUser());
    return () => {
      dispatch(clearState());
    }
  }, []);

  useEffect(() => {
    if (response) {
      if (response?.data.role !== type) {
        navigate('/');
      }
    }

    return () => {
      dispatch(clearState());
    }
  }, [response]);

  const handleLoading = (loading) => {
    setIsLoading(loading);
  }

  return (
    <>
      <div className="container container-app">
        <Header type={type}/>
        <Content loading={handleLoading} type={type} />
      </div>
      {isLoading && <Loading />}
    </>
  );
}

export default Index;
import React from 'react';
import {Button, Modal} from "react-bootstrap";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import nl2br from "react-nl2br";

Info.propTypes = {
  todos: PropTypes.bool,
  closeModal: PropTypes.func,
  response: PropTypes.object,
};

Info.defaultProps = {
  todos: false,
  closeModal: null,
  response: {},
};

function Info(props) {
  const {t} = useTranslation();
  const { todos, closeModal, response } = props;

  return (
    <div>
      <Modal show={todos} centered className='modal-info-user'>
        <Modal.Header className='justify-content-center'>
          <Modal.Title>
            <div className="title-form">{t('admin.view_profile')}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='user-info-group'>
            <span className='title'>{t('user_info.name')}</span>
            <div>{response?.name}</div>
          </div>
          <div className='user-info-group'>
            <span className='title'>{t('user_info.gender')}</span>
            <div>{response?.profile?.gender?.name}</div>
          </div>
          <div className='user-info-group'>
            <span className='title'>{t('user_info.birthday')}</span>
            <div>{response?.profile?.birthday}</div>
          </div>
          <div className='user-info-group'>
            <span className='title'>{t('user_info.email')}</span>
            <div>{response?.email}</div>
          </div>
          <div className='user-info-group'>
            <span className='title'>{t('user_info.phone_number')}</span>
            <div>{response?.profile?.phone_number}</div>
          </div>
          <div className='user-info-group'>
            <span className='title'>{t('user_info.city')}</span>
            <div>{response?.profile?.city?.name}</div>
          </div>
          <div className='user-info-group'>
            <span className='title'>{t('user_info.bio')}</span>
            <div>{nl2br(response?.profile?.introduce_yourself)}</div>
          </div>
          <div className='user-info-group'>
            <span className='title'>{t('user_info.skills')}</span>
            <div>{nl2br(response?.profile?.skills)}</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => closeModal(!todos)}>{t('user_info.back')}</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Info;